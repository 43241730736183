import './App.css';
import AppRoutes from './components/AppRoutes';
import LoginPage from './components/Admin/LoginForm';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import LoginForm from './components/Admin/LoginForm';

function CheckLogin() {
  const navigate = useNavigate();
  
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  return null;
}

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <AppRoutes />
        <CheckLogin />
      </BrowserRouter>
    </div>
  );
}

export default App;
