import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import axios from '../../axiosConfig';
import UserSearch from './UserSearch';
import { useNavigate } from 'react-router-dom';


function CompleteUserKyc() {

    // const [allUsersFilter, setAllUsersFilter] = useState('');
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(500);
    const [selectedUser, setSelectedUser] = useState(null); // Track the selected user
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const columns = [
        { field: 'id', headerName: 'UID', width: 50 },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: true,
        },
    ];

    const fetchData = async (page, rowsPerPage) => {
        try {
            setLoading(true);
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-all-users`, {
                size: limit,
                page: offset
            });

            // Set kycStatus to 0 if user_kyc_info is null
            const dataWithDefaultKycStatus = response.data.data.map(item => {
                if (!item.user_kyc_info) {
                    item.user_kyc_info = { kycStatus: 0 || 3 };
                }
                return item;
            });

            const flattenedData = flattenUserData(dataWithDefaultKycStatus);
            setRows(flattenedData);
            setCount(response.data.totalCount); // Set the total count of rows
            console.log('Rows - ', response.data.data);
            console.log('User data = ', flattenedData);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage]);

    const flattenUserData = (data) => {
        const flattenedData = [];

        data.forEach((user) => {
            if (user.user_kyc_info.kycStatus === 0 || user.user_kyc_info.kycStatus === 3) {
                flattenedData.push({
                    id: user.userId,
                    name: user.fullName,
                    dob: user.dob,
                    gender: user.gender,
                    college : user.college_info.collegeName,
                    branch : user.course_info.courseName,
                    year : user.year,
                });
            }
        });
        return flattenedData;
    };

    const filteredRows = (rows || [])
        .filter((row) =>
            Object.values(row).some(
                (value) =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText.toLowerCase())
            )
        );

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        console.log(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setCurrentPage(0);
    };

    function handleUserKyc(user) {
        setSelectedUser(user);
        console.log('Selected user:', user);
        navigate(`/user-kyc/manual-kyc/${user.id}`, { state: { user }});
    }


    return (
        <>
            <h1>Manual Kyc</h1>
            <UserSearch onSearch={setSearchText} />
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 420, overflow: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow
                                key={rows.id}
                            >
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.field}
                                        style={{
                                            minWidth: column.minWidth,
                                            backgroundColor: '#f0f0f0',
                                            fontWeight: 'bold',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {column.headerName}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow key={row.id}
                                        style={{ backgroundColor: 'transparent', transition: 'background-color 0.3s' }}
                                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                                    >
                                        {columns.map((column) => (
                                            <TableCell key={column.field} style={{
                                                textAlign: 'center',
                                                width: column.width,
                                                cursor: column.field === 'name' ? 'pointer' : 'auto',
                                            }}
                                                onClick={() => (column.field === 'name' ? handleUserKyc(row) : null)}
                                            >
                                                {row[column.field]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Pagination */}
                <TablePagination
                    rowsPerPageOptions={[100, 500, 1000, 5000]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    )
}

export default CompleteUserKyc