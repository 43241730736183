import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Modal,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import axios from '../../axiosConfig';

const Admin = () => {
  const [admins, setAdmins] = useState([]);
  const [reloadAdmins, setReloadAdmins] = useState(false);

  useEffect(()=>{
    try {
      axios.post(`${process.env.REACT_APP_BASE_URL}/get-all-admins`)
      .then(response => {
        setAdmins(response.data.data);
        // console.log(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching admins:', error);
      });
    } catch (error) {
      console.log(error);
    }
  },[reloadAdmins])
  console.log(admins);

  const [modalOpen, setModalOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const [newAdmin, setNewAdmin] = useState({
    name: '',
    username: '',
    email: '',
    phone: '',
    city: '',
    password: ''
  });

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    // Clear the form fields
    setNewAdmin({
      name: '',
      username: '',
      email: '',
      phone: '',
      city: '',
      password: ''
    });
  };

  const [filteredCities, setFilteredCities] = useState([]);

  const handleCitySearch = (searchTerm) => {
    // Fetch cities based on the entered search term
    axios.post(`${process.env.REACT_APP_BASE_URL}/search-cities`, { term: searchTerm })
      .then(response => {
        setFilteredCities(response.data);
      })
      .catch(error => {
        console.error('Error fetching cities:', error);
      });
  };

  const handleCityInputChange = (value) => {

    // Trigger city search when at least two characters are entered
    if (value.length >= 2) {
      handleCitySearch(value);
    } else {
      // Clear the filtered cities when the search term is less than two characters
      setFilteredCities([]);
    }
  };

  const handleAddAdmin = () => {

    const selectedCity = filteredCities.find(city => city.cityId === newAdmin.city.cityId);
    
      const CityId = selectedCity.cityId;    

    axios.post(`${process.env.REACT_APP_BASE_URL}/create-admin`, {
            name: newAdmin.name,
            userName: newAdmin.username,
            emailId : newAdmin.email,
            phoneNumber: newAdmin.phone,
            cityId : CityId,
            password : newAdmin.password
        })
            .then(response => {
                console.log('New Admin created successfully', response);
                setModalOpen(false);
                // Trigger a re-render by updating the reloadAdmins state
                setReloadAdmins(true);
            })
            .catch(error => {
                console.error('Error rejecting :', error);
                return null;
            });
    // console.log(newAdmin);
  };

  const handleInputChange = (field, value) => {
    setNewAdmin((prevAdmin) => ({
      ...prevAdmin,
      [field]: value,
    }));

  };

  const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'username', label: 'Username', minWidth: 170 },
    { id: 'email', label: 'Email ID', minWidth: 170 },
    { id: 'phone', label: 'Phone No.', minWidth: 170 },
    { id: 'city', label: 'City', minWidth: 170 },
  ];

  return (
    <div style={{ marginTop: '54px' }}>
      <Typography variant="h4" gutterBottom>
        Admin Details
      </Typography>

      <Button variant="outlined" onClick={handleModalOpen} style={{ marginBottom: '16px' }}>
        Add Admin
      </Button>

      <TableContainer component={Paper} style={{ marginBottom:'50px'}}>
        <Table aria-label="admin table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} style={{ fontWeight: 'bold' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.map((admin) => (
              <TableRow key={admin.id}>
                <TableCell>{admin.name}</TableCell>
                <TableCell>{admin.userName}</TableCell>
                <TableCell>{admin.emailId}</TableCell>
                <TableCell>{admin.phoneNumber}</TableCell>
                <TableCell>{admin.city_info.cityName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for adding a new admin */}
      <Modal open={modalOpen} onClose={handleModalClose} aria-labelledby="add-admin-modal">
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: { xs: '80%', md: 400 }, bgcolor: 'background.paper', boxShadow: 24, p: 3, borderRadius: '25px', height: '90%', overflowY: 'auto', scrollbarWidth:'thin' }}>
          <Typography variant="h6" id="modal-modal-title" gutterBottom>
            Add Admin
          </Typography>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={newAdmin.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            required
          />
          <TextField
            label="Username"
            fullWidth
            margin="normal"
            value={newAdmin.username}
            onChange={(e) => handleInputChange('username', e.target.value)}
          />
          <TextField
            label="Email ID"
            fullWidth
            margin="normal"
            value={newAdmin.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
          <TextField
            label="Phone No."
            fullWidth
            margin="normal"
            value={newAdmin.phone}
            onChange={(e) => handleInputChange('phone', e.target.value)}
          />
          <Autocomplete
            options={filteredCities}
            getOptionLabel={(option) => option?.cityName || ''}
            value={newAdmin.city}
            onChange={(e, newValue) => handleInputChange('city', newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                fullWidth
                margin="normal"
                onChange={(e) => handleCityInputChange(e.target.value)}
              />
            )}
          />
          <TextField
            label="Password"
            fullWidth
            margin="normal"
            value={newAdmin.password}
            onChange={(e) => handleInputChange('password', e.target.value)}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '16px' }}>
            <Button variant="outlined" onClick={handleAddAdmin} color="success">
              Add Admin
            </Button>
            <Button variant="outlined" onClick={handleModalClose} color="error">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Admin;
