import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import axios from 'axios';

const AddCompany = ({ onUpdate }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [companyForm, setCompanyForm] = useState({
    displayName: "",
    email: "",
    contact: "",
    entityType: "Type1",
    about: "",
    registeredName: "",
    pocName: "",
  });

  const [logo, setLogo] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
      const url = URL.createObjectURL(file);
    if (file) {
      setLogo(file);
      setLogoUrl(url);
    }
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    // Reset the form after closing the dialog
    setCompanyForm({
      displayName: "",
      email: "",
      contact: "",
      entityType: "Type1",
      about: "",
      registeredName: "",
      pocName: "",
    });
    setLogo(null);
    setLogoUrl(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if logo has been uploaded
    if (!logo) {
        alert('Please upload a logo.');
        return;
    }

    const dataToSend = new FormData();

    // Append each property of companyForm to dataToSend
    for (let key in companyForm) {
      dataToSend.append(key, companyForm[key]);
    }
    dataToSend.append('logo', logo);

    await axios.post(`${process.env.REACT_APP_BASE_URL}/add-brand`, dataToSend)
      .then(response => {
        console.log("Company Form Details submitted:", response.data);
      })
      .catch(error => {
        console.error('Error adding company:', error);
      });

    onUpdate();
    // Reset the form after submission
    setCompanyForm({
      displayName: "",
      email: "",
      contact: "",
      entityType: "Type1",
      about: "",
      registeredName: "",
      pocName: "",
    });
    setLogo(null);
    setLogoUrl(null);
    // Close the dialog
    handleDialogClose();
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleDialogOpen}
        style={{ marginTop: "10px" }}
      >
        Add Company
      </Button>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <label htmlFor="logo-upload" style={{ cursor: "pointer" }}>
            <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                overflow: "hidden",
                backgroundColor: "#ccc", // Fallback background color
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
              }}
            >
              {logo ? (
                <Avatar
                  alt="Logo"
                  src={logoUrl}
                  sx={{ width: "100%", height: "100%" }}
                />
              ) : (
                <span>Upload Logo</span>
              )}
            </div>
          </label>
          <input
            id="logo-upload"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleLogoUpload}
          />
          <TextField
            label="Name"
            variant="standard"
            fullWidth
            name="displayName"
            value={companyForm.displayName}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Email"
            variant="standard"
            fullWidth
            name="email"
            value={companyForm.email}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Mobile"
            variant="standard"
            fullWidth
            name="contact"
            value={companyForm.contact}
            onChange={handleInputChange}
            margin="normal"
          />
          <FormControl fullWidth variant="standard" margin="normal">
            <InputLabel>Entity Type</InputLabel>
            <Select
              value={companyForm.entityType}
              onChange={handleInputChange}
              label="Entity Type"
              name="entityType"
            >
              <MenuItem value="Type1">Pvt Ltd</MenuItem>
              <MenuItem value="Type2">LLP</MenuItem>
              <MenuItem value="Type3">Partnership Firm</MenuItem>
              <MenuItem value="Type4">HUF</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="About"
            variant="standard"
            fullWidth
            name="about"
            value={companyForm.about}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Registered Name"
            variant="standard"
            fullWidth
            name="registeredName"
            value={companyForm.registeredName}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Point of Contact"
            variant="standard"
            fullWidth
            name="pocName"
            value={companyForm.pocName}
            onChange={handleInputChange}
            margin="normal"
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginTop: "10px" }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDialogClose}
            style={{ marginTop: "10px", marginLeft: "10px" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddCompany;
