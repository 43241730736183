import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Checkbox,
  Box,
  Container,
  Grid,
  Input,
  Chip,
  Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCompany from "./AddCompany";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../axiosConfig';
import EditSubtaskForm from "./EditSubtaskForm";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditTask = () => {
  const { state } = useLocation();
  // console.log("Received state in EditTask:", state);
  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subtaskList, setSubtaskList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  // console.log(state.taskDetails);
  const existingTask = state && state.taskDetails ? state.taskDetails : {
    taskTitle: '',
    taskCategoryId: '',
    brandId: '',
    startDate: '',
    endDate: '',
    tutorialLink: '',
    rewards: [],
    submissionMaxLimit: '',
    approvalRequired: '',
    taskType: '',
    complexity: '',
    personalDetailsRequired: '',
    personalDetailsForm: '',
    // Add other properties as needed
  };

  // console.log("existingTask in EditTask:", existingTask);
  const { taskInfoId, taskTitle, taskCategoryId, brandId, startDate, rewards: selectedRewardIds, endDate, tutorialLink, submissionMaxLimit, approvalRequired, taskType, complexity, personalDetailsRequired, personalDetailsForm } = existingTask;
  // console.log(existingTask);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    taskInfoId: existingTask?.taskInfoId,
    taskTitle: existingTask?.taskTitle,
    taskCategoryId: existingTask?.taskCategoryId,
    brandId: existingTask?.brandId,
    startDate: existingTask?.startDate,
    endDate: existingTask?.endDate,
    tutorialLink: existingTask?.tutorialLink,
    rewards: existingTask.task_rewards_infos.map((reward) => ({
      rewardName: reward.rewardName,
      taskRewardInfoId: reward.task_rewards_mapping.taskRewardInfoId,
    })),
    submissionMaxLimit: existingTask?.submissionMaxLimit,
    approvalRequired: existingTask?.approvalRequired,
    taskType: existingTask?.taskType,
    taskLevel: existingTask?.complexity,
    includePersonalDetails: existingTask?.personalDetailsRequired,
    personalDetails: existingTask?.personalDetailsForm
      ? JSON.parse(existingTask?.personalDetailsForm)
      : {
        Name: false,
        Email: false,
        PhoneNumber: false,
      },
    // {
    //   Name: false,
    //   Email: false,
    //   PhoneNumber: false,
    // },
  });
  // console.log(formData);

  const [selectedApproval, setSelectedApproval] = useState(formData.approvalRequired);
  const [selectedTaskLevel, setSelectedTaskLevel] = useState(formData.taskLevel);
  const [selectedTaskType, setSelectedTaskType] = useState(formData.taskType);
  const [includePersonalDetails, setIncludePersonalDetails] = useState(formData.includePersonalDetails);

  const [formErrors, setFormErrors] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [categories, setCategories] = useState([]);

  // Add these lines where you define your state variables
  const [nameChecked, setNameChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [phoneNumberChecked, setPhoneNumberChecked] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState([]);
  const [triggerCompanyFetch, setTriggerCompanyFetch] = useState(false);

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    // Fetch categories from the server
    axios.post(`${process.env.REACT_APP_BASE_URL}/get-brands`)
      .then(response => {
        setCompanies(response.data);
      })
      .catch(error => {
        console.error('Error fetching companies:', error);
      });
  }, [triggerCompanyFetch]);

  useEffect(() => {
    // Fetch categories from the server
    axios.post(`${process.env.REACT_APP_BASE_URL}/getCategories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch categories from the server
    axios.post(`${process.env.REACT_APP_BASE_URL}/get-rewards`)
      .then(response => {
        setRewards(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching rewards:', error);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "submissionMaxLimit") {
      maxLimitChange(e);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubtaskSubmit = (subtaskData) => {
    // Log the entire subtaskData array
    console.log("Received subtaskData:", subtaskData);

    setSubtaskList(subtaskData);
    // toast.success('All subtasks submitted successfully');


    // Check if subtaskData is defined and is an array
    // if (subtaskData && Array.isArray(subtaskData)) {
    //   // Check for duplicates and only add unique subtasks
    //   subtaskData.forEach(subtask => {
    //     const isDuplicate = subtaskList.some(existingSubtask =>
    //       Object.keys(existingSubtask).every(key => existingSubtask[key] === subtask[key])
    //     );

    //     if (!isDuplicate) {
    //       // Add the subtask to the subtaskList
    //       setSubtaskList((prevList) => [...prevList, subtask]);
    //       console.log(`Added subtask: ${subtask.SubtaskTitle}`);
    //     } else {
    //       console.log(`Discarded duplicate subtask: ${subtask.SubtaskTitle}`);
    //     }
    //   });
    // } else {
    //   console.error("Invalid subtaskData structure. Unable to process subtasks.");
    // }
  };


  const maxLimitChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value === "" ? "" : Number(value),
    }));
  };

  const handleCategoryChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedCategory(value);
    setFormData((prevData) => ({
      ...prevData,
      taskCategoryId: value, // Replace 'category' with the appropriate property name in your state
    }));
  };

  const handleUpdate = () => {
    setTriggerCompanyFetch(prevState => !prevState);
  };

  const handleCompanyChange = (event) => {
    const selectedBrandId = parseInt(event.target.value, 10);
    setSelectedCompany(selectedBrandId);

    setFormData((prevData) => ({
      ...prevData,
      brandId: selectedBrandId,
    }));
  };

  const handleRewardChange = (event) => {
    const selectedIds = event.target.value;
    const selectedObjects = rewards.filter(reward => selectedIds.includes(reward.taskRewardInfoId));

    setFormData((prevData) => ({
      ...prevData,
      rewards: selectedObjects
    }));
  };

  const handleApprovalChange = (event) => {
    const value = parseInt(event.target.value, 10);

    setFormData((prevData) => ({
      ...prevData,
      approvalRequired: value,
    }));

    setSelectedApproval(value);
  };

  const handleTaskLevelChange = (event) => {
    const value = parseInt(event.target.value, 10); // or Number(event.target.value);

    setFormData((prevData) => ({
      ...prevData,
      taskLevel: value,
    }));

    setSelectedTaskLevel(value);
  };

  const handleTaskTypeChange = (event) => {
    const value = parseInt(event.target.value, 10); // or Number(event.target.value);
    // console.log(value);
    setFormData((prevData) => ({
      ...prevData,
      taskType: value,
    }));

    setSelectedTaskType(value);
    // console.log(selectedTaskType);
  };

  const handleRadioChange = (event) => {
    const value = parseInt(event.target.value, 10);

    setFormData((prevData) => ({
      ...prevData,
      includePersonalDetails: value,
    }));

    setIncludePersonalDetails(value);
  };


  const handleCheckboxChange = (field) => (event) => {
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        [field]: event.target.checked,
      },
    }));

    // Update individual state variables for checked status
    if (field === "Name") {
      setNameChecked(event.target.checked);
    } else if (field === "Email") {
      setEmailChecked(event.target.checked);
    } else if (field === "PhoneNumber") {
      setPhoneNumberChecked(event.target.checked);
    }
  };
  // console.log("TaskInfoId:", taskInfoId);
  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!subtaskList || subtaskList.length === 0) {
    //   alert('Subtask detail is required');
    //   // You can also show an error message to the user here
    //   return;
    // }

    // Create an object with selected data
    const submittedData = {
      taskInfoId: formData.taskInfoId,
      taskTitle: formData.taskTitle,
      taskCategoryId: formData.taskCategoryId,
      brandId: formData.brandId,
      startDate: formData.startDate,
      endDate: formData.endDate,
      rewards: formData.rewards.map((reward) => reward.taskRewardInfoId),
      tutorialLink: formData.tutorialLink,
      status: 1,
      complexity: selectedTaskLevel,
      taskType: selectedTaskType,
      submissionMaxLimit: formData.submissionMaxLimit,
      approvalRequired: selectedApproval,
      personalDetailsRequired: includePersonalDetails,
      personalDetailsForm:
        includePersonalDetails === 1
          ? JSON.stringify(formData.personalDetails)
          : null,
      subtaskDetail: subtaskList, // Include subtasks here
    };

    // Send data to the backend
    axios.put(`${process.env.REACT_APP_BASE_URL}/edit-task`, submittedData) // Replace with your actual API endpoint
      .then(response => {
        console.log('Task successfully updated:', response.data);
        console.log("submitted data:", submittedData);
        navigate('/task');
      })
      .catch(error => {
        console.error('Error updating task:', error);
        alert('Error updating task: ' + error.message);
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  return (
    <Container maxWidth="md" style={{ marginBottom: "50px", marginTop: "48px" }}>
      <h1>Edit Task</h1>
      {/* <form onSubmit={handleSubmit} style={{ marginLeft: "20px" }}> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Task ID"
            variant="standard"
            name="taskInfoId"
            fullWidth
            value={taskInfoId}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Task Title"
            variant="standard"
            name="taskTitle"
            fullWidth
            value={formData.taskTitle}
            onChange={handleInputChange}
            required
            error={!!formErrors.taskTitle}
            helperText={formErrors.taskTitle}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard" margin="normal" required>
            <InputLabel>Category</InputLabel>
            <Select
              label='category'
              name='taskCategoryId'
              value={formData.taskCategoryId}
              onChange={handleCategoryChange}
            >
              {/* <MenuItem value=''>Select a category</MenuItem> */}
              {categories.map((category) => (
                <MenuItem key={category.taskCategoryId} value={category.taskCategoryId}>
                  {category.categoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard" margin="normal" required>
            <InputLabel>Company</InputLabel>
            <Select
              value={formData.brandId}
              onChange={handleCompanyChange}
              label="company"
              name="brandId"
            >
              {companies.map((company) => (
                <MenuItem key={company.brandId} value={company.brandId}>
                  {company.registeredName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Add Company form as modal */}
          <AddCompany onUpdate={handleUpdate} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Start Date"
            type="date"
            variant="standard"
            name="startDate"
            fullWidth
            value={formatDate(formData.startDate)}
            onChange={handleInputChange}
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="End Date"
            type="date"
            variant="standard"
            name="endDate"
            fullWidth
            value={formatDate(formData.endDate)}
            onChange={handleInputChange}
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Tutorial Link"
            variant="standard"
            name="tutorialLink"
            fullWidth
            value={formData.tutorialLink}
            onChange={handleInputChange}
            required
            error={!!formErrors.tutorialLink}
            helperText={formErrors.tutorialLink}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-multiple-chip-label"
              style={{ marginTop: "5px" }}
              required
            >
              Rewards
            </InputLabel>
            <Select
              labelId="Rewards"
              id="Rewards"
              multiple
              value={formData.rewards.map(reward => reward.taskRewardInfoId)}
              onChange={handleRewardChange}
              input={<Input id="select-multiple-reward" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    margin: "8px 0",
                    overflowY: "auto",
                  },
                },
              }}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {selected.map((taskRewardInfoId) => {
                    const reward = rewards.find(reward => reward.taskRewardInfoId === taskRewardInfoId);
                    {/* console.log("reward:", reward); */}
                    return (
                      <Chip key={taskRewardInfoId} label={reward.rewardName} />
                    );
                  })}
                </Box>
              )}
            >
              {rewards.map((option) => (
                <MenuItem key={option.taskRewardInfoId} value={option.taskRewardInfoId}>
                  {option.rewardName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Max Limit"
            variant="standard"
            type="number"
            name="submissionMaxLimit"
            fullWidth
            value={formData.submissionMaxLimit}
            onChange={maxLimitChange}
            error={parseInt(formData.submissionMaxLimit) < 0}
            helperText={
              parseInt(formData.submissionMaxLimit) < 0
                ? "Max Limit cannot be negative"
                : formErrors.submissionMaxLimit
            }
          />
        </Grid>

        <Grid container mt={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "left", marginLeft: "15px" }}
                >
                  <b>Approval Required</b>
                </Grid>
                <Grid item xs={3} style={{ textAlign: "left" }}>
                  <FormControlLabel
                    value={1}  /* Set the value to 1 for "Yes" */
                    control={<Radio />}
                    label="Yes"
                    checked={selectedApproval === 1}  /* Check for 1 instead of "Yes" */
                    onChange={handleApprovalChange}
                  />
                </Grid>
                <Grid item xs={3} style={{ textAlign: "left" }}>
                  <FormControlLabel
                    value={0}  /* Set the value to 0 for "No" */
                    control={<Radio />}
                    label="No"
                    checked={selectedApproval === 0}  /* Check for 0 instead of "No" */
                    onChange={handleApprovalChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={1}>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "left", marginLeft: "15px" }}
                >
                  <b>Task Type</b>
                </Grid>
                <Grid item xs={3} style={{ textAlign: "left" }}>
                  <FormControlLabel
                    value={1}  /* Set the value to 1 for "Type 1" */
                    control={<Radio />}
                    label="Type 1"
                    checked={selectedTaskType === 1}  /* Check for 1 instead of "Type 1" */
                    onChange={handleTaskTypeChange}
                  />
                </Grid>
                <Grid item xs={3} style={{ textAlign: "left" }}>
                  <FormControlLabel
                    value={2}  /* Set the value to 2 for "Type 2" */
                    control={<Radio />}
                    label="Type 2"
                    checked={selectedTaskType === 2}  /* Check for 2 instead of "Type 2" */
                    onChange={handleTaskTypeChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={1}>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "left", marginLeft: "15px" }}
                >
                  <b>Personal details</b>
                </Grid>
                <Grid item xs={3} style={{ textAlign: "left" }}>
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Yes"
                    checked={includePersonalDetails === 1}
                    onChange={handleRadioChange}
                  />
                </Grid>
                <Grid item xs={3} style={{ textAlign: "left" }}>
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="No"
                    checked={includePersonalDetails === 0}
                    onChange={handleRadioChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          {includePersonalDetails === 1 && (
            <Grid
              container
              spacing={2}
              style={{ textAlign: "left", marginLeft: "15px" }}
            >
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nameChecked}
                      onChange={handleCheckboxChange("Name")}
                    />
                  }
                  label="Name"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={emailChecked}
                      onChange={handleCheckboxChange("Email")}
                    />
                  }
                  label="Email"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={phoneNumberChecked}
                      onChange={handleCheckboxChange("PhoneNumber")}
                    />
                  }
                  label="Phone number"
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid container mt={1}>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "left", marginLeft: "15px" }}
                >
                  <b>Task level</b>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "left" }}>
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Easy"
                    checked={selectedTaskLevel === 1}
                    onChange={handleTaskLevelChange}
                  />
                </Grid>
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Medium"
                    checked={selectedTaskLevel === 2}
                    onChange={handleTaskLevelChange}
                  />
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Hard"
                    checked={selectedTaskLevel === 3}
                    onChange={handleTaskLevelChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>

      </Grid>

      {/* {subtaskList.length > 0 && (
          <div>
            <h2>Subtasks:</h2>
            <Grid container spacing={2}>
              {subtaskList.map((subtask, index) => (
                <Grid item xs={12} key={index}>
                  <Paper style={{ padding: 10, textAlign: 'left' }}>
                    <strong>{`Subtask ${index + 1}:`}</strong>
                    <div>{`Title: ${subtask.SubtaskTitle}`}</div>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
        )} */}

      <Grid item xs={12}>
        <EditSubtaskForm onSubmitAllSubtask={handleSubtaskSubmit} />
      </Grid>


      <Grid item xs={12}>
        {/* Discard and Submit Buttons */}
        <Button
          variant="outlined"
          onClick={() => navigate("/task")}
          color="error"
          endIcon={<DeleteIcon />}
          style={{ margin: "15px" }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="outlined"
          // onClick={() => navigate("/task")}
          color="primary"
          endIcon={<SendIcon />}
          style={{ margin: "15px" }}
          onClick={handleSubmit}

        >
          Submit Form
        </Button>
      </Grid>
      {/* </form> */}
      <ToastContainer position="bottom-right" autoClose={1700} />
    </Container>
  );
};

export default EditTask;
