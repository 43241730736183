import React from 'react'
import UserStatistics from './UserStatistics'
// import UserNavBar from './UserNavBar';
import UserTable from './UserTable';


function User() {
  return (
    <div>
      <UserStatistics />
      {/* <UserNavBar /> */}
      <UserTable />

    </div>
  )
}

export default User;