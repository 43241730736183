import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import axios from '../../axiosConfig';

function Transactions() {
    const [selectedTab, setSelectedTab] = useState('withdrawn');
    const [withdrawnData, setWithdrawnData] = useState([]);
    const [approvedData, setApprovedData] = useState([]);
    const [withdrawnTotalCount, setWithdrawnTotalCount] = useState(0);
    const [approvedTotalCount, setApprovedTotalCount] = useState(0);
    const [withDrawnpaginationModel, setWithDrawnPaginationModel] = useState({ page: 0, pageSize: 100 });
    const [approvedPaginationModel, setApprovedPaginationModel] = useState({ page: 0, pageSize: 100 });

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return 'Pending';
            case 2:
                return 'Processed';
            case 3:
                return 'Reversed';
            case 4:
                return 'Queued';
            case 5:
                return 'Initiated';
            case 6:
                return 'Updated';
            case 7:
                return 'Rejected';
            case 8:
                return 'Failed';
            default:
                return 'Unknown';
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit' };

        const datePart = date.toLocaleDateString(undefined, options);
        const timePart = date.toLocaleTimeString(undefined, timeOptions);

        return `${datePart}, ${timePart}`;
    }

    const fetchApprovedData = async (page, rowsPerPage) => {
        try {
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-approved-transaction?size=${limit}&page=${offset}`);
            console.log(response.data.data);
            const userDataValues = response.data.data.userTransactions.map((obj) => ({
                id: obj.transactionId,
                user: obj.user_info.fullName,
                task: obj.task_info?.taskTitle,
                amount: obj.amount,
                transactionId: obj.transactionId,
                updatedAt: formatDate(obj.updatedAt),
            }));
            console.log(userDataValues);
            setApprovedData(userDataValues);
            setApprovedTotalCount(response.data.data.transactionCount);
        } catch (error) {
            console.error('Error fetching approved transaction data:', error);
        }
    };

    useEffect(() => {
        fetchApprovedData(approvedPaginationModel.page, approvedPaginationModel.pageSize);
    }, [approvedPaginationModel]);

    const fetchWithdrawnData = async (page, rowsPerPage) => {
        try {
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-withdrawal-transaction?size=${limit}&page=${offset}`);

            const userDataValues = response.data.data.userTransactions.map((obj) => ({
                id: obj.transactionId,
                user: obj.user_info.fullName,
                amount: obj.amount,
                transactionId: obj.transactionId,
                status: getStatusLabel(obj.status),
                poutRzrpay: obj.pout_rzrpay,
                utr: obj.utr,
                updatedAt: formatDate(obj.updatedAt),
            }));
            console.log(userDataValues);
            setWithdrawnData(userDataValues);
            setWithdrawnTotalCount(response.data.data.transactionCount);
        } catch (error) {
            console.error('Error fetching withdrawal request data:', error);
        }
    };

    useEffect(() => {
        fetchWithdrawnData(withDrawnpaginationModel.page, withDrawnpaginationModel.pageSize);
    }, [withDrawnpaginationModel]);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    const withdrawnColumns = [
        {
            field: 'user',
            headerName: 'User',
            width: 200,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 100,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'transactionId',
            headerName: 'Transaction ID',
            width: 200,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'poutRzrpay',
            headerName: 'Pout Rzrpay',
            width: 180,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'utr',
            headerName: 'UTR',
            width: 100,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 200,
            align: 'center',
            headerAlign: 'center',
        },
    ];

    const approvedColumns = [
        {
            field: 'user',
            headerName: 'User',
            width: 200,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'task',
            headerName: 'Task',
            width: 200,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 100,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'transactionId',
            headerName: 'Transaction ID',
            width: 200,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 200,
            align: 'center',
            headerAlign: 'center',
        },
    ];

    const handleWithdrawnPaginationModelChange = (model) => {
        setWithDrawnPaginationModel(model);
    }; 
    
    const handleApprovedPaginationModelChange = (model) => {
        setApprovedPaginationModel(model);
    }

    return (
        <div style={{ marginTop: '24px', padding: '16px', marginBottom: '25px' }}>
            <Box
                display={{ xs: 'block', md: 'flex' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems={{ md: 'center' }}
                justifyContent={{ xs: 'flex-start', md: 'space-around' }}
                marginTop={{ xs: '16px' }}
            >
                <Box marginBottom={{ xs: '16px', md: 0, marginLeft: '370px' }}>
                    <ButtonGroup variant="outlined" marginBottom={{ xs: '16px', md: 0 }}>
                        <Button
                            variant={selectedTab === 'withdrawn' ? 'contained' : 'outlined'}
                            onClick={() => handleTabChange('withdrawn')}
                            sx={{
                                borderTopLeftRadius: 20,
                                borderBottomLeftRadius: 20,
                            }}
                        >
                            Withdrawn
                        </Button>
                        <Button
                            variant={selectedTab === 'approved' ? 'contained' : 'outlined'}
                            onClick={() => handleTabChange('approved')}
                            sx={{
                                borderTopRightRadius: 20,
                                borderBottomRightRadius: 20,
                            }}
                        >
                            Credited
                        </Button>
                    </ButtonGroup>
                </Box>
                <Grid item xs={12} sm={6} md={3}>
                    <Link to="/user/withdrawal-request">
                        <Button
                            variant="contained"
                            style={{
                                width: '100%',
                                height: '40px',
                                borderRadius: '20px',
                                color: 'white',
                                marginBottom: '2px',
                            }}
                        >
                            Withdrawal Request
                        </Button>
                    </Link>
                </Grid>
            </Box>
            <div style={{ marginTop: '20px' }}>
                {selectedTab === 'withdrawn' && (
                    <DataGrid
                        rows={withdrawnData}
                        columns={withdrawnColumns}
                        getRowId={(row) => row.transactionId}
                        pagination
                        paginationMode="server"
                        rowCount={withdrawnTotalCount}
                        pageSizeOptions={[100,500, 1000, 5000]}
                        paginationModel={withDrawnpaginationModel}
                        onPaginationModelChange={handleWithdrawnPaginationModelChange}
                    />
                )}
                {selectedTab === 'approved' && (
                    <DataGrid
                        rows={approvedData}
                        columns={approvedColumns}
                        getRowId={(row) => row.transactionId}
                        pagination
                        paginationMode="server"
                        rowCount={approvedTotalCount}
                        pageSizeOptions={[100,500, 1000, 5000]}
                        paginationModel={approvedPaginationModel}
                        onPaginationModelChange={handleApprovedPaginationModelChange}
                    />
                )}
            </div>
        </div>
    );
}

export default Transactions;
