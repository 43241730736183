// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCL6aZK2jkwuuahg-J6uuk8e_0kyyRwPrI",
  authDomain: "boompanda-project.firebaseapp.com",
  projectId: "boompanda-project",
  storageBucket: "boompanda-project.appspot.com",
  messagingSenderId: "743133345472",
  appId: "1:743133345472:web:ce21180aebe05fdcfa9f25",
  measurementId: "G-8HFH20ZNCB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

export const generateFirebaseToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission);
    if (permission == 'granted') {
        const token = await getToken(messaging);
        console.log(token);
    }
}