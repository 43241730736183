import * as React from 'react';
import { Button, Container, Input } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MUIDataTable from 'mui-datatables';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useRef } from 'react';
import axios from '../../axiosConfig';
import {generateFirebaseToken, messaging } from "./notifications/firebase";
import { onMessage } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging';              

function SendNotification() {

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [listData, setListData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(500);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [ currentRowSelected, setCurrentRowSelected] = useState('');

    useEffect(() => {
        generateFirebaseToken();
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
        })
    },[]);


    const changeRowsPerPage = (rowsPerPage) => {
        setRowsPerPage(rowsPerPage);
        setCurrentPage(0);
    };

    const changePage = (page) => {
        setCurrentPage(page);
        setSelectedRows([]);
    };

    const handleRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
        const selectedUserIds = allRowsSelected.map((row) => listData[row.dataIndex][8]);
        setSelectedUserIds(selectedUserIds);
        setCurrentRowSelected(rowsSelected);
        console.log(rowsSelected.map(index => listData[index][8]));
        setSelectedRows(rowsSelected.map(index => listData[index]));
    };

    const options = {
        filterType: 'checkbox',
        responsive: 'standard',
        selectableRows: 'multiple',
        selectableRowsOnClick: false,
        noSsr: true,
        elevation: 0,
        onRowSelectionChange: handleRowSelectionChange,
        customHeadLabelRender: (data, dataIndex, rowIndex) => {
            return (
                <div style={{ textAlign: 'center' }}>
                    {data}
                </div>
            );
        },
        rowsSelected: currentRowSelected,
        count: totalCount,
        serverSide: true,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [100, 500,1000, 5000],
        page: currentPage,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    changePage(tableState.page);
                    console.log('tableState page- ', tableState.page);
                    break;
                case 'changeRowsPerPage':
                    changeRowsPerPage(tableState.rowsPerPage);
                    console.log('tableState rowperpage- ', tableState.rowsPerPage);
                    break;
                default:
                    break;
            }
        }
    };


    const fetchUsers = async (page, rowsPerPage) => {
        try {
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;
            // console.log(limit, offset);
            await axios.post(`${process.env.REACT_APP_BASE_URL}/get-all-users`, {
                size: limit,
                page: offset
            })
                .then((response) => {
                    // console.log(response.data);
                    setListData(response.data.data.map((obj) => [
                        obj.userId,
                        obj.fullName,
                        obj.emailId,
                        obj.course_info.courseName,
                        obj.college_info.collegeName,
                        obj.year,
                        obj.city_info.cityName,
                        obj.city_info.state_info.stateName,
                        obj.deviceToken,
                    ]));
                    setTotalCount(response.data.totalCount);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.error('Error fetching users data:', error);
        }
    }

    useEffect(() => {
        fetchUsers(currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage]);

    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleSendNotification = async () => {
        console.log('Sending notification...');

        console.log(selectedRows);

        for (let rowIndex in selectedRows) {
            if (selectedRows.hasOwnProperty(rowIndex)) {
                const deviceToken = selectedRows[rowIndex][8]; // Access deviceToken from the data array
                // console.log(`Sending notification to deviceToken ${deviceToken}:`);
                // console.log(`Title: ${title}`);
                // console.log(`Message: ${message}`);
                // if (selectedFile) {
                //     console.log(`Image: ${URL.createObjectURL(selectedFile)}`);
                // }

                try {
                    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/send-notification`, {
                        deviceToken: deviceToken,
                        title: title,
                        message: message,
                    });
                    console.log(response.data);
                } catch (error) {
                    console.error(`Error sending notification: ${error}`);
                }
            }
        }

        // Clear the form after sending the notification
        setSelectedFile(null);
        setTitle('');
        setMessage('');

        // Clear the selected rows in the MUIDataTable
        setSelectedRows([]);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = listData.filter((row) =>
        row.some((cell) =>
            cell.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const listColumns = [
        { name: 'UID', label: 'UID' },
        { name: 'Name', label: 'Name' },
        { name: 'Email', label: 'Email' },
        { name: 'Course', label: 'Course' },
        { name: 'College', label: 'College' },
        { name: 'Year', label: 'Year' },
        { name: 'City', label: 'City' },
        { name: 'State', label: 'State' },
    ];

    // Additional prop to reset table selection
    const tableProps = {
        onTableChange: () => setSelectedRows([]),
    };

    return (
        <>
            <Container maxWidth="md">
                <Box boxShadow={4} borderRadius={8} bgcolor="white" p={3} style={{ margin: '10px', marginTop: '40px' }}>
                    <Grid container spacing={3} justifyContent="space-evenly" alignItems="center">
                        <Grid item xs={6} md={2}>
                            <div
                                style={{ width: '110px', height: '110px', borderRadius: '50%', overflow: 'hidden', backgroundColor: '#ddd', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', }}
                                onClick={handleImageClick}
                            >
                                {selectedFile ? (
                                    <img src={URL.createObjectURL(selectedFile)} alt="Selected" style={{ borderRadius: '50%' }}
                                    />
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        Img
                                    </Typography>
                                )}
                            </div>
                            <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept="image/*" onChange={handleFileChange} />
                        </Grid>

                        <Grid item xs={6} md={8}>
                            <form>
                                <Box p={0} textAlign={{ xs: 'center', sm: 'left' }}>
                                    <Box>
                                        <TextField id="title" label="Title" sx={{ width: '100%' }} variant="standard" value={title}
                                            onChange={(e) => setTitle(e.target.value)} />
                                        <TextField id="message" label="Add Message" sx={{ width: '100%' }} variant="standard"
                                            value={message} onChange={(e) => setMessage(e.target.value)} />
                                        <Typography variant="body1" sx={{ marginTop: '12px' }}>
                                            Add Student from list
                                        </Typography>
                                    </Box>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <div style={{ marginTop: '24px', padding: '16px', marginBottom: '35px' }}>
                <Button variant="contained" color="primary" onClick={handleSendNotification}>
                    Send Notification
                </Button>
                <div>
                    <TextField
                        label="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                        variant="standard"
                        style={{ marginBottom: '10px', marginTop: '10px' }}
                    />
                </div>
                <MUIDataTable
                    title={'User list'}
                    data={filteredData}
                    columns={listColumns}
                    options={options}
                    // Add the selectedRows prop
                    selectedRows={selectedRows}
                    // Add the tableProps prop
                    setTableProps={tableProps}
                />
            </div>
        </>
    );
}

export default SendNotification;
