import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Button, Select, MenuItem, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Boomcoin from '../assets/boomcoin.png';
import axios from '../../axiosConfig';

// const categories = [
//     'Download & Signup',
//     'Review & Feedback',
//     'Giveaway',
//     'Social Media Promotion',
//     'Survey',
//     'Influencer Marketing',
//     'Content Creation',
//     'Telecalling',
// ];


function TaskCard({ task }) {
    const {
        taskInfoId,
        taskType,
        taskTitle,
        brand_info,
        task_category,
        complexity,
        startDate,
        endDate,
        totalBoomcoins,
        applicationCount,
        submissionCount,
    } = task;

    const { displayName: brandDisplayName, brandLogo: brandLogo } = brand_info;

    // Access nested properties within task_category
    const { categoryName: taskCategoryName } = task_category;

    const formatMonthYear = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    const navigate = useNavigate();

    const handleCardClick = async () => {
        try {
            navigate(`/task/taskDetail`, { state: { taskInfoId: taskInfoId } });
        } catch (error) {
            console.error('Error fetching task details:', error);
        }
    };

    const getComplexityString = (complexityId) => {
        switch (complexityId) {
            case 1:
                return 'Easy';
            case 2:
                return 'Medium';
            case 3:
                return 'Hard';
            default:
                return 'Unknown';
        }
    };

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} marginLeft={2} marginTop={2} >
            <Link
                onClick={(e) => {
                    e.preventDefault();
                    handleCardClick();
                }}
                style={{ textDecoration: 'none' }}
            >
                <Card
                    sx={{
                        border: '0.1px solid #FAA0A0',
                        borderRadius: '25px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                        height: '510px',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '20px',
                        // backgroundColor:'grey'
                    }}
                >
                    <div
                        style={{
                            margin: '0px',
                            padding: '10px',
                            borderRadius: '12.5px',
                            overflow: 'hidden',
                        }}
                    >
                        <img
                            style={{
                                width: '100%',
                                height: '200px',
                                objectFit: 'cover',
                                borderRadius: '25px',
                            }}
                            // src={subtask ? subtask.bannerImageUrl : 'default-image-url'} //FOR IMAGES 
                            src={brandLogo}


                            alt='Event Banner'
                        />
                    </div>

                    <CardContent>
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            style={{
                                height: '70px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {taskTitle}
                        </Typography>

                        <hr />
                        <Typography variant="body2" color="text.#282828">
                            <b>TaskType:</b> {taskType}
                        </Typography>
                        <Typography variant="body2" color="text.#282828">
                            <b>Brand Name:</b> {brandDisplayName}
                        </Typography>
                        <Typography variant="body2" color="text.#282828">
                            <b>Category:</b> {taskCategoryName}
                        </Typography>
                        <Typography variant="body2" color="text.#282828">
                            <b>Task Level:</b> {getComplexityString(complexity)}
                        </Typography>
                        <Typography variant="body2" color="text.#282828">
                            <b>Date:</b> {formatMonthYear(startDate)} - {formatMonthYear(endDate)}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.#282828"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                style={{
                                    width: '25px',
                                    height: '25px',
                                    objectFit: 'contain',
                                    borderRadius: '30px',
                                    verticalAlign: 'middle',
                                    marginRight: '5px',
                                }}
                                src={Boomcoin}
                                alt="boomcoin"
                            />
                            <b>{totalBoomcoins}</b>
                        </Typography>
                    </CardContent>
                    <div
                        style={{
                            margin: '0 10px 10px 10px',
                            padding: '10px',
                            borderRadius: '20px',
                            overflow: 'hidden',
                            background: '#A9A9A9',
                        }}
                    >
                        <Typography variant="body2" color="text.primary">
                            <b>Applications:</b> {applicationCount}
                        </Typography>
                        <Typography variant="body2" color="text.primary">
                            <b>Submissions:</b> {submissionCount}
                        </Typography>
                    </div>
                </Card>
            </Link>
        </Grid>
    );
}

// In the TaskCardList component, you can use the Outlet to render TaskDetail component
export default function TaskCardList() {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [tasks, setTasks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [page, setPage] = useState(0); // Current page number
    const [pageSize, setPageSize] = useState(10); // Number of tasks per page
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);


    useEffect(() => {
        setLoading(true); // Set loading state to true
        axios.post(`${process.env.REACT_APP_BASE_URL}/get-all-tasks-admin?size=${pageSize}&page=${page}`)
            .then(response => {
                setTasks(response.data.data); // Append new tasks to the existing list
                console.log("Data through api is ", response.data.data);
                setTotalCount(response.data.totalCount);
                setLoading(false); // Set loading state to false after receiving the response
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
                setLoading(false); // Set loading state to false if there's an error
            });
    }, [page]);


    useEffect(() => {
        // Fetch categories from the server
        axios.post(`${process.env.REACT_APP_BASE_URL}/getCategories`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    const handleLoadMore = () => {
        if (loading) return; // Prevent multiple requests while loading

        setLoading(true); // Set loading state to true
        axios.post(`${process.env.REACT_APP_BASE_URL}/get-all-tasks-admin?size=${pageSize}&page=${page}`)
            .then(response => {
                const newTasks = response.data.data;
                setTasks(prevTasks => [...prevTasks, ...newTasks]); // Append new tasks to the existing list
                setPage(page + 1); // Increment page number
                setLoading(false); // Set loading state to false after receiving the response
            })
            .catch(error => {
                console.error('Error fetching more tasks:', error);
                setLoading(false); // Set loading state to false if there's an error
            });
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    
    const filteredTasks = tasks.filter((task) => {
        const categoryMatch =
            selectedCategory === '' || task.task_category.categoryName === selectedCategory;
        const searchMatch = task.taskTitle.toLowerCase().includes(searchTerm.toLowerCase());

        return categoryMatch && searchMatch;
    });

    return (
        <>
            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                style={{
                    marginTop: '10px',
                    marginRight: '10px',
                    marginLeft: '10px',
                    marginBottom: '20px',
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <Grid item xs={12} sm={6} md={3}>
                    <Link to="/AddTask" style={{ textDecoration: 'none' }}>
                        <Button
                            variant='contained'
                            style={{
                                width: '100%',
                                height: '40px',
                                borderRadius: '20px',
                                backgroundColor: '#4caf50',
                                color: 'white',
                            }}
                        >
                            Create Task
                        </Button>
                    </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Select
                        labelId='SelectCategory'
                        id='category-select'
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        displayEmpty
                        fullWidth
                        style={{
                            height: '40px',
                            borderRadius: '20px',
                            backgroundColor: 'white',
                        }}
                    >
                        <MenuItem value=''>Select a category</MenuItem>
                        {categories.map((category) => (
                            <MenuItem key={category.categoryName} value={category.categoryName}>
                                {category.categoryName}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        variant='standard'
                        label="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        fullWidth
                        style={{
                            height: '40px',
                        }}
                        InputProps={{
                            endAdornment: <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '80px' }}>
                {filteredTasks.map((task) => (
                    <TaskCard key={task.taskInfoId} task={task} />
                ))}
                {filteredTasks.length % pageSize === 0 && (
                    <Grid container justifyContent="center">
                        <Button variant="contained" onClick={handleLoadMore}>
                            Load More
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    );
}
