import React from 'react';
import TaskCard from './TaskCard';
import './Task.css'; // Import the CSS file for additional styling

function Task() {
  return (
    <div className="app-container">
      <div className="content-container">
        <div className="task-card-container"><TaskCard /></div>
      </div>
    </div>
  );
}


export default Task;
