import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, IconButton, Modal, Box, Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Boomcoin from '../../assets/boomcoin.png';
import { useLocation } from 'react-router-dom';
import axios from '../../../axiosConfig';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
    borderRadius: '15px'
};

function TransactionStatistics() {
    const [availableCoins, setAvailableCoins] = useState();
    const [totalEarned, setTotalEarned] = useState();
    const [pendingCoins, setPendingCoins] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const [coinsToAdd, setCoinsToAdd] = useState();
    const [coinsToRemove, setCoinsToRemove] = useState();
    const [reasonToAdd, setReasonToAdd] = useState();
    const [reasonToRemove, setReasonToRemove] = useState();
    const state = useLocation();
    const userId = state.state.userId;

    const fetchAvailableCoins = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-user-wallet-data`, { userId: userId });
            setAvailableCoins(response.data.data.walletAmount);
            setTotalEarned(response.data.data.totalEarned);
            setPendingCoins(response.data.data.pendingCoins);
            console.log(response.data.data);
        } catch (error) {
            console.error('Error fetching available coins:', error);
        }
    };

    useEffect(() => {
        fetchAvailableCoins();
    }, [])

    const handleOpenModal = (type) => {
        setActionType(type);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleConfirmAction = async () => {
        try {
            let response;

            if (actionType === 'add') {
                response = await axios.post(`${process.env.REACT_APP_BASE_URL}/add-coins`, { 
                    coinsToAdd: coinsToAdd, 
                    userId: userId,
                    reasonToAdd: reasonToAdd
                });
            } else if (actionType === 'remove') {
                response = await axios.post(`${process.env.REACT_APP_BASE_URL}/remove-coins`, { 
                    coinsToRemove: coinsToRemove,
                    userId: userId,
                    reasonToRemove: reasonToRemove
                });
            }

            if (response && response.status === 200) {
                fetchAvailableCoins();
                console.log(response.data);
            }
        } catch (error) {
            console.error('Error updating coins:', error);
        } finally {
            handleCloseModal();
        }
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} mt={5} display={'flex'} justifyContent={'space-around'}>
                <Grid item xs={12} sm={6} md={4} lg={2.2} margin={1} padding={2} boxShadow={4} bgcolor="white"
                    style={{ textAlign: 'center', alignItems: 'center', position: 'relative' }}>
                    <IconButton
                        style={{ position: 'absolute', top: '50%', left: '0', transform: 'translateY(-50%)' }}
                        aria-label="remove"
                        onClick={() => handleOpenModal('remove')}
                    >
                        <RemoveIcon />
                    </IconButton>
                    <IconButton
                        style={{ position: 'absolute', top: '50%', right: '0', transform: 'translateY(-50%)' }}
                        aria-label="add"
                        onClick={() => handleOpenModal('add')}
                    >
                        <AddIcon />
                    </IconButton>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <img src={Boomcoin} alt="Boomcoin" style={{ marginRight: '5px', width: '40px', height: '40px' }} />
                        <Typography variant="h5">{availableCoins}</Typography>
                    </div>
                    <p><b>Available boomcoins</b></p>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} margin={1} padding={2} boxShadow={4} bgcolor="white"
                    style={{ textAlign: 'center', }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <img src={Boomcoin} alt="Boomcoin" style={{ marginRight: '5px', width: '40px', height: '40px' }} />
                        <Typography variant="h5">{pendingCoins}</Typography>
                    </div>
                    <p> <b>Pending boomcoins</b></p>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} margin={1} padding={2} boxShadow={4} bgcolor="white"
                    style={{ textAlign: 'center', }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <img src={Boomcoin} alt="Boomcoin" style={{ marginRight: '5px', width: '40px', height: '40px' }} />
                        <Typography variant="h5">{totalEarned}</Typography>
                    </div>
                    <p> <b>Total Earned</b></p>
                </Grid>
            </Grid>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Confirm Action
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        How many coins do you want to {actionType}?
                    </Typography>
                    <TextField
                        label="Coins"
                        type="number"
                        variant='standard'
                        value={actionType === 'add' ? coinsToAdd : coinsToRemove}
                        onChange={(e) => actionType === 'add' ? setCoinsToAdd(e.target.value) : setCoinsToRemove(e.target.value)}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        label="Reason"
                        type="text"
                        variant='standard'
                        fullWidth
                        value={actionType === 'add' ? reasonToAdd : reasonToRemove}
                        onChange={(e) => actionType === 'add' ? setReasonToAdd(e.target.value) : setReasonToRemove(e.target.value)}
                        sx={{ mt: 2 }}
                    />
                    <Button variant="contained" color="primary" onClick={handleConfirmAction} sx={{ mt: 2 }}>
                        Confirm
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleCloseModal} sx={{ mt: 2, ml: 2 }}>
                        Cancel
                    </Button>
                </Box>
            </Modal>
        </Container>
    )
}

export default TransactionStatistics;
