import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function SortableQuestion({ QuestionRenderer, id, index, question, handleDeleteQuestion }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        position: 'relative',
        marginBottom: '20px'
    };

    const handleClickDelete = () => {
        handleDeleteQuestion(index);
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div
                key={id}
                style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    marginBottom: "10px",
                    marginTop: '10px',
                }}
            >
                <QuestionRenderer question={question} />
                <IconButton
                    style={{ position: 'absolute', top: '30px', right: '40px' }}
                    onClick={handleClickDelete}
                    color='error'
                >
                    <DeleteIcon />
                </IconButton>
            </div>
        </div>
    );
}

export default SortableQuestion;