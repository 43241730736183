import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TransactionTable from './TransactionTable';

function TransactionSearch() {
    const [searchText, setSearchText] = useState('');

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', paddingTop: '10px' }}>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        variant='filled'
                        label="Search..."
                        fullWidth
                        style={{
                            height: '45px',
                            width: '290px'
                        }}
                        InputProps={{
                            endAdornment: <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
                        }}
                        onChange={handleSearchChange}
                    />
                </Grid>
            </div>

            <TransactionTable searchText={searchText} />
        </>
    );
}

export default TransactionSearch