import React, { useState, useEffect, useRef, useMemo } from "react";
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    Checkbox,
    Container,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import JoditEditor from 'jodit-react';
import { useLocation } from 'react-router-dom';
import SubtaskForm from "./SubtaskForm";
import { DndContext, closestCorners, KeyboardSensor, PointerSensor, useSensor, useSensors, MouseSensor, } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import EditSortableQuestion from "./EditSortableQuestion";

const EditSubtaskForm = ({ onSubmitAllSubtask }) => {
    const { state } = useLocation();

    const [subtasks, setSubtasks] = useState([]);
    const [isShowForm, setIsShowForm] = useState(false);
    const [questionType, setQuestionType] = useState("short_answer");
    const [isMandatory, setIsMandatory] = useState(false);
    const [newQuestion, setNewQuestion] = useState("");
    const [newOption, setNewOption] = useState("");
    const [options, setOptions] = useState([]);
    const [isSubmissionFormOpen, setIsSubmissionFormOpen] = useState(false);
    const [submissionFormData, setSubmissionFormData] = useState([]);

    const [selectedSubtask, setSelectedSubtask] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const [isAddingSubtask, setIsAddingSubtask] = useState(false);

    const [newSubtaskSubmissionData, setNewSubtaskSubmissionData] = useState([]);
    const [isSubtaskChanged, setIsSubtaskChanged] = useState(false);

    const [formData, setFormData] = useState({
        subtaskTitle: "",
        boomcoins: "",
        requirements: "",
        howToComplete: "",
        startDate: "",
        endDate: "",
    });

    useEffect(() => {
        if (
            state &&
            state.taskDetails &&
            state.taskDetails.subtask_infos
        ) {
            setSubtasks(state.taskDetails.subtask_infos);
        }
    }, [state]);

    useEffect(() => {
        if (
            state &&
            state.taskDetails &&
            state.taskDetails.subtask_infos &&
            Array.isArray(state.taskDetails.subtask_infos)
        ) {
            const subtaskSubmissionForms = state.taskDetails.subtask_infos.map(subtask => subtask.subtask_submission_form);

            // Flatten the array of submission forms
            const flattenedSubmissionForms = subtaskSubmissionForms.flat();

            setSubmissionFormData(flattenedSubmissionForms);
        }
    }, [state]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const editor = useRef(null);

    // Function to add an option to a question
    const handleAddOption = (questionIndex) => {
        setSubmissionFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            const updatedQuestion = { ...updatedFormData[0].submissionForm[questionIndex] };
            updatedQuestion.options.push("");
            updatedFormData[0].submissionForm[questionIndex] = updatedQuestion;
            return updatedFormData;
        });
    };

    const handleCreateSubmissionForm = () => {
        // Create a new question object
        const newQuestionObj = {
            type: questionType,
            mandatory: isMandatory,
            question: newQuestion,
            options: [...options],
        };

        // Update the submission form state to include the new question
        setNewSubtaskSubmissionData((prevForm) => [...prevForm, newQuestionObj]);

        // Reset form fields
        setQuestionType("short_answer");
        setIsMandatory(false);
        setNewQuestion("");
        setNewOption("");
        setOptions([]);
    };



    const ShortAnswerQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <TextField
                label={question.question}
                variant="standard"
                fullWidth
                margin="normal"
            />
        </div>
    );

    const ParagraphQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <TextField
                label={question.question}
                minRows={3}
                placeholder={question.question}
                style={{ width: "100%", marginTop: "10px" }}
            />
        </div>
    );

    const DateQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <TextField
                label={question.question}
                type="date"
                variant="standard"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    );

    const TimeQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <TextField
                label={question.question}
                type="time"
                variant="standard"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder={question.question}
            />
        </div>
    );

    const FileUploadQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <Input type="file" />
            <p>
                <b>Options:</b> N/A
            </p>
        </div>
    );

    const CheckboxQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            {question.options.map((option, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                    }}
                >
                    <Checkbox />
                    <span>{option}</span>
                </div>
            ))}
        </div>
    );

    const DropdownQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <FormControl fullWidth variant="standard" margin="normal">
                <InputLabel>{question.question}</InputLabel>
                <Select label="Select" placeholder={question.question}>
                    {question.options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );

    const QuestionRenderer = ({ question }) => {
        switch (question.type) {
            case "short_answer":
                return <ShortAnswerQuestion question={question} />;
            case "paragraph":
                return <ParagraphQuestion question={question} />;
            case "date":
                return <DateQuestion question={question} />;
            case "time":
                return <TimeQuestion question={question} />;
            case "file_upload":
                return <FileUploadQuestion question={question} />;
            case "checkbox":
                return <CheckboxQuestion question={question} />;
            case "dropdown":
                return <DropdownQuestion question={question} />;
            default:
                return null;
        }
    };

    const handleSubtaskChange = (e) => {
        const { name, value } = e.target;
        setSelectedSubtask((prevSubtask) => ({
            ...prevSubtask,
            [name]: value,
        }));
    };

    // console.log(formData);
    const handleEditSubtask = (subtaskIndex) => {
        const editedSubtask = subtasks[subtaskIndex];
        setSelectedSubtask(editedSubtask);
        // setFormData({ ...editedSubtask });
        setIsFormVisible(true);
        setIsSubtaskChanged(true); // Set isSubtaskChanged to true here
    };

    useEffect(() => {
        setFormData({ ...selectedSubtask });
    }, [selectedSubtask]);

    const handleDeleteSubtask = (index) => {
        // Delete the subtask at the specified index
        setSubtasks((prevSubtasks) => [
            ...prevSubtasks.slice(0, index),
            ...prevSubtasks.slice(index + 1),
        ]);
        setIsSubtaskChanged(true); // Set isSubtaskChanged to true here
    };

    const resetForm = () => {
        setFormData({
            subtaskTitle: "",
            boomcoins: "",
            requirements: "",
            howToComplete: "",
            startDate: "",
            endDate: "",
        });

        setNewSubtaskSubmissionData([]);
        setIsSubmissionFormOpen(false);
        setIsShowForm(false);
    };

    useEffect(() => {
        // This function will be called whenever subtasks changes
        onSubmitAllSubtask(subtasks);
    }, [subtasks]);

    const handleSubmitAllSubtasks = (event) => {
        event.preventDefault();
        // Handle the submission of all subtasks
        console.log("All Subtasks submitted:", subtasks);
        // You can perform additional logic or API calls here

        onSubmitAllSubtask(subtasks);
        // resetForm();
        // setSubtasks([]); // Reset the subtasks to an empty array
    };

    const handleAddSubtask = () => {
        resetForm(); // Reset the form fields when adding a new subtask
        // setIsShowForm(true);
        setIsAddingSubtask(true);
        // handleAddSubmissionForm();
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${year}-${month}-${day}`;
    };

    const handleAddQuestion = () => {
        // Create a new question object
        const newQuestionObj = {
            type: "short_answer", // Set a default type or use a default value
            mandatory: false, // Set a default value
            question: "",
            options: [],
        };

        // Update the submission form state to include the new question
        setSubmissionFormData((prevFormData) => {
            const updatedForm = [...prevFormData];
            updatedForm[0].submissionForm.push(newQuestionObj);
            return updatedForm;
        });
    };

    // Function to handle changes in submission form questions
    const handleSubmissionFormQuestionChange = (questionIndex, key, valueIndex, value) => {
        setSubmissionFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            const updatedQuestion = { ...updatedFormData[0].submissionForm[questionIndex] };

            if (key === 'options') {
                // Ensure the 'options' array exists before updating a specific option
                updatedQuestion[key] = updatedQuestion[key] || [];
                updatedQuestion[key][valueIndex] = value;
            } else {
                updatedQuestion[key] = value;
            }

            updatedFormData[0].submissionForm[questionIndex] = updatedQuestion;
            return updatedFormData;
        });
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10, // Enable sort function when dragging 10px 
            },
        })
    );

    const renderSubmissionFormQuestions = (selectedSubtask, submissionFormData) => {
        if (!selectedSubtask || !submissionFormData || !Array.isArray(submissionFormData) || !submissionFormData[0]) {
            return <p>No submission form questions available</p>;
        }

        // Find the submission form data for the selected subtask
        const subtaskSubmissionForm = submissionFormData?.find(form => form?.submissionFormId === selectedSubtask.subtask_submission_form?.submissionFormId);

        if (!subtaskSubmissionForm || !subtaskSubmissionForm.submissionForm) {
            return <p>No submission form questions available for the selected subtask</p>;
        }

        let submissionFormArray = subtaskSubmissionForm.submissionForm;
        console.log(submissionFormArray);
        const handleDragEnd = (event) => {
            const { active, over } = event;
            console.log(active, over);

            if (active.id && over && active.id !== over.id) {
                // Find the original index of the question being dragged
                const oldIndex = submissionFormArray.findIndex(question => question.questionIndex === active.id);
                // Find the new index of the question being dragged
                const newIndex = submissionFormArray.findIndex(question => question.questionIndex === over.id);
                console.log(oldIndex, newIndex, submissionFormArray);

                if (oldIndex !== -1 && newIndex !== -1) {
                    // Create a new copy of the submission form array
                    const newSubmissionFormArray = [...submissionFormArray];
                    // Remove the question being dragged from its original position
                    const [removed] = newSubmissionFormArray.splice(oldIndex, 1);
                    // Insert the question being dragged at its new position
                    newSubmissionFormArray.splice(newIndex, 0, removed);

                    // Update the questionIndex of all questions based on their new order
                    newSubmissionFormArray.forEach((question, index) => {
                        question.questionIndex = index + 1;
                    });

                    // Update the submission form data for the selected subtask
                    const subtaskSubmissionForm = submissionFormData.find(form => form.submissionFormId === selectedSubtask.subtask_submission_form.submissionFormId);
                    subtaskSubmissionForm.submissionForm = newSubmissionFormArray;

                    // Update the submissionFormData state
                    setSubmissionFormData([...submissionFormData]);
                }
            }
        };

        return (
            <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                <SortableContext items={submissionFormArray.map((question, index) => question.questionIndex)} strategy={verticalListSortingStrategy}>
                    {submissionFormArray.map((question, qIndex) => (
                        <EditSortableQuestion key={question.questionIndex} id={question.questionIndex.toString()} question={question} qIndex={qIndex} handleAddOption={handleAddOption} handleSubmissionFormQuestionChange={handleSubmissionFormQuestionChange} />
                    ))}
                </SortableContext>
            </DndContext>
        );
    };


    const renderSubtaskForms = () => {
        return subtasks.map((subtask, index) => (
            <div key={index}>
                <h2>{`Subtask ${index + 1}`}</h2>
                <p>{`Title: ${subtask.subtaskTitle}`}</p>
                <p>{`Boomcoins : ${subtask.boomcoins || '-'}`}</p>
                <p>{`Requirements : ${subtask.requirements}`}</p>
                <p>{`How to Complete this gig : ${subtask.howToComplete}`}</p>
                <p>{`StartDate : ${formatDate(subtask.startDate)}`}</p>
                <p>{`EndDate : ${formatDate(subtask.endDate)}`}</p>
                {/* <p>{`Submission form : `}</p>
                {subtask.subtask_submission_form?.submissionForm ? (
                    // Existing structure
                    subtask.subtask_submission_form.submissionForm.map((question, qIndex) => (
                        <div key={qIndex}>
                            <p>{`Question ${qIndex + 1}`}</p>
                            <p>{`Question Text: ${question.questionText}`}</p>
                            <p>{`Type: ${question.type}`}</p>
                            <p>{`Mandatory: ${question.mandatory ? 'Yes' : 'No'}`}</p>
                            {["checkbox", "dropdown"].includes(question.type) && (
                                <div>
                                    <p>{`Options:`}</p>
                                    {question.options.map((option, optionIndex) => (
                                        <p key={optionIndex}>{`- ${option}`}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    // New structure
                    subtask.submissionForm?.map((question, qIndex) => (
                        <div key={qIndex}>
                            <p>{`Question ${qIndex + 1}`}</p>
                            <p>{`Question Text: ${question.questionText}`}</p>
                            <p>{`Type: ${question.type}`}</p>
                            <p>{`Mandatory: ${question.mandatory ? 'Yes' : 'No'}`}</p>
                            {["checkbox", "dropdown"].includes(question.type) && (
                                <div>
                                    <p>{`Options:`}</p>
                                    {question.options.map((option, optionIndex) => (
                                        <p key={optionIndex}>{`- ${option}`}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))
                )} */}
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => handleEditSubtask(index)}
                >
                    Edit
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDeleteSubtask(index)}
                >
                    Delete
                </Button>
            </div>
        ));
    };
    console.log(subtasks);

    const handleSubtaskSubmit = async (SubtaskData) => {

        console.log("Subtask Data submitted:", SubtaskData);

        // Send only the first object in the array to setSubtasks
        setSubtasks((prevSubtasks) => [...prevSubtasks, SubtaskData[0]]);

        // Clear the submission form data
        // setSubmissionFormData([]);

        // Hide the form after submission
        setIsFormVisible(false);
    };

    const handleSubtaskUpdate = (e) => {
        e.preventDefault();
        // Extract subtask details directly from formData state
        const updatedSubtask = {
            ...formData,
        };

        console.log("Updated Subtask Data:", updatedSubtask);

        // Update the subtask in the array
        setSubtasks((prevSubtasks) =>
            prevSubtasks.map((subtask) =>
                subtask.subtaskId === updatedSubtask.subtaskId ? updatedSubtask : subtask
            )
        );
        setIsFormVisible(false);
    };


    return (
        <Container maxWidth="md" style={{ marginBottom: "50px" }}>
            <form onSubmit={handleSubmitAllSubtasks} style={{ marginLeft: "20px" }}>
                <div style={{
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                    margin: "10px",
                    padding: "10px",
                }}>
                    {/* Display the list of subtasks */}
                    {subtasks.length > 0 && (
                        <>
                            <h2>Subtasks:</h2>
                            {renderSubtaskForms()}
                        </>
                    )}
                </div>
                <SubtaskForm onSubmitSubtask={handleSubtaskSubmit} />
                {isSubtaskChanged && (
                    <Button type="submit">Add All Subtasks to Task</Button>
                )}

                {isFormVisible && selectedSubtask && (
                    <div>
                        <h1>Edit Subtask</h1>
                        <TextField
                            label="Subtask Title"
                            variant="standard"
                            fullWidth
                            margin="normal"
                            name="subtaskTitle"
                            value={selectedSubtask.subtaskTitle}
                            onChange={(e) => handleSubtaskChange(e)}
                        // Add onChange handler if needed
                        />

                        <TextField
                            name="boomcoins"
                            label="Boom Coins"
                            variant="standard"
                            fullWidth
                            margin="normal"
                            value={selectedSubtask.boomcoins}
                            onChange={(e) => handleSubtaskChange(e)}
                        // Add onChange handler if needed
                        />

                        <TextField
                            name="requirements"
                            label="Mention Requirements to Complete This Subtask"
                            variant="standard"
                            fullWidth
                            margin="normal"
                            value={selectedSubtask.requirements}
                            onChange={(e) => handleSubtaskChange(e)}
                        // Add onChange handler if needed
                        />

                        {/* Add other fields based on your subtask structure */}

                        <div style={{ textAlign: 'left' }}>
                            <p>How to Complete This Gig - </p>
                        </div>
                        <JoditEditor
                            ref={editor}
                            value={selectedSubtask.howToComplete}
                            // config={config}
                            // tabIndex={1}
                            onChange={(newContent) => setFormData((prevData) => ({ ...prevData, howToComplete: newContent }))}
                        />

                        <TextField
                            name="startDate"
                            label="Start Date"
                            type="date"
                            variant="standard"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            value={formatDate(selectedSubtask.startDate)}
                            onChange={(e) => handleSubtaskChange(e)}
                        // Add onChange handler if needed
                        />

                        <TextField
                            name="endDate"
                            label="End Date"
                            type="date"
                            variant="standard"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            value={formatDate(selectedSubtask.endDate)}
                            onChange={(e) => handleSubtaskChange(e)}
                        // Add onChange handler if needed
                        />
                        <h3>Submission Form:</h3>
                        {renderSubmissionFormQuestions(selectedSubtask, submissionFormData)}
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            style={{ margin: "15px" }}
                            onClick={handleSubtaskUpdate}
                        >
                            Submit Subtask
                        </Button><br />
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "10px" }}
                            onClick={() => setIsFormVisible(false)}
                        >
                            Close Form
                        </Button>
                    </div>
                )}
            </form>
        </Container>
    );
};

export default EditSubtaskForm;
