import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import {
  DndContext,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  MouseSensor,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import SortableBanner from './SortableBanner';
import axios from '../../axiosConfig';
import axios1 from 'axios';
const Banner = () => {
  const [open, setOpen] = useState(false);
  const [bannerType, setBannerType] = useState('internal');
  const [internalOption, setInternalOption] = useState('');
  const [externalUrl, setExternalUrl] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [sampleBanners, setSampleBanners] = useState([]);
  const [tasks, setTasks] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getBanners`);
      console.log('Banners:', response.data.data);
      // Extracting bannerImage from the response and mapping it to the format expected by setSampleBanners
      const updatedSampleBanners = response.data.data
        .map(banner => ({
          bannerId: banner.bannerId,
          // type: banner.bannerType === 1 ? 'internal' : 'external',
          bannerImage: banner.bannerImage,
          position: banner.position
        }))
        .sort((a, b) => a.position - b.position);

      setSampleBanners(updatedSampleBanners);
    } catch (error) {
      console.error('Error fetching banners:', error);
      // Handle the error as needed
    }
  };

  //fetch tasks
  const fetchTasks = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-all-tasks-admin?size=10&page=0`);
      console.log('Tasks:', response.data.data);

      setTasks(response.data.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      // Handle the error as needed
    }
  }

  useEffect(() => {
    fetchData();
    fetchTasks();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setImage('');
    setOpen(false);
  };

  const handleBannerTypeChange = (event) => {
    setBannerType(event.target.value);
    setInternalOption('');
    setExternalUrl('');
  };

  const handleInternalOptionChange = (event) => {
    setInternalOption(event.target.value);
  };

  const handleExternalUrlChange = (event) => {
    setExternalUrl(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);


    console.log('Selected File:', file);

    if (file) {
      setImage(file);
      setImageUrl(url);
    }
  };


  const handleAddBanner = async () => {
    try {
      const formData = new FormData();
      formData.append('file', image);

      // Append the selected task ID or external URL based on the bannerType
      if (bannerType === 'internal') {
        formData.append('taskId', internalOption);
        formData.append('bannerType', 1);
      } else if (bannerType === 'external') {
        formData.append('externalLink', externalUrl);
        formData.append('bannerType', 2);
      }

      // Log the formData to check if the image and task/URL are appended correctly
      console.log('FormData:', sampleBanners);

      // Send a POST request to the server to handle image upload
      const response = await axios1.post(`${process.env.REACT_APP_BASE_URL}/uploadBanner`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // The server response may include information about the uploaded image, such as the imagePath
      console.log('Image uploaded successfully:', response.data);
      fetchData();
      // Close the dialog
      handleClose();
    } catch (error) {
      console.error('Error:', error);
      // Handle the error as needed
    }
  };

  const handleDeleteBanner = async (bannerId) => {
    console.log(`Deleting banner with ID: ${bannerId}`);
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/deleteBanner/${bannerId}`);
      console.log(`Banner with ID ${bannerId} deleted successfully`);

      const deletedBannerIndex = sampleBanners.findIndex(banner => banner.bannerId === bannerId);

      // Update positions of subsequent banners
      for (let i = deletedBannerIndex + 1; i < sampleBanners.length; i++) {
        const nextBannerId = sampleBanners[i].bannerId;
        await axios.put(`${process.env.REACT_APP_BASE_URL}/updateBanner/${nextBannerId}`, { position: i });
      }

      // After deletion, refetch the banners to update the UI
      fetchData();
    } catch (error) {
      console.error('Error deleting banner:', error);
      // Handle the error as needed
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (!active || !over || active.id === over.id) {
      return;
    }

    setSampleBanners((items) => {
      const oldIndex = items.findIndex((banner) => banner.bannerId === active.id);
      const newIndex = items.findIndex((banner) => banner.bannerId === over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);

      // Update positions in the database
      Promise.all(newItems.map((item) =>
        axios.put(`${process.env.REACT_APP_BASE_URL}/updateBanner/${item.bannerId}`, { position: null })
      ))
        .then(() => {
          newItems.forEach(async (item, index) => {
            try {
              // Step 2: Set the new position
              await axios.put(`${process.env.REACT_APP_BASE_URL}/updateBanner/${item.bannerId}`, { position: index + 1 });
            } catch (error) {
              console.error('Error updating banner position:', error);
            }
          });
        })
        .catch((error) => {
          console.error('Error setting banner position to null:', error);
        });

      return newItems;
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10, // Enable sort function when dragging 10px 
      },
    })
  );


  return (
    <div style={{ padding: '20px' }}>
      <div style={{ textAlign: 'center' }}>
        <h1>Banner</h1>
        <Button variant="outlined" color="primary" onClick={handleOpen}>
          Add Banner
        </Button>
      </div>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle style={{ textAlign: 'center' }}>Add Banner</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="banner-type"
              name="banner-type"
              value={bannerType}
              onChange={handleBannerTypeChange}
              row
            >
              <FormControlLabel value="internal" control={<Radio />} label="Internal" />
              <FormControlLabel value="external" control={<Radio />} label="External" />
            </RadioGroup>
          </FormControl>

          {bannerType === 'internal' && (
            <FormControl fullWidth margin="normal" variant="standard">
              <InputLabel>Select Task</InputLabel>
              <Select value={internalOption} onChange={handleInternalOptionChange}>
                {tasks.map((task) => (
                  <MenuItem key={task.taskInfoId} value={task.taskInfoId}>
                    {task.taskTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {bannerType === 'external' && (
            <>
              <TextField
                label="Enter External URL"
                value={externalUrl}
                onChange={handleExternalUrlChange}
                fullWidth
                margin="normal"
              />
            </>
          )}

          <div style={{ marginTop: '10px', position: 'relative' }}>
            <label htmlFor="upload-image" style={{ cursor: 'pointer' }}>
              <div
                id="image-upload-box"
                style={{
                  width: '100%',
                  height: '250px',
                  backgroundColor: '#eee',
                  borderRadius: '25px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                {image ? (
                  <img
                    src={imageUrl}
                    alt="Banner Preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '25px' }}
                  />
                ) : (
                  <span style={{ color: '#555' }}>Upload Image</span>
                )}
              </div>
            </label>
            <input
              name='upload-image'
              id="upload-image"
              type="file"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddBanner} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ marginTop: '20px', overflowX: 'auto' }}>
        <DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={handleDragEnd} >
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '25px' }}>
            <SortableContext items={sampleBanners.map(banner => banner.bannerId)} strategy={verticalListSortingStrategy}>
              {sampleBanners.map((banner, index) => (
                <SortableBanner key={banner.bannerId} id={banner.bannerId} index={index} banner={banner} handleDeleteBanner={() => handleDeleteBanner(banner.bannerId)} />
              ))}
            </SortableContext>
          </div>
        </DndContext>
      </div>

    </div>
  );
};

export default Banner;
