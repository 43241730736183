import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import { Box, TextField, Button, Modal } from '@mui/material';

export default function KycInfo() {

    const navigate = useNavigate();
    const state = useLocation();
    const [userId, setUserId] = useState();
    const [kycInfo, setKycInfo] = useState();
    const [rejectionReason, setRejectionReason] = useState('');
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (
            state &&
            state.state.userId
        ) {
            setUserId(state.state.userId);
        }
    }, [state]);

    useEffect(() => {
        if (userId) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/get-user-kyc-details`, { userId: userId })
                .then(response => {
                    setKycInfo(response.data.data);
                    // console.log("Data is ", response.data.data);
                })
                .catch(error => {
                    console.error('Error fetching student data:', error);
                });
        }
    }, [userId]);

    if (!kycInfo) {
        return (
            <div>
                <p>No KYC information available.</p>
            </div>
        );
    }

    const handleAccept = () => {
        const kycStatus = 2;
        try {
            axios.put(`${process.env.REACT_APP_BASE_URL}/update-user-kyc-status`, {
                userId: userId,
                kycStatus: kycStatus
            })
                .then((data) => {
                    console.log('accept api successful', data);
                    navigate('/user');
                })
                .catch((err) => {
                    console.log('Accept api error: ', err);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const handleReject = () => {
        // Open the rejection modal
        setOpenModal(true);
    };

    const handleModalReject = () => {
        const kycStatus = 3; //3 represents "Rejected"

        axios.put(`${process.env.REACT_APP_BASE_URL}/update-user-kyc-status`, {
            userId: userId,
            kycStatus: kycStatus,
            rejectRemark: rejectionReason
        })
            .then(response => {
                console.log('Rejected successfully', response);
                navigate('/user');
            })
            .catch(error => {
                console.error('Error rejecting :', error);
                return null;
            });

        handleModalClose();

    };

    const handleModalClose = () => {
        setOpenModal(false);
        setRejectionReason('');
    };

    const statusMapping = {
        1: 'Requested',
        2: 'Verified',
        3: 'Rejected'
    };

    // Use the mapping object to get the status string
    const statusString = statusMapping[kycInfo.kycStatus];
    console.log(kycInfo);

    function DisplayImage({ src, alt }) {
        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ width: '300px', height: '300px', border: '1px solid grey', borderRadius: '10px', padding: '2px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                    <Box component="img" src={src} alt={alt} style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '10px' }} />
                </div>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                >
                    {alt}
                </Button>
            </div>
        );
    }

    return (
        <div>
            <h2 style={{ marginTop: '30px' }}>KYC Information</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px', justifyContent: 'center', marginBottom: '50px', marginTop:'20px' }}>
                <DisplayImage src={kycInfo.aadharProfile} alt='Aadhar Image' />
                <DisplayImage src={kycInfo.selfie} alt='Selfie' />
                <DisplayImage src={kycInfo.collegeIdBack} alt='College ID (Back)' />
                <DisplayImage src={kycInfo.collegeIdFront} alt='College ID (Front)' />
                <DisplayImage src={kycInfo.signature} alt='Signature' />
            </div>
            {openModal && (
                <Modal
                    open={openModal}
                    onClose={handleModalClose}
                    aria-labelledby="rejection-modal"
                    aria-describedby="rejection-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: '80%', md: 400 },
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <TextField
                            id="rejection-reason"
                            label="Reason for Rejection"
                            multiline
                            rows={4}
                            value={rejectionReason}
                            onChange={(e) => setRejectionReason(e.target.value)}
                            sx={{ marginBottom: 2, width: '100%' }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button variant="outlined" color="error" onClick={handleModalReject}>
                                Reject
                            </Button>
                            <Box sx={{ width: 2 }} />
                            <Button variant="outlined" onClick={handleModalClose}>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
            <Box mt={3} display="flex" justifyContent="center">
                {statusString !== 'Verified' && statusString !== 'Rejected' && (
                    <>
                        <Button variant="outlined" color="success" onClick={handleAccept} sx={{ marginRight: '10px', marginBottom: ' 60px' }}>
                            Accept
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleReject} sx={{ marginLeft: '10px', marginBottom: '60px' }}>
                            Reject
                        </Button>
                    </>
                )}
            </Box>

        </div>
    );
}
