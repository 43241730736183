import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Box, Modal, TextField, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ClearIcon from '@mui/icons-material/Clear';
import axios from '../../axiosConfig';


const TaskAnalysis = () => {
    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState('applications');
    const [selectedRows, setSelectedRows] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [selectedApplicant, setSelectedApplicant] = useState(null);
    const [userIds, setUserId] = useState();
    const [taskId, setTaskId] = useState();
    const [applicationData, setApplicationData] = useState([]);
    const [submissionData, setSubmissionData] = useState([]);
    const [tumId, setTumId] = useState(null);
    const navigate = useNavigate();

    // const tumId = task_user_mapping.tumId || null;
    const [applicationCurrentPage, setApplicationCurrentPage] = useState(0);
    const [submissionCurrentPage, setSubmissionCurrentPage] = useState(0);
    const [applicationCount, setApplicationCount] = useState(0);
    const [submissionCount, setSubmissionCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [reloadData, setReloadData] = useState(false);

    const triggerReload = () => {
        setReloadData(prevState => !prevState);
    };

    const changePage = (page) => {
        // setCurrentPage(page);
        if (selectedTab === 'applications') {
            setApplicationCurrentPage(page);
        } else {
            setSubmissionCurrentPage(page);
        }
    };

    const changeRowsPerPage = (rowsPerPage) => {
        setRowsPerPage(rowsPerPage);
        if (selectedTab === 'applications') {
            setApplicationCurrentPage(0);
        } else {
            setSubmissionCurrentPage(0);
        }
    };

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    useEffect(() => {
        if (
            state &&
            state.taskDetails
        ) {
            setTaskId(state.taskDetails.taskInfoId);
            console.log(state);
        }
    }, [state]);

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return 'Pending';
            case 2:
                return 'Accepted';
            case 3:
                return 'Rejected';
            default:
                return 'Unknown';
        }
    };

    const isButtonDisabled = (rowIndex, action) => {
        const rowData = flattenedData[rowIndex];
        const status = rowData.Status;

        if (status) {
            if (status === 1) {
                return { disabled: true };
            } else if (status === 2 && action === 'reject') {
                return { disabled: true };
            } else if (status === 3) {
                return { disabled: false };
            }
        } else {
            console.error(`Invalid row data at index ${rowIndex}`);
            // return { disabled: true }; // Default to disabled if rowData is invalid
        }
    };


    const applicationColumns = [
        'UID',
        'Name',
        'College Name',
        'Branch',
        'Year',
        'Location',
        'Status',
        {
            name: 'View',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Button
                        variant='outlined'
                        color="secondary"
                        onClick={() => handleView(tableMeta.rowIndex)}
                    >
                        View
                    </Button>
                ),
            },
        },
        {
            name: 'Accept',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Button
                        variant='outlined'
                        color="success"
                        onClick={() => handleAccept(tableMeta.rowIndex)}
                        disabled={isButtonDisabled(tableMeta.rowIndex, 'accept')}
                    >
                        Accept
                    </Button>
                ),
            },
        },

        {
            name: 'Reject',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Button
                        variant='outlined'
                        color="error"
                        onClick={() => handleSingleReject(tableMeta.rowIndex)}
                        disabled={isButtonDisabled(tableMeta.rowIndex, 'reject')}
                    >
                        Reject
                    </Button>
                ),
            },
        },

    ];
    // console.log(applicationData);
    const flattenedData = applicationData.map(item => ({
        UID: item["user_info.userId"],
        Name: item["user_info.fullName"],
        'College Name': item["user_info.college_info.collegeName"],
        Branch: item["user_info.course_info.courseName"],
        Year: item["user_info.city_info.cityId"],
        Location: item["user_info.city_info.cityName"],
        Status: getStatusLabel(item.taskRequestStatus),
        userId: item["user_info.userId"],
        // profileImg: item["user_info.profileImg"],
        // collegeId: item["user_info.college_info.collegeId"],
        // courseId: item["user_info.course_info.courseId"],
    }));

    const submissionFlattenedData = submissionData.map(item => ({
        UID: item["task_user_mapping.tumId"],
        Name: item["task_user_mapping.user_info.fullName"],
        'College Name': item["task_user_mapping.user_info.college_info.collegeName"],
        Year: item["task_user_mapping.user_info.year"],
        Location: item["task_user_mapping.user_info.city_info.cityName"],
        // userId: item["task_user_mapping.userId"],
    }));
    console.log(submissionFlattenedData);
    const submissionColumns = [
        'UID',
        'Name',
        'College Name',
        'Year',
        'Location',
        {
            name: 'Submission Detail',
            options: {
                customBodyRender: (value, tableMeta) => (
                    // <Link onClick={(e) => {
                    //     e.preventDefault();
                    //     // navigate(`/submission-detail`, { state: {tumId : tumId} });
                    //     navigate(`/submission-detail2`, { state: {taskInfoId : taskId, tumId : tumId} });
                    // }}
                    // // to={`/submission-detail/${tableMeta.rowData[0]}`}
                    // >
                    //     <Button variant='outlined' color="success">
                    //         View Submission
                    //     </Button>
                    // </Link>
                    <Link
                        onClick={(e) => {
                            e.preventDefault();

                            if (state.taskDetails.taskType === 2) {
                                navigate(`/submission-detail2`, { state: { userName: tableMeta.rowData[1] ,taskInfoId: taskId, tumId: tableMeta.rowData[0] } });
                            } else if (state.taskDetails.taskType === 1) {
                                navigate(`/submission-detail`, { state: { tumId: tableMeta.rowData[0] } });
                            }
                        }}
                    >
                        <Button variant='outlined' color="success">
                            View Submission
                        </Button>
                    </Link>
                ),
            },
        },
    ];

    const handleMultipleAccept = (selectedRows) => {

        // Retrieve UID based on the row numbers
        const uids = selectedRows.map((row) => {
            const rowIndex = row.index;
            const rowData = flattenedData[rowIndex];

            if (rowData) {
                const uid = rowData.userId;
                return uid;
            } else {
                console.error(`Invalid row data at index ${rowIndex}:`, rowData);
                return null;
            }
        });
        setUserId(uids);

        try {
            axios.put(`${process.env.REACT_APP_BASE_URL}/update-task-application`, { userId: uids, taskInfoId: taskId, taskRequestStatus: 2, })
            console.log('multiple accept api successful');
            setTimeout(() => {
                setReloadData(prevState => !prevState);
            }, 500);
        } catch (error) {
            console.log(error);
        }
    };

    const handleMultipleReject = (selectedRows) => {

        // Retrieve UID based on the row numbers
        const uids = selectedRows.map((row) => {
            const rowIndex = row.index;
            const rowData = flattenedData[rowIndex];

            if (rowData) {
                const uid = rowData.userId;
                return uid;
            } else {
                console.error(`Invalid row data at index ${rowIndex}:`, rowData);
                return null;
            }
        });

        // Log the rejected UID (for debugging purposes)
        setModalOpen(true);
        setUserId(uids);
        console.log('Rejected UIDs:', uids);

    };

    const options = {
        filterType: 'checkbox',
        responsive: 'standard',
        selectableRows: selectedTab === 'applications' ? 'multiple' : 'none',
        selectableRowsOnClick: selectedTab === 'applications', // Enable row selection on click only for applications
        noSsr: true,
        elevation: 0,

        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div>
                {selectedTab === 'applications' && (
                    <>
                        <IconButton onClick={() => handleMultipleAccept(selectedRows.data)}>
                            <Button variant="text" color="success" startIcon={<DoneAllIcon />} >
                                Accept All
                            </Button>
                        </IconButton>
                        <IconButton onClick={() => handleMultipleReject(selectedRows.data)}>
                            <Button variant="text" color="error" startIcon={<ClearIcon />} >
                                Reject All
                            </Button>
                        </IconButton>
                    </>
                )}
            </div>
        ),
        // pagination: true,  // Add this line to enable pagination
        // rowsPerPageOptions: [5, 10, 20],  // You can customize the number of rows per page
        rowsPerPage: 10,  // Set an initial value for rows per page
        tableBodyMaxHeight: 'calc(100vh - 250px)',
        root: {
            marginBottom: '50px',
        },
        filter: true,
        serverSide: true,
        count: selectedTab === 'applications' ? applicationCount : submissionCount,
        page: selectedTab === 'applications' ? applicationCurrentPage : submissionCurrentPage,
        rowsPerPage: rowsPerPage,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    changePage(tableState.page);
                    console.log('tableState page- ', tableState.page);
                    break;
                case 'changeRowsPerPage':
                    changeRowsPerPage(tableState.rowsPerPage);
                    console.log('tableState rowperpage- ', tableState.rowsPerPage);
                    break;
                default:
                    break;
            }
        }
    };

    const fetchApplicationData = async (page, rowsPerPage) => {
        try {
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-task-applications`, {
                taskInfoId: taskId,
                size: limit,
                page: offset,
            });

            setApplicationData(response.data.data);
            setApplicationCount(response.data.totalCount);
        } catch (error) {
            console.error('Error fetching application data:', error);
        }
    };

    useEffect(() => {
        if (taskId) {
            fetchApplicationData(applicationCurrentPage, options.rowsPerPage);
        } else {
            console.error('Task ID is invalid:', taskId);
        }
    }, [reloadData, taskId, applicationCurrentPage, options.rowsPerPage]);

    const fetchSubmissionData = async (page, rowsPerPage) => {
        try {
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-task-submission-users`, {
                taskInfoId: taskId,
                size: limit,
                page: offset,
            });

            setSubmissionData(response.data.data);
            setSubmissionCount(response.data.totalCount);
        } catch (error) {
            console.error('Error fetching submission data:', error);
        }
    };

    useEffect(() => {
        if (taskId) {
            fetchSubmissionData(submissionCurrentPage, options.rowsPerPage);
        } else {
            console.error('Task ID is invalid:', taskId);
        }
    }, [taskId, submissionCurrentPage, options.rowsPerPage]);


    const handleAccept = async (tableIndex) => {
        // Get the user data from flattenedData using tableIndex
        const userData = flattenedData[tableIndex];

        // Check if userData is valid
        if (userData) {
            const userId = userData.userId;

            // Implement your logic for accepting an application
            console.log(`Accepted UID: ${userId}`);

            try {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/update-task-application`, {
                    userId: userId,
                    taskInfoId: taskId,
                    taskRequestStatus: 2,
                });
                console.log('accept api successful');
                setReloadData(prevState => !prevState);
            } catch (error) {
                console.log(error);
            }

            // Clear selected rows
            setSelectedRows([]);
        } else {
            console.error(`Invalid user data at index ${tableIndex}`);
        }
    };

    const handleSingleReject = (tableIndex) => {
        // Get the user data from flattenedData using tableIndex
        const userData = flattenedData[tableIndex];
        console.log(userData);
        // Check if userData is valid
        if (userData) {
            const userId = userData.userId;
            // Log the rejected UID (for debugging purposes)
            setModalOpen(true);
            setUserId(userId);
            // console.log('Rejected UIDs:', userId);   
        } else {
            console.error(`Invalid user data at index ${tableIndex}`);
        }
    }

    const handleReject = async () => {

        // Implement your logic for rejecting an application
        console.log(`Rejecting UID: ${userIds}, taskinfoid- ${taskId}, reason- ${rejectionReason}`);
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/update-task-application`, { userId: userIds, taskInfoId: taskId, taskRequestStatus: 3, rejectionRemark: rejectionReason })
            console.log('rejection api successful');
            setReloadData(prevState => !prevState);
        } catch (error) {
            console.log(error);
        }
        setModalOpen(false);
        setRejectionReason('');
    };

    const handleView = (rowIndex) => {
        const applicantData = flattenedData[rowIndex];
        setSelectedApplicant(applicantData);
        setViewDialogOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        // Reset rejection reason state
        setRejectionReason('');
    };

    return (
        <div style={{ marginTop: '24px', padding: '16px' }}>
            <Box
                display={{ xs: 'block', md: 'flex' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems={{ md: 'center' }}
                justifyContent={{ xs: 'flex-start', md: 'space-around' }}
                marginTop={{ xs: '16px' }}
            >
                <Box marginBottom={{ xs: '16px', md: 0 }}>
                    <Link to="/task/task-detailed-info">
                        <Button variant='outlined' sx={{ borderRadius: 20 }}>
                            Task Detail
                        </Button>
                    </Link>
                </Box>
                <Box marginBottom={{ xs: '16px', md: 0 }}>
                    <ButtonGroup variant="outlined" marginBottom={{ xs: '16px', md: 0 }}>
                        <Button
                            variant={selectedTab === 'applications' ? 'contained' : 'outlined'}
                            onClick={() => handleTabChange('applications')}
                            sx={{
                                borderTopLeftRadius: 20,
                                borderBottomLeftRadius: 20,
                            }}
                        >
                            Applications
                        </Button>
                        <Button
                            variant={selectedTab === 'submissions' ? 'contained' : 'outlined'}
                            onClick={() => handleTabChange('submissions')}
                            sx={{
                                borderTopRightRadius: 20,
                                borderBottomRightRadius: 20,
                            }}
                        >
                            Submissions
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box>
                    <Link onClick={(e) => {
                        e.preventDefault();
                        navigate(`/task/boomcoin-disburse`, { state: { taskInfoId: taskId } });
                    }}
                    >
                        <Button
                            variant='outlined'
                            sx={{
                                borderRadius: 20,
                            }}
                        >
                            Disburse payment
                        </Button>
                    </Link>
                </Box>
            </Box>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="rejection-modal"
                aria-describedby="rejection-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: { xs: '80%', md: 400 }, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <TextField
                        id="rejection-reason"
                        label="Reason for Rejection"
                        multiline
                        rows={4}
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        sx={{ marginBottom: 2, width: '100%' }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button variant="outlined" color="error" onClick={handleReject}>
                            Reject
                        </Button>
                        <Box sx={{ width: 2 }} />
                        <Button variant="outlined" onClick={handleModalClose}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Modal for viewing applicant details */}
            <Dialog open={viewDialogOpen} onClose={() => setViewDialogOpen(false)}>
                <DialogTitle>Applicant Details</DialogTitle>
                <DialogContent>
                    {selectedApplicant && (
                        <div>
                            <Typography variant="body1" gutterBottom>
                                <strong>Name:</strong> {selectedApplicant['Name']}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>College Name:</strong> {selectedApplicant['College Name']}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Branch:</strong> {selectedApplicant['Branch']}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Year:</strong> {selectedApplicant['Year']}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Location:</strong> {selectedApplicant['Location']}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Status:</strong> {selectedApplicant['Status']}
                            </Typography>
                            {/* Add more details as needed */}
                        </div>
                    )}
                </DialogContent>
                {/* <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions> */}
            </Dialog>

            {selectedTab === 'applications' && (
                <MUIDataTable
                    title={'Application List'}
                    data={flattenedData}
                    columns={applicationColumns}
                    options={options}
                />
            )}
            {selectedTab === 'submissions' && (
                <MUIDataTable
                    title={'Submission List'}
                    data={submissionFlattenedData}
                    columns={submissionColumns}
                    options={options}
                />
            )}
        </div>
    );
};

export default TaskAnalysis;
