import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import { Grid } from '@mui/material';
import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';

function UserNavBar({ onSearch }) {

    const handleSearch = (e) => {
        const searchText = e.target.value;
        onSearch(searchText);
    };

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" style={{
            padding: '10px', marginBottom: '10px', marginTop: '0px', display: 'flex', justifyContent: 'space-around'
        }}>
            <Grid item xs={12} sm={6} md={3}>
                <TextField
                    variant='standard'
                    label="Search..."
                    onChange={handleSearch}
                    fullWidth
                    style={{
                        height: '40px',
                    }}
                    InputProps={{
                        endAdornment: <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <Link to="/user/send-notification">
                    <Button
                        variant='contained'
                        style={{
                            width: '100%',
                            height: '40px',
                            borderRadius: '20px',
                            backgroundColor: '#4caf50',
                            color: 'white',
                            marginBottom: '2px'
                        }}
                    >
                        Send Notifications
                    </Button>
                </Link>
            </Grid>

        </Grid>
    )
}

export default UserNavBar