import React from 'react';
import Box from '@mui/material/Box';
import { Modal, Grid, Typography, Button } from '@mui/material';
import ProfileImage from '../assets/100.jpg';
import { Link, useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '40px',

};

const UserModal = ({ open, handleClose, user }) => {
    
    const navigate = useNavigate();
    
    if (!user) {
        return null; // or any other fallback UI when user is null
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ textAlign: 'center', marginBottom: '10px' }}>
                    <Typography>
                        <img src={ProfileImage} alt='#' style={{
                            width: '90px',
                            height: '90px',
                            // objectFit: 'contain',
                            borderRadius: '50px',
                            verticalAlign: 'middle',
                            marginRight: '5px',
                            marginBottom: '10px'
                        }} />
                    </Typography>
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                        {user.name}
                    </Typography>
                </Typography>
                <Typography id="modal-modal-description" >
                    <b>EmailId</b>: {user.email}<br />
                    <b>Mobile no</b>: +91 {user.mobile}<br />
                    <b>City</b> : {user.location}<br />
                    <b>State</b> : {user.state}<br />
                    <b>Gender</b>: Male<br />
                    <b>DOB</b>: 1999-01-29<br />
                    <b>College</b>: {user.collegeName}<br />
                    <b>Course</b> : {user.branch}<br />
                    <b>Year</b>: {user.year}<br />
                    <b>Branch</b>: {user.branch}<br />
                </Typography>
                <Typography sx={{ textAlign: 'center', marginTop:'15px' }}>
                    <Link onClick={(e) => {
                        e.preventDefault();
                        navigate(`/user/transactions`, { state: { userId: user.id } });
                    }}
                    >
                        <Button variant='contained'
                            style={{
                                width: '45%',
                                height: '40px',
                                borderRadius: '20px',
                                backgroundColor: '#4caf50',
                                color: 'white',
                                marginBottom: '1px',
                                marginTop: '6px',
                                marginRight: '15px',
                            }}>Wallet</Button>
                    </Link>
                    <Link onClick={(e) => {
                        e.preventDefault();
                        navigate(`/user/kycUserInfo`, { state: { userId: user.id } });
                        // console.log(user.status);
                    }}
                    >
                        {user.status !== 'Not Submitted' && (
                            <Button
                                variant='contained'
                                style={{
                                    width: '47%',
                                    height: '40px',
                                    borderRadius: '20px',
                                    backgroundColor: '#4caf50',
                                    color: 'white',
                                    marginBottom: '1px',
                                    marginTop: '6px',
                                    marginLeft: '5px',
                                }}
                            >
                                KYC Request
                            </Button>
                        )}

                    </Link>
                </Typography>
            </Box>
        </Modal>
    );
};

export default UserModal;
