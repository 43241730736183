import React from 'react';
import { Typography, Paper, Grid, List, ListItem, ListItemText } from '@mui/material';

const TaskDetailedInfo = () => {
  const taskDetails = {
    title: 'Sample Task',
    category: 'Coding',
    company: 'TechCo',
    startDate: '01-11-2023',
    endDate: '15-11-2023',
    whoCanApply: 'Students',
    tutorialLink: 'https://example.com/tutorial2',
    taskType: 'Direct',
    maxLimit: 50,
    eligibilityParameters: ['Instagram Followers', 'College Specific', 'Any other'],
  };

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '20px', borderRadius: '10px' }}>
          <Typography variant="h4" gutterBottom>
            Task Details
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary={`Task Title: ${taskDetails.title}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Task Category: ${taskDetails.category}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Company: ${taskDetails.company}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Start Date: ${taskDetails.startDate}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`End Date: ${taskDetails.endDate}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Who Can Apply: ${taskDetails.whoCanApply}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Tutorial Link: ${taskDetails.tutorialLink}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Task Type: ${taskDetails.taskType}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Max Limit: ${taskDetails.maxLimit}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Eligibility Parameters: ${taskDetails.eligibilityParameters.join(', ')}`}
              />
            </ListItem>
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TaskDetailedInfo;
