import {
  Button,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import axios from "../../axiosConfig";
// import axios from 'axios';
import { useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
// require('dotenv').config();

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function ImageUploadBox({
  label,
  selectedImageUrl,
  selectedImage,
  handleImageChange,
}) {
  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          width: "300px",
          height: "300px",
          border: "1px solid grey",
          borderRadius: "10px",
          padding: "2px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {selectedImage ? (
          <Box
            component="img"
            src={selectedImageUrl}
            alt={label}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              borderRadius: "10px",
            }}
          />
        ) : (
          "No Image Selected"
        )}
      </div>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload {label}
        <VisuallyHiddenInput type="file" onChange={handleImageChange} />
      </Button>
    </div>
  );
}

function ManualKyc() {
  const pathSegments = window.location.pathname.split("/");
  const userId = pathSegments[3];
  const location = useLocation();
  const user = location.state.user;
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [selectedImages, setSelectedImages] = useState({
    CollegeIdFront: null,
    CollegeIdBack: null,
    Selfie: null,
    Signature: null,
  });
  const [selectedImageUrls, setSelectedImageUrls] = useState({
    CollegeIdFront: "",
    CollegeIdBack: "",
    Selfie: "",
    Signature: "",
  });
  const [panValue, setPanValue] = useState("");
  const [aadharValue, setAadharValue] = useState("");
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [requestId, setRequestId] = useState("");
  const [isAadharVerified, setIsAadharVerified] = useState(false);
  const [aadhaar_number, setAadhar_Number] = useState("");
  const [isPanCardVerified, setIsPanCardVerified] = useState(false);
  const [aadharProfile, setAadharProfile] = useState("");
  const [receivedData, setReceivedData] = useState({});
  const [receivedPanData, setReceivedPanData] = useState({});

  const handlePanChange = (event) => {
    setPanValue(event.target.value);
  };

  const handleAadharChange = (event) => {
    setAadharValue(event.target.value);
  };

  const handleImageChange = (label) => (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error(`No file selected for ${label}`);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;

      setSelectedImages((prevState) => ({
        ...prevState,
        [label]: base64,
      }));

      // Create a URL for the file for previewing purposes
      const url = URL.createObjectURL(file);
      setSelectedImageUrls((prevState) => ({
        ...prevState,
        [label]: url,
      }));
    };
    reader.readAsDataURL(file);
  };

  // console.log('selectedImages = ', selectedImages);
  const genderMap = {
    1: "M",
    2: "F",
  };

  const handleVerifyAadhar = () => {
    const data = {
      aadhaarNumber: aadharValue,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/getOkycOtp`, data)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setRequestId(response.data.data.requestId);
        setOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
    setOpen(true);
  };

  const handleOtpSubmit = () => {
    const data = {
      requestId: requestId,
      otp: otp,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/fetchOkycData`, data)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const responseData = response.data.data;
        const receivedData = {
          fullName: responseData.full_name,
          gender: responseData.gender,
          dob: responseData.dob,
        };

        setReceivedData(receivedData);
        setAadhar_Number(responseData.aadhaar_number);
        setAadharProfile("data:image/jpeg;base64,"+responseData.profile_image);
        setIsAadharVerified(true);
      })
      .catch((error) => {
        alert(error);
      });

    handleClose();
    setOtp("");
  };

  const handleVerifyPan = () => {
    let data ={
      panNumber: panValue,
      getStatusInfo: true,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/panextensive`, data)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const responseData = response.data;
        const receivedPanData = {
          name: responseData.name,
          gender: responseData.gender,
          dob: responseData.dateOfBirth,
          maskedAadhaarNumber: responseData.maskedAadhaarNumber,
        };

        setReceivedPanData(receivedPanData);
        setIsPanCardVerified(true);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = async () => {
    const fields = {
      aadharProfile: aadharProfile,
      collegeIdFront: selectedImages["CollegeIdFront"],
      collegeIdBack: selectedImages["CollegeIdBack"],
      selfie: selectedImages["Selfie"],
      sign: selectedImages["Signature"],
    };

    const responses = {
      userId: userId,
      pan: isPanCardVerified ? panValue : "undefined",
      aadhar: aadhaar_number,
      // aadharValue
    };

    for (const label in fields) {
      const data = {
        image: label,
        userId: userId,
        imagedata: fields[label],
      };
      console.log(data);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/add-images`,
          data
        );

        if (response.data) {
          console.log(`Image ${label} uploaded successfully:`, response.data);
          responses[label] = response.data.data;
        } else {
          alert(`Failed to upload image ${label}. Please try again`);
          return;
        }
      } catch (error) {
        console.error(`Error uploading image ${label}:`, error);
        return;
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update-user-kyc`,
        responses
      );

      if (response.data) {
        console.log("KYC updated successfully:", response.data);
      } else {
        alert("Failed to update KYC. Please try again");
      }
    } catch (error) {
      console.error("Error updating KYC:", error);
    }
  };

  return (
    <div>
      <h1 style={{ marginTop: "40px" }}>ManualKyc for UserID: {userId}</h1>
      <h3>Name : {user.name}</h3>
      <h3>Gender : {user.gender === 1 ? "Male" : "Female"}</h3>
      <h3>College : {user.college}</h3>
      <h3>Branch : {user.branch}</h3>
      <h3>Year : {user.year}</h3>
      <h2 style={{ marginTop: "30px" }}>KYC Information</h2>
      <TextField
        id="aadhar"
        label="aadhar"
        variant="standard"
        value={aadharValue}
        onChange={handleAadharChange}
      />
      {isAadharVerified ? (
        <CheckIcon />
      ) : (
        <Button
          variant="contained"
          onClick={handleVerifyAadhar}
          style={{ marginTop: "10px" }}
        >
          Verify
        </Button>
      )}
      {isAadharVerified && (
        <div>
          <h3>Full Name : {receivedData.fullName}</h3>
          <h3>Gender : {receivedData.gender}</h3>
          <h3>DOB : {receivedData.dob}</h3>
        </div>
      )}
      <br />
      <TextField
        id="pan"
        label="Pan"
        variant="standard"
        value={panValue}
        onChange={handlePanChange}
      />
      {isPanCardVerified ? (
        <CheckIcon />
      ) : (
        <Button
          variant="contained"
          onClick={handleVerifyPan}
          style={{ marginTop: "10px" }}
        >
          Verify
        </Button>
      )}
      {isPanCardVerified && (
        <div>
          <h3>Name : {receivedPanData.name}</h3>
          <h3>Gender: {receivedPanData.gender}</h3>
          <h3>DOB: {receivedPanData.dob}</h3>
          <h3>Masked Aadhaar Number: {receivedPanData.maskedAadhaarNumber}</h3>
        </div>
      )}
      <br />
      <br />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "30px",
          justifyContent: "center",
          marginBottom: "50px",
        }}
      >
        <ImageUploadBox
          label="CollegeId (Front)"
          selectedImage={selectedImages.CollegeIdFront}
          selectedImageUrl={selectedImageUrls.CollegeIdFront}
          handleImageChange={handleImageChange("CollegeIdFront")}
        />
        <ImageUploadBox
          label="CollegeId (Back)"
          selectedImage={selectedImages.CollegeIdBack}
          selectedImageUrl={selectedImageUrls.CollegeIdBack}
          handleImageChange={handleImageChange("CollegeIdBack")}
        />
        <ImageUploadBox
          label="Selfie"
          selectedImage={selectedImages.Selfie}
          selectedImageUrl={selectedImageUrls.Selfie}
          handleImageChange={handleImageChange("Selfie")}
        />
        <ImageUploadBox
          label="Signature"
          selectedImage={selectedImages.Signature}
          selectedImageUrl={selectedImageUrls.Signature}
          handleImageChange={handleImageChange("Signature")}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "60px",
        }}
      >
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enter OTP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the OTP you received.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            variant="filled"
            id="otp"
            label="OTP"
            type="text"
            fullWidth
            value={otp}
            onChange={handleOtpChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOtpSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManualKyc;
