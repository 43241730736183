import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Box, IconButton, Modal, TextField } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { Check, Clear } from '@mui/icons-material'; // Import the icons
import axios from '../../axiosConfig';

const TaskAnalysis = () => {
    const [selectedTab, setSelectedTab] = useState('pending');
    const [selectedRows, setSelectedRows] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [pendingData, setPendingData] = useState([]);
    const [pendingTotalCount, setPendingTotalCount] = useState(0);
    const [approvedTotalCount, setApprovedTotalCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pendingCurrentPage, setPendingCurrentPage] = useState(0);
    const [approvedCurrentPage, setApprovedCurrentPage] = useState(0);
    const [approvedData, setApprovedData] = useState([]);

    const changeRowsPerPage = (rowsPerPage) => {
        setRowsPerPage(rowsPerPage);
        if (selectedTab === 'pending') {
            setPendingCurrentPage(0);
        } else {
            setApprovedCurrentPage(0);
        }
    };

    const changePage = (page) => {
        if (selectedTab === 'pending') {
            setPendingCurrentPage(page);
        } else {
            setApprovedCurrentPage(page);
        }
    };

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    const pendingColumns = [
        'UID',
        'Name',
        'Current Balance',
        'Req Amount',
        'Status',
        {
            name: 'Action',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Box display="flex" alignItems="center">
                        <IconButton color="primary" onClick={(event) => handleAccept(event, tableMeta.rowData[0])}>
                            <Check />
                        </IconButton>
                        <IconButton color="secondary" onClick={(event) => handleReject(event, tableMeta.rowData[0])}>
                            <Clear />
                        </IconButton>
                    </Box>
                ),
            },
        },

    ];

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return 'Pending';
            case 2:
                return 'Processed';
            case 3:
                return 'Reversed';
            case 4:
                return 'Queued';
            case 5:
                return 'Initiated';
            case 6:
                return 'Updated';
            case 7:
                return 'Rejected';
            case 8:
                return 'Failed';
            default:
                return 'Credited';
        }
    };
    // status 1- pending, 2- approved, 3- rejected
    const fetchWithdrawalData = async (page, rowsPerPage) => {
        try {
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-withdrawal-request?size=${limit}&page=${offset}`);

            const userDataValues = response.data.data.map(obj => [
                obj.userId,
                obj.user_info.fullName,
                obj.user_info.user_wallet.walletAmount,
                obj.amount,
                getStatusLabel(obj.status)
            ]);

            setPendingData(userDataValues);
            setPendingTotalCount(response.data.totalCount);
        } catch (error) {
            console.error('Error fetching withdrawal request data:', error);
        }
    };

    useEffect(() => {
        fetchWithdrawalData(pendingCurrentPage, options.rowsPerPage);
    }, []);

    // get-withdrawal-approved
    const fetchApprovedData = async (page, rowsPerPage) => {
        try {
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-withdrawal-approved?size=${limit}&page=${offset}`);

            const userDataValues = response.data.data.map(obj => [
                obj.userId,
                obj.user_info.fullName,
                obj.user_info.user_wallet.walletAmount,
                obj.amount,
                obj.typeOfTransaction === 1 ? 'Credit' : 'Withdraw',
                getStatusLabel(obj.status),
            ]);
            console.log(response.data.data);
            setApprovedData(userDataValues);
            setApprovedTotalCount(response.data.totalCount);
        } catch (error) {
            console.error('Error fetching approved data:', error);
        }
    }

    useEffect(() => {
        fetchApprovedData(pendingCurrentPage, options.rowsPerPage);
    }, []);

    const approvedColumns = [
        'UID',
        'Name',
        'Current Balance',
        'Req Amount',
        'Type Of Transaction',
        'Status',
    ];

    const options = {
        filterType: 'checkbox',
        responsive: 'standard',
        selectableRows: 'multiple',
        selectableRowsOnClick: true,
        noSsr: true,
        elevation: 0,
        onChangeRows: (currentRowsSelected, allRowsSelected) => {
            const selectedUserIds = allRowsSelected.map((row) => row.dataIndex);
            setSelectedRows(selectedUserIds);
        },
        // onRowClick: (rowData) => {
        //     const uid = rowData[0];
        //     if (selectedTab === 'pending') {
        //         navigate(`/applicant-detail/${uid}`);
        //     } else if (selectedTab === 'approved') {
        //         navigate(`/submission-detail/${uid}`);
        //     }
        // },
        setSelectedRows: (rows) => {
            // This callback is invoked after the user interacts with the checkboxes
            // You can use it to clear the selected rows when the "Accept" button is clicked
            setSelectedRows([]);
        },
        count: selectedTab === 'pending' ? pendingTotalCount : approvedTotalCount,
        serverSide: true,
        rowsPerPage: 10,
        page: selectedTab === 'pending' ? pendingCurrentPage : approvedCurrentPage,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    changePage(tableState.page);
                    console.log('tableState page- ', tableState.page);
                    break;
                case 'changeRowsPerPage':
                    changeRowsPerPage(tableState.rowsPerPage);
                    console.log('tableState rowperpage- ', tableState.rowsPerPage);
                    break;
                default:
                    break;
            }
        }
    };

    if (selectedTab === 'pending') {
        options.selectableRows = 'multiple';
        options.selectableRowsOnClick = true;
    } else {
        options.selectableRows = 'none';
        options.selectableRowsOnClick = false;
    }

    const handleAccept = async (event, uid) => {
        event.stopPropagation();
        // Implement your logic for accepting an application
        const dataToSend = {
            userId: uid,
            status: 1
        }
        console.log(dataToSend);

        await axios.post(`${process.env.REACT_APP_BASE_URL}/update-withdrawal-request`, dataToSend)
            .then(response => {
                console.log("Withdrawal accepted - ", response.data);
            })
            .catch(error => {
                console.error('Error accepting withdrawal request:', error);
            })
        // Clear selected rows
        setSelectedRows([]);
    };

    const handleReject = (event, uid) => {
        event.stopPropagation();
        // Implement your logic for rejecting an application
        console.log(`Rejecting UID: ${uid}`);
        handleRejectionSubmit(uid);
        setModalOpen(true);
    };

    const handleRejectionSubmit = async (uid) => {
        // Implement your logic for submitting rejection reason
        console.log('Rejection Reason:', rejectionReason);

        const dataToSend = {
            userId: uid,
            status: 2,
            rejectionRemark: rejectionReason
        }

        await axios.post(`${process.env.REACT_APP_BASE_URL}/update-withdrawal-request`, dataToSend)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error rejecting withdrawal request:', error);
            })
        // Close the modal
        setModalOpen(false);
        // Reset rejection reason state
        setRejectionReason('');
    };

    const handleModalClose = () => {
        setModalOpen(false);
        // Reset rejection reason state
        setRejectionReason('');
    };

    return (
        <div style={{ marginTop: '24px', padding: '16px', marginBottom: '25px' }}>
            <Box
                display={{ xs: 'block', md: 'flex' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems={{ md: 'center' }}
                justifyContent={{ xs: 'flex-start', md: 'space-around' }}
                marginTop={{ xs: '16px' }}
            >

                <Box marginBottom={{ xs: '16px', md: 0 }}>
                    <ButtonGroup variant="outlined" marginBottom={{ xs: '16px', md: 0 }}>
                        <Button
                            variant={selectedTab === 'pending' ? 'contained' : 'outlined'}
                            onClick={() => handleTabChange('pending')}
                            sx={{
                                borderTopLeftRadius: 20,
                                borderBottomLeftRadius: 20,
                            }}
                        >
                            Pending
                        </Button>
                        <Button
                            variant={selectedTab === 'all' ? 'contained' : 'outlined'}
                            onClick={() => handleTabChange('all')}
                            sx={{
                                borderTopRightRadius: 20,
                                borderBottomRightRadius: 20,
                            }}
                        >
                            All
                        </Button>
                    </ButtonGroup>
                </Box>

            </Box>

            {selectedTab === 'pending' && (
                <MUIDataTable
                    title={'Pending List'}
                    data={pendingData}
                    columns={pendingColumns}
                    options={options}
                />
            )}
            {selectedTab === 'all' && (
                <MUIDataTable
                    title={'Approved List'}
                    data={approvedData}
                    columns={approvedColumns}
                    options={options}
                />
            )}

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="rejection-modal"
                aria-describedby="rejection-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: { xs: '80%', md: 400 }, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <TextField
                        id="rejection-reason"
                        label="Reason for Rejection"
                        multiline
                        rows={4}
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        sx={{ marginBottom: 2, width: '100%' }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button variant="outlined" color="error" onClick={handleReject}>
                            Reject
                        </Button>
                        <Box sx={{ width: 2 }} />
                        <Button variant="outlined" onClick={handleModalClose}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default TaskAnalysis;
