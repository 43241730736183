import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    Input,
    Checkbox,
    Container,
    IconButton,
} from "@mui/material";

function EditSortableQuestion({ qIndex, handleAddOption, handleSubmissionFormQuestionChange, id, question}) {

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: question.questionIndex });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        position: 'relative',
        marginBottom: '20px'
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div key={qIndex}
                style={{
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                    margin: "10px",
                    padding: "10px",
                }}>
                <h3>{`Question ${qIndex + 1}`}</h3>
                <TextField
                    label="Question Text"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    value={question.questionText || ''}
                    onChange={(e) => handleSubmissionFormQuestionChange(qIndex, 'questionText', null, e.target.value)}
                />
                <FormControl fullWidth variant="standard" margin="normal">
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={question.type}
                        onChange={(e) => handleSubmissionFormQuestionChange(qIndex, 'type', null, e.target.value)}
                    >
                        <MenuItem value="short_answer">Short Answer</MenuItem>
                        <MenuItem value="paragraph">Paragraph</MenuItem>
                        <MenuItem value="checkbox">Checkbox</MenuItem>
                        <MenuItem value="dropdown">Dropdown</MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                        <MenuItem value="time">Time</MenuItem>
                        <MenuItem value="file_upload">File Upload</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Switch
                            checked={question.mandatory}
                            onChange={() => handleSubmissionFormQuestionChange(qIndex, 'mandatory', null, !question.mandatory)}
                        />
                    }
                    label="Mandatory"
                />
                {(question.type === "checkbox" || question.type === "dropdown") && (
                    <div>
                        <p>Options:</p>
                        {question.options && question.options.length > 0 ? (
                            question.options.map((option, optionIndex) => (
                                <div key={optionIndex}>
                                    <TextField
                                        label={`Option ${optionIndex + 1}`}
                                        variant="standard"
                                        fullWidth
                                        margin="normal"
                                        value={option}
                                        onChange={(e) => handleSubmissionFormQuestionChange(qIndex, 'options', optionIndex, e.target.value)}
                                    />
                                </div>
                            ))
                        ) : (
                            <p>No option available</p>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "10px" }}
                            onClick={() => handleAddOption(qIndex)}
                        >
                            Add Option
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EditSortableQuestion;