import React, { useState, useEffect } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import './SideNavbar.css';
import LogoutIcon from '@mui/icons-material/Logout';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TaskIcon from '@mui/icons-material/Task';
import PersonIcon from '@mui/icons-material/Person';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import Hidden from '@mui/material/Hidden';
import Logo from '../assets/Logo.png';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: 'white', // Make the sidebar completely transparent
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    backgroundColor: 'transparent', // Make the sidebar completely transparent
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'transparent', // Make the AppBar background transparent
    backdropFilter: 'blur(10px)', // Add the blur effect
    WebkitBackdropFilter: 'blur(10px)', // For Safari support
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': {
                ...openedMixin(theme),
            },
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': {
                ...closedMixin(theme),
            },
        }),
    }),
);

function SideNavbar({ adminUsername }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false); // Set initial state based on screen width
    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setOpen(window.innerWidth > 768); // Update state based on screen width
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Function to handle the logout action
    const handleLogout = () => {
        // Perform logout actions, e.g., clearing the token and navigating to the login page
        localStorage.removeItem('jwtToken'); // Clear the token from localStorage
        localStorage.removeItem('requesterId');
        // Refresh the page to take effect
        navigate('/login')
    };

    const icons = [SpaceDashboardIcon, TaskIcon, PersonIcon, ReceiptIcon, ViewCarouselIcon, AdminPanelSettingsIcon, FolderSharedIcon];
    const location = useLocation();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 1,
                            display: open && 'none', // Hide when sidebar is open
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Toolbar>
                        <Link to='/dashboard' style={{ textDecoration: 'none', cursor: 'pointer', color: 'inherit' }}>
                            <Typography variant="h56" noWrap component="div">
                                <img src={Logo} alt="BOOMPANDA" />
                            </Typography>
                        </Link>
                    </Toolbar>

                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                        <Hidden smDown>
                            <div style={{ fontSize: 16, marginRight: '10px', color: 'black' }}>Hello, <b>{adminUsername}</b></div>
                        </Hidden>
                        <IconButton color="inherit" aria-label="log out" onClick={handleLogout} style={{ color: 'black' }}>
                            <LogoutIcon sx={{ fontSize: 32 }} /> {/* Set font size to make the icon larger */}
                        </IconButton>
                    </Box>

                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {['Dashboard', 'Task', 'User', 'Transactions', 'Banner', 'Admin', 'User-KYC'].map((text, index) => {
                        const isSelected = location.pathname === `/${text.toLowerCase()}`;

                        return (
                            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                                <Link to={`/${text.toLowerCase()}`} style={{ textDecoration: 'none', cursor: 'pointer', color: 'inherit' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                            backgroundColor: isSelected ? 'lightgray' : 'transparent', // Change the background color if the tab is selected
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {React.createElement(icons[index])}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={text}
                                            sx={{ opacity: open ? 1 : 0 }}
                                        />
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        );
                    })}
                </List>
                <Divider />
            </Drawer>
        </Box>
    );
}

export default SideNavbar;
