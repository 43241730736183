import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Checkbox,
  Box,
  Container,
  Grid,
  Input,
  Chip,
  Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import AddCompany from "./AddCompany";
import SubtaskForm from "./SubtaskForm";
import axios from '../../axiosConfig';

const AddTask = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    taskTitle: "",
    taskCategory: "",
    company: "",
    startDate: "",
    endDate: "",
    whoCanApply: "",
    tutorialLink: "",
    rewards: [],
    maxLimit: "",
    approvalRequired: "",
    taskType: "",
    taskLevel: "",
    includePersonalDetails: "",
    personalDetails: {
      Name: false,
      Email: false,
      PhoneNumber: false,
    },
  });

  const [selectedRewards, setSelectedRewards] = useState([]);
  const [selectedApproval, setSelectedApproval] = useState(1);
  const [selectedTaskLevel, setSelectedTaskLevel] = useState();
  const [selectedTaskType, setSelectedTaskType] = useState(1);
  const [includePersonalDetails, setIncludePersonalDetails] = useState(0);

  const [formErrors, setFormErrors] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [categories, setCategories] = useState([]);
  const [rewards, setRewards] = useState([]);
  // Add these lines where you define your state variables
  const [nameChecked, setNameChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [phoneNumberChecked, setPhoneNumberChecked] = useState(false);
  const [triggerCompanyFetch, setTriggerCompanyFetch] = useState(false);

  // const rewards = [
  //   "Certificate",
  //   "Flexible Work",
  //   "Cash Reward",
  //   "Enhance soft skill",
  //   "Amazon Voucher",
  //   "Flipkart Voucher",
  //   "Reliance Voucher",
  //   "Croma Voucher",
  // ];

  const [selectedCompany, setSelectedCompany] = useState([]);

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    // Fetch categories from the server
    axios.post(`${process.env.REACT_APP_BASE_URL}/get-brands`)
      .then(response => {
        setCompanies(response.data);
      })
      .catch(error => {
        console.error('Error fetching companies:', error);
      });
  }, [triggerCompanyFetch]);

  useEffect(() => {
    // Fetch categories from the server
    axios.post(`${process.env.REACT_APP_BASE_URL}/getCategories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch categories from the server
    axios.post(`${process.env.REACT_APP_BASE_URL}/get-rewards`)
      .then(response => {
        setRewards(response.data);
      })
      .catch(error => {
        console.error('Error fetching rewards:', error);
      });
  }, []);

  const handleUpdate = () => {
    setTriggerCompanyFetch(prevState => !prevState);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "maxLimit") {
      maxLimitChange(e);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const [subtaskList, setSubtaskList] = useState([]);

  const handleSubtaskSubmit = (subtaskData) => {
    // Log the entire subtaskData array
    console.log("Received subtaskData:", subtaskData);

    // Check if subtaskData is defined and is an array
    if (subtaskData && Array.isArray(subtaskData)) {
      // Check for duplicates and only add unique subtasks
      subtaskData.forEach(subtask => {
        const isDuplicate = subtaskList.some(existingSubtask =>
          Object.keys(existingSubtask).every(key => existingSubtask[key] === subtask[key])
        );

        if (!isDuplicate) {
          // Add the subtask to the subtaskList
          setSubtaskList((prevList) => [...prevList, subtask]);
          console.log(`Added subtask: ${subtask.SubtaskTitle}`);
        } else {
          console.log(`Discarded duplicate subtask: ${subtask.SubtaskTitle}`);
        }
      });
    } else {
      console.error("Invalid subtaskData structure. Unable to process subtasks.");
    }
  };


  const maxLimitChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value === "" ? "" : Number(value),
    }));
  };
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleCategoryChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedCategory(value);
    setFormData((prevData) => ({
      ...prevData,
      taskCategory: value, // Replace 'category' with the appropriate property name in your state
    }));
  };


  const handleCompanyChange = (event) => {
    const selectedBrandId = event.target.value;
    setSelectedCompany(selectedBrandId);

    setFormData((prevData) => ({
      ...prevData,
      company: selectedBrandId,
    }));
  };

  const handleRewardChange = (event) => {
    const selectedObjects = event.target.value;
    
    if (Array.isArray(selectedObjects)) {
      // Handle an array of objects
      const ids = selectedObjects.map(obj => obj.taskRewardInfoId);
      console.log(ids);
    } else if (selectedObjects && selectedObjects.taskRewardInfoId) {
      // Handle a single object
      const objectId = selectedObjects.taskRewardInfoId;
      console.log(objectId);
    } else {
      console.error('Invalid data format');
    }

    setSelectedRewards(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      rewards: event.target.value,
    }));
  };

  const handleApprovalChange = (event) => {
    const value = parseInt(event.target.value, 10);

    setFormData((prevData) => ({
      ...prevData,
      approvalRequired: value,
    }));

    setSelectedApproval(value);
  };

  const handleTaskLevelChange = (event) => {
    const value = parseInt(event.target.value, 10); // or Number(event.target.value);

    setFormData((prevData) => ({
      ...prevData,
      taskLevel: value,
    }));

    setSelectedTaskLevel(value);
  };

  const handleTaskTypeChange = (event) => {
    const value = parseInt(event.target.value, 10); // or Number(event.target.value);
    // console.log(value);
    setFormData((prevData) => ({
      ...prevData,
      taskType: value,
    }));

    setSelectedTaskType(value);
    
  };

  useEffect(() => {
    if (selectedTaskType == 2) {
      setIncludePersonalDetails(0);
    }
  }, [selectedTaskType]);

  const handleRadioChange = (event) => {
    const value = parseInt(event.target.value, 10);

    setFormData((prevData) => ({
      ...prevData,
      includePersonalDetails: value,
    }));

    setIncludePersonalDetails(value);
  };


  const handleCheckboxChange = (field) => (event) => {
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        [field]: event.target.checked,
      },
    }));

    // Update individual state variables for checked status
    if (field === "Name") {
      setNameChecked(event.target.checked);
    } else if (field === "Email") {
      setEmailChecked(event.target.checked);
    } else if (field === "PhoneNumber") {
      setPhoneNumberChecked(event.target.checked);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create an object with selected data
    const submittedData = {
      taskTitle: formData.taskTitle,
      taskCategoryId: formData.taskCategory,
      brandId: formData.company,
      startDate: formData.startDate,
      endDate: formData.endDate,
      rewards: formData.rewards.map(reward => reward.taskRewardInfoId),
      whoCanApply: formData.whoCanApply,
      tutorialLink: formData.tutorialLink,
      status: 1,
      complexity: selectedTaskLevel,
      taskType: selectedTaskType,
      submissionMaxLimit: formData.maxLimit,
      approvalRequired: selectedApproval,
      personalDetailsRequired: includePersonalDetails,
      personalDetailsForm:
        includePersonalDetails === 1
          ? JSON.stringify(formData.personalDetails)
          : null,
      subtaskDetail: subtaskList, // Include subtasks here
    };

    // Send data to the backend
    axios.post(`${process.env.REACT_APP_BASE_URL}/create-task`, submittedData) // Replace with your actual API endpoint
      .then(response => {
        console.log('Task successfully added:', response.data);
        navigate("/task");
      })
      .catch(error => {
        console.error('Error adding task:', error);
        // Handle the error or display a message to the user
      });
    console.log(submittedData);

    // Reset the form data and other state variables
    setFormData({
      taskTitle: "",
      taskCategory: "",
      company: "",
      startDate: "",
      endDate: "",
      whoCanApply: "",
      tutorialLink: "",
      taskType: "",
      maxLimit: "",
      personalDetails: {
        Name: false,
        Email: false,
        PhoneNumber: false,
      },
    });

    setSelectedRewards([]);
    setIncludePersonalDetails(1);
    setSelectedApproval(1);
    setSelectedTaskLevel("");
    setSelectedTaskType(1);
    setIsDialogOpen(false);

    // Clear subtask list
    setSubtaskList([]);
  };


  return (
    <Container maxWidth="md" style={{ marginBottom: "50px", marginTop: "48px" }}>
      <h1>Add Task</h1>
      <form onSubmit={handleSubmit} style={{ marginLeft: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Task Title"
              variant="standard"
              name="taskTitle"
              fullWidth
              value={formData.taskTitle}
              onChange={handleInputChange}
              required
              error={!!formErrors.taskTitle}
              helperText={formErrors.taskTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard" margin="normal" required>
              <InputLabel>Category</InputLabel>
              <Select
                label='category'
                name='category'
                value={formData.taskCategory}
                onChange={handleCategoryChange}
              >
                {/* <MenuItem value=''>Select a category</MenuItem> */}
                {categories.map((category) => (
                  <MenuItem key={category.taskCategoryId} value={category.taskCategoryId}>
                    {category.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard" margin="normal" required>
              <InputLabel>Company</InputLabel>
              <Select
                value={formData.company}
                onChange={handleCompanyChange}
                label="company"
                name="company"
              >
                {companies.map((company) => (
                  <MenuItem key={company.brandId} value={company.brandId}>
                    {company.registeredName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Add Company form as modal */}
            <AddCompany onUpdate={handleUpdate} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Date"
              type="date"
              variant="standard"
              name="startDate"
              fullWidth
              value={formData.startDate}
              onChange={handleInputChange}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="End Date"
              type="date"
              variant="standard"
              name="endDate"
              fullWidth
              value={formData.endDate}
              onChange={handleInputChange}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Who Can Apply"
              variant="standard"
              name="whoCanApply"
              fullWidth
              value={formData.whoCanApply}
              onChange={handleInputChange}
              required
              error={!!formErrors.whoCanApply}
              helperText={formErrors.whoCanApply}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Tutorial Link"
              variant="standard"
              name="tutorialLink"
              fullWidth
              value={formData.tutorialLink}
              onChange={handleInputChange}
              required
              error={!!formErrors.tutorialLink}
              helperText={formErrors.tutorialLink}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-multiple-chip-label"
                style={{ marginTop: "5px" }}
                required
              >
                Rewards
              </InputLabel>
              <Select
                labelId="Rewards"
                id="Rewards"
                multiple
                value={selectedRewards}
                onChange={handleRewardChange}
                input={<Input id="select-multiple-reward" />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Set your desired max height
                      margin: "8px 0", // Add margin
                      overflowY: "auto", // Enable vertical scrolling
                    },
                  },
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {selected.map((value) => (
                      <Chip key={value.taskRewardInfoId} label={value.rewardName} />
                    ))}
                  </Box>
                )}
              >
                {rewards.map((reward) => (
                  <MenuItem key={reward.taskRewardInfoId} value={reward}>
                    {reward.rewardName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Max Limit"
              variant="standard"
              type="number"
              name="maxLimit"
              fullWidth
              value={formData.maxLimit}
              onChange={maxLimitChange}
              error={parseInt(formData.maxLimit) < 0}
              helperText={
                parseInt(formData.maxLimit) < 0
                  ? "Max Limit cannot be negative"
                  : formErrors.maxLimit
              }
            />
          </Grid>

          <Grid container mt={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs={4}
                    style={{ textAlign: "left", marginLeft: "15px" }}
                  >
                    <b>Approval Required</b>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "left" }}>
                    <FormControlLabel
                      value={1}  /* Set the value to 1 for "Yes" */
                      control={<Radio />}
                      label="Yes"
                      checked={selectedApproval === 1}  /* Check for 1 instead of "Yes" */
                      onChange={handleApprovalChange}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "left" }}>
                    <FormControlLabel
                      value={0}  /* Set the value to 0 for "No" */
                      control={<Radio />}
                      label="No"
                      checked={selectedApproval === 0}  /* Check for 0 instead of "No" */
                      onChange={handleApprovalChange}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container mt={1}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs={4}
                    style={{ textAlign: "left", marginLeft: "15px" }}
                  >
                    <b>Task Type</b>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "left" }}>
                    <FormControlLabel
                      value={1}  /* Set the value to 1 for "Type 1" */
                      control={<Radio />}
                      label="Type 1"
                      checked={selectedTaskType === 1}  /* Check for 1 instead of "Type 1" */
                      onChange={handleTaskTypeChange}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "left" }}>
                    <FormControlLabel
                      value={2}  /* Set the value to 2 for "Type 2" */
                      control={<Radio />}
                      label="Type 2"
                      checked={selectedTaskType === 2}  /* Check for 2 instead of "Type 2" */
                      onChange={handleTaskTypeChange}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container mt={1}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs={4}
                    style={{ textAlign: "left", marginLeft: "15px" }}
                  >
                    <b>Personal details</b>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "left" }}>
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Yes"
                      checked={includePersonalDetails === 1}
                      onChange={handleRadioChange}
                      disabled={selectedTaskType === 2}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "left" }}>
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="No"
                      checked={includePersonalDetails === 0}
                      onChange={handleRadioChange}
                      // defaultChecked={selectedTaskType === 2}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            {includePersonalDetails === 1 && selectedTaskType === 1 && (
              <Grid
                container
                spacing={2}
                style={{ textAlign: "left", marginLeft: "15px" }}
              >
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={nameChecked}
                        onChange={handleCheckboxChange("Name")}
                      />
                    }
                    label="Name"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={emailChecked}
                        onChange={handleCheckboxChange("Email")}
                      />
                    }
                    label="Email"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={phoneNumberChecked}
                        onChange={handleCheckboxChange("PhoneNumber")}
                      />
                    }
                    label="Phone number"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid container mt={1}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs={4}
                    style={{ textAlign: "left", marginLeft: "15px" }}
                  >
                    <b>Task level</b>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "left" }}>
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Easy"
                      checked={selectedTaskLevel === 1}
                      onChange={handleTaskLevelChange}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Medium"
                      checked={selectedTaskLevel === 2}
                      onChange={handleTaskLevelChange}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "right" }}>
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="Hard"
                      checked={selectedTaskLevel === 3}
                      onChange={handleTaskLevelChange}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>

        </Grid>

        {subtaskList.length > 0 && (
          <div>
            <h2>Subtasks:</h2>
            <Grid container spacing={2}>
              {subtaskList.map((subtask, index) => (
                <Grid item xs={12} key={index}>
                  <Paper style={{ padding: 10, textAlign: 'left' }}>
                    <strong>{`Subtask ${index + 1}:`}</strong>
                    <div>{`Title: ${subtask.subtaskTitle}`}</div>
                    {/* Add other subtask properties as needed */}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
        )}


        <SubtaskForm onSubmitSubtask={handleSubtaskSubmit} />


        <Grid item xs={12}>
          {/* Discard and Submit Buttons */}
          <Button
            variant="outlined"
            onClick={() => navigate("/task")}
            color="error"
            endIcon={<DeleteIcon />}
            style={{ margin: "15px" }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            endIcon={<SendIcon />}
            style={{ margin: "15px" }}
          >
            Submit Form
          </Button>
        </Grid>
      </form>
    </Container>
  );
};

export default AddTask;
