import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Boomcoin from '../assets/boomcoin.png';
import trophy from '../assets/earnertrophy.svg';

function TopEarners() {
  const topEarnersData = [
    { name: 'User 1', boomcoins: 500 },
    { name: 'User 2', boomcoins: 450 },
    { name: 'User 3', boomcoins: 400 },
  ];

  return (
    <div className="topEarners" style={{ height: '100%' }}>
      <Card style={{ height: '100%', margin: '8px', padding: '8px', boxShadow: '4px 4px 8px #ccc', textAlign: 'center' }}>
        <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2>
            Top Earners{' '}
            <img
              style={{
                width: '40px',
                height: '40px',
                objectFit: 'contain',
                borderRadius: '30px',
                verticalAlign: 'middle',
                marginRight: '5px',
              }}
              src={trophy}
              alt="trophy"
            />
          </h2>

          <ul>
            {topEarnersData.map((earner, index) => (
              <li key={index} className="top-earner-item">
                <span className="top-earner-name">{earner.name}</span>
                <span className="top-earner-boomcoins">
                  <img
                    style={{
                      width: '28px',
                      height: '28px',
                      objectFit: 'contain',
                      borderRadius: '30px',
                      verticalAlign: 'middle',
                      marginRight: '5px',
                    }}
                    src={Boomcoin}
                    alt="boomcoin"
                  />
                  {earner.boomcoins}{' '}
                </span>
              </li>
            ))}
          </ul>
          <button>View All</button>
        </CardContent>
      </Card>
    </div>
  );
}

export default TopEarners;
