import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './AppRoutes.css'
import SideNavbar from './SideNavbar';
import Dashboard from './Dashboard/Dashboard';

import User from './User/User';
import UserTransactions from './User/transactions/UserTransactions.js';
import WithdrawalRequest from './User/WithdrawalRequest.js';
import SendNotification from './User/SendNotification.js';
import Banner from './Banner/Banner.js';

import Task from './Task/Task';
import AddTask from './Task/AddTask.js';
import TaskDetail from './Task/TaskDetail.js';
import TaskAnalysis from './Task/TaskAnalysis.js';
import BoomcoinDisburse from './Task/BoomcoinDisburse.js';
import TaskDetailedInfo from './Task/TaskDetailedInfo.js';
import SubmissionDetail from './Task/SubmissionDetail.js';
import SubmissionDetail2 from './Task/SubmissionDetail2.js';
import SubtaskForm from './Task/SubtaskForm.js';
import EditTask from './Task/EditTask.js';
import Admin from './Admin/Admin.js';
import KycInfo from './User/KycInfo.js';
import LoginForm from './Admin/LoginForm.js';
import CompleteUserKyc from './User-Kyc/CompleteUserKyc.js';
import ManualKyc from './User-Kyc/ManualKyc.js';
import Transactions from './Transactions/Transactions.js';


const AppRoutes = () => {
  const [adminUsername, setAdminUsername] = useState('');

  const handleAdminData = (username) => {
    setAdminUsername(username);
  };

  return (
    <Routes>
      <Route path="/login" element={<LoginForm onAdminData={handleAdminData} />} />
      <Route path="/*" element={
        <div className="app-container">
          <SideNavbar className="side-navbar" adminUsername={adminUsername}/>
          <div className="content-container">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user" element={<User />} />
              <Route path="/user/transactions" element={<UserTransactions />} />
              <Route path="/user/withdrawal-request" element={<WithdrawalRequest />} />
              <Route path="/user/send-notification" element={<SendNotification />} />
              <Route path="/banner" element={<Banner />} />

              <Route path="/task" element={<Task />} />
              <Route path="/AddTask" element={<AddTask />} />
              <Route path="/task/TaskDetail" element={<TaskDetail />} />
              <Route path="/task/taskanalysis" element={<TaskAnalysis />} />
              <Route path="/task/boomcoin-disburse" element={<BoomcoinDisburse />} />
              <Route path="/task/task-detailed-info" element={<TaskDetailedInfo />} />
              <Route path="/submission-detail" element={<SubmissionDetail />} />
              <Route path="/submission-detail2" element={<SubmissionDetail2 />} />
              <Route path="/SubtaskForm/" element={<SubtaskForm />} />
              <Route path="/edit-task" element={<EditTask />} />

              <Route path="/user" element={<User />} />
              <Route path="/user/transactions" element={<UserTransactions />} />
              <Route path="/user/withdrawal-request" element={<WithdrawalRequest />} />
              <Route path="/user/send-notification" element={<SendNotification />} />
              <Route path="/user/kycUserInfo" element={<KycInfo />} />
              <Route path="/banner" element={<Banner />} />

              <Route path="/admin" element={<Admin />} />

              <Route path="/user-kyc" element={<CompleteUserKyc />} />
              <Route path="/user-kyc/manual-kyc/:id" element={<ManualKyc />} />

              <Route path="/transactions" element={<Transactions />} />
            </Routes>
          </div>
        </div>
      } />

    </Routes>
  );
};

export default AppRoutes;

