import React, { useState, useRef, } from "react";
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    Input,
    Checkbox,
    Container,
    IconButton,
    styled,
} from "@mui/material";
import { Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import { Editor } from "@tinymce/tinymce-react";
// import $ from "jquery/dist/jquery";
// import "trumbowyg/dist/ui/trumbowyg.min.css";
// import "trumbowyg/dist/trumbowyg.min.js";
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { EditorState, convertToRaw, ContentState } from 'draft-js';
import JoditEditor from 'jodit-react';
import { DndContext, closestCorners, KeyboardSensor, PointerSensor, useSensor, useSensors, MouseSensor, } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import SortableQuestion from "./SortableQuestion";
import Modal from "@mui/material/Modal";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const SubtaskForm = ({ onSubmitSubtask }) => {
    const [subtasks, setSubtasks] = useState([]);
    const [formData, setFormData] = useState({
        subtaskTitle: "",
        boomcoins: "",
        requirements: "",
        howToComplete: "",
        startDate: "",
        endDate: "",
        sampleProof: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [isShowForm, setIsShowForm] = useState(false);
    const [questionType, setQuestionType] = useState("short_answer");
    const [isMandatory, setIsMandatory] = useState(false);
    const [newQuestion, setNewQuestion] = useState("");
    const [newOption, setNewOption] = useState("");
    const [options, setOptions] = useState([]);
    const [isSubmissionFormOpen, setIsSubmissionFormOpen] = useState(false);
    const [submissionForm, setSubmissionForm] = useState([]);
    // console.log('Submission form  = ', submissionForm);

    const editor = useRef(null);

    const handleAddOption = () => {
        setOptions((prevOptions) => [...prevOptions, newOption]);
        setNewOption("");
    };

    const [selectedImageFile, setSelectedImageFile] = useState(null);

    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        setSelectedImageFile(event.target.files[0]);
        setSelectedImage(URL.createObjectURL(event.target.files[0]));
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            console.log('RESULT', reader.result);
            const base64String = reader.result;

            setFormData(prevFormData => ({
                ...prevFormData,
                sampleProof: base64String,
            }));
        };

        reader.readAsDataURL(file);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreateSubmissionForm = () => {
        const newQuestionObj = {
            type: questionType,
            mandatory: isMandatory,
            question: newQuestion,
            options: [...options],
        };

        setSubmissionForm((prevForm) => [...prevForm, newQuestionObj]);
        setQuestionType("short_answer");
        setIsMandatory(false);
        setNewQuestion("");
        setNewOption("");
        setOptions([]);
    };

    const ShortAnswerQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <TextField
                label={question.question}
                variant="standard"
                fullWidth
                margin="normal"
            />
        </div>
    );

    const ParagraphQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <TextField
                label={question.question}
                minRows={3}
                placeholder={question.question}
                style={{ width: "100%", marginTop: "10px" }}
            />
        </div>
    );

    const DateQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <TextField
                label={question.question}
                type="date"
                variant="standard"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    );

    const TimeQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <TextField
                label={question.question}
                type="time"
                variant="standard"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder={question.question}
            />
        </div>
    );

    const FileUploadQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <Input type="file" />
            <p>
                <b>Options:</b> N/A
            </p>
        </div>
    );

    const CheckboxQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            {question.options.map((option, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                    }}
                >
                    <Checkbox />
                    <span>{option}</span>
                </div>
            ))}
        </div>
    );

    const DropdownQuestion = ({ question }) => (
        <div>
            <p>
                <b>Question:</b> {question.question}
            </p>
            <p>
                <b>Mandatory:</b> {question.mandatory ? "Yes" : "No"}
            </p>
            <FormControl fullWidth variant="standard" margin="normal">
                <InputLabel>{question.question}</InputLabel>
                <Select label="Select" placeholder={question.question}>
                    {question.options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );

    const QuestionRenderer = ({ question }) => {
        switch (question.type) {
            case "short_answer":
                return <ShortAnswerQuestion question={question} />;
            case "paragraph":
                return <ParagraphQuestion question={question} />;
            case "date":
                return <DateQuestion question={question} />;
            case "time":
                return <TimeQuestion question={question} />;
            case "file_upload":
                return <FileUploadQuestion question={question} />;
            case "checkbox":
                return <CheckboxQuestion question={question} />;
            case "dropdown":
                return <DropdownQuestion question={question} />;
            default:
                return null;
        }
    };

    const handleSubtaskChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubtaskSubmit = (e) => {
        e.preventDefault();

        // Extract subtask details directly from formData state
        const subtaskDetails = { ...formData };

        // Log submission form details as an array of objects
        const submissionFormDetails = submissionForm.map((question, index) => ({
            questionIndex: index + 1,
            type: question.type,
            mandatory: question.mandatory,
            questionText: question.question,
            options: [...question.options],
        }));

        // Combine subtaskDetails and submissionFormDetails into the final object
        const SubtaskData = {
            ...subtaskDetails,
            subtask_submission_form: { submissionForm: submissionFormDetails },
        };
        console.log("Subtask Data submitted:", SubtaskData);

        // Add the new subtask to the array
        setSubtasks((prevSubtasks) => [...prevSubtasks, SubtaskData]);
        // Clear the form data for the next subtask
        setFormData({
            subtaskTitle: "",
            boomcoins: "",
            requirements: "",
            howToComplete: "",
            startDate: "",
            endDate: "",
        });

        setSubmissionForm([]);
        setIsShowForm(false);
        setIsSubmissionFormOpen(false);
    };

    const handleDeleteSubtask = (index) => {
        // Delete the subtask at the specified index
        setSubtasks((prevSubtasks) => [
            ...prevSubtasks.slice(0, index),
            ...prevSubtasks.slice(index + 1),
        ]);
    };

    const resetForm = () => {
        setFormData({
            subtaskTitle: "",
            boomcoins: "",
            requirements: "",
            howToComplete: "",
            startDate: "",
            endDate: "",
        });

        setSubmissionForm([]);
        setIsSubmissionFormOpen(false);
        setIsShowForm(false);
    };

    const handleSubmitAllSubtasks = () => {
        // Handle the submission of all subtasks
        console.log("All Subtasks submitted:", subtasks);
        // You can perform additional logic or API calls here

        onSubmitSubtask(subtasks);
        resetForm();
        setSubtasks([]); // Reset the subtasks to an empty array
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${year}-${month}-${day}`;
    };

    const handleCloseForm = () => {
        // Add logic to reset or close the forms
        setIsShowForm(false);
        setIsSubmissionFormOpen(false);
        // Additional logic if needed
    };

    function handleQuestionDelete(questionIndex) {
        setSubmissionForm(prevSubmissionForm => {
            return prevSubmissionForm.filter((question, index) => index !== questionIndex);
        });
    }

    function handleDragEnd(event) {
        const { active, over } = event;

        // Convert active and over to indices
        const activeIndex = Number(active.id);
        const overIndex = Number(over.id);

        if (activeIndex !== overIndex) {
            setSubmissionForm((prevSubmissionForm) => {
                // Move the question from the active index to the over index
                const updatedForm = [...prevSubmissionForm];
                const [movedQuestion] = updatedForm.splice(activeIndex, 1);
                updatedForm.splice(overIndex, 0, movedQuestion);
                return updatedForm;
            });
        }
    }

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10, // Enable sort function when dragging 10px 
            },
        })
    );


    return (
        <Container maxWidth="md" style={{ marginBottom: "50px" }}>
            {/* <form onSubmit={handleSubtaskSubmit} style={{ marginLeft: "20px" }}> */}
            <div>
                {/* Display the list of subtasks */}
                {subtasks.length > 0 && (
                    <>
                        <h2>Subtasks:</h2>
                        <ul>
                            {subtasks.map((subtask, index) => (
                                <li key={index}>
                                    <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                        <Grid item>
                                            <b>{`Subtask ${index + 1}:`}</b>{` ${subtask.subtaskTitle}`}
                                        </Grid>
                                        <Grid item>
                                            <IconButton color="error" onClick={() => handleDeleteSubtask(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </li>
                            ))}
                        </ul>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "10px" }}
                            onClick={handleSubmitAllSubtasks}
                        >
                            Submit All Subtasks
                        </Button>
                    </>
                )}
            </div>

            {isShowForm ? (
                <div >
                    <h1>Create Subtask</h1>
                    <TextField
                        label="Subtask Title"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        name="subtaskTitle"
                        value={formData.subtaskTitle}
                        onChange={handleInputChange}
                        required
                    />

                    <TextField
                        name="boomcoins"
                        label="Boom Coins"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        value={formData.boomcoins}
                        onChange={handleInputChange}
                        required
                    />

                    <TextField
                        name="requirements"
                        label="Mention Requirements to Complete This Subtask"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        value={formData.requirements}
                        onChange={handleInputChange}
                        required
                    />
                    <div style={{ textAlign: 'left' }}>
                        <p>How to Complete This Gig - </p>
                    </div>
                    <JoditEditor
                        ref={editor}
                        value={formData.howToComplete}
                        // config={config}
                        // tabIndex={1}
                        onChange={newContent => { setFormData(prevData => ({ ...prevData, howToComplete: newContent })) }}
                    />
                    <TextField
                        name="startDate"
                        label="Start Date"
                        type="date"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={formatDate(formData.startDate)}
                        onChange={handleInputChange}
                        required
                    />

                    <TextField
                        name="endDate"
                        label="End Date"
                        type="date"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={formatDate(formData.endDate)}
                        onChange={handleInputChange}
                        required
                    />
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                        <TextField
                            variant="standard"
                            id="outlined-read-only-input"
                            defaultValue="Sample"
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{ width: '8%', display: 'flex', justifyContent: 'left', marginRight: '20px' }}
                        />
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload file
                            <VisuallyHiddenInput type="file" onChange={handleImageChange} />
                        </Button>

                        {selectedImage && (
                            <>
                                <Button onClick={handleOpen}>
                                    {selectedImageFile.name}
                                </Button>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <div style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '40%',
                                        height: '70%',
                                        backgroundColor: 'white',
                                        padding: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <img src={selectedImage} alt="Selected" style={{ width: '100%', height: '100%', objectFit: 'cover', }} />
                                    </div>
                                </Modal>
                            </>
                        )}
                    </div>



                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "30px" }}
                            onClick={() => setIsSubmissionFormOpen(true)}
                        >
                            Create Submission Form
                        </Button>
                    </div>
                </div>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "10px" }}
                    onClick={() => setIsShowForm(true)}
                >
                    Add Subtask
                </Button>
            )}

            {isSubmissionFormOpen && (
                <div
                    style={{
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                        margin: "10px",
                        padding: "10px",
                    }}
                >
                    <h2>Create Submission Form</h2>
                    <FormControl fullWidth variant="standard" margin="normal" on>
                        <InputLabel>Question Type</InputLabel>
                        <Select
                            value={questionType}
                            onChange={(e) => setQuestionType(e.target.value)}
                            label="Question Type"
                        >
                            <MenuItem value="short_answer">Short Answer</MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="checkbox">Checkbox</MenuItem>
                            <MenuItem value="dropdown">Dropdown</MenuItem>
                            <MenuItem value="date">Date</MenuItem>
                            <MenuItem value="time">Time</MenuItem>
                            <MenuItem value="file_upload">File Upload</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isMandatory}
                                onChange={() => setIsMandatory(!isMandatory)}
                            />
                        }
                        label="Mandatory"
                    />
                    <TextField
                        // required
                        label="Enter Question"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                    />

                    {questionType === "checkbox" || questionType === "dropdown" ? (
                        <div>
                            <TextField
                                label="Enter Option"
                                variant="standard"
                                fullWidth
                                margin="normal"
                                value={newOption}
                                onChange={(e) => setNewOption(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: "10px" }}
                                onClick={handleAddOption}
                            >
                                Add Option
                            </Button>
                            {options.length > 0 && (
                                <div>
                                    <b>Options:</b>
                                    {options.map((option, index) => (
                                        <div key={index}>{`${index + 1}. ${option}`}</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ) : null}
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "10px" }}
                        onClick={handleCreateSubmissionForm}
                    >
                        Add Question
                    </Button>
                    {/* Display Questions */}
                    <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                        <SortableContext items={submissionForm.map((question, index) => index.toString())} strategy={verticalListSortingStrategy}>
                            {submissionForm.map((question, index) => (

                                <SortableQuestion key={index} id={index.toString()} question={question} handleDeleteQuestion={handleQuestionDelete} index={index} QuestionRenderer={QuestionRenderer} />

                            ))}
                        </SortableContext>
                    </DndContext>

                    <Grid item xs={12}>
                        {/* <Button
                                variant="outlined"
                                color="error"
                                style={{ margin: "15px" }}
                            >
                                Discard
                            </Button> */}
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            style={{ margin: "15px" }}
                            onClick={handleSubtaskSubmit}
                        >
                            Submit Subtask
                        </Button>
                    </Grid>
                </div>
            )}
            {isShowForm && (
                <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: "10px" }}
                    onClick={handleCloseForm}
                >
                    Close Form
                </Button>
            )}
            {/* </form> */}
        </Container>
    );
};

export default SubtaskForm;
