import React, { useEffect, useState } from 'react';
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import axios from '../../axiosConfig';
import { useLocation } from 'react-router-dom';

const BoomcoinDisburse = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalBoomCoins, setTotalBoomCoins] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState('');
  const [taskId, setTaskId] = useState('');
  const [reloadData, setReloadData] = useState(false);
  const state = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const changeRowsPerPage = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (
      state &&
      state.state.taskInfoId
    ) {
      setTaskId(state.state.taskInfoId);
      console.log(state.state.taskInfoId);
    }
  }, [state]);

  const options = {
    filterType: 'checkbox',
    responsive: 'standard',
    selectableRows: 'multiple',
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {

      const selectedUserIdsAll = allRowsSelected.map(({ dataIndex }) => userData[dataIndex][0]);
      console.log('Selected User IDs (all):', selectedUserIdsAll);

      setSelectedRows(selectedUserIdsAll);
    },
    filter: true,
    serverSide: true,
    count: count,
    page: currentPage,
    rowsPerPage: rowsPerPage,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          console.log('tableState page- ', tableState.page);
          break;
        case 'changeRowsPerPage':
          changeRowsPerPage(tableState.rowsPerPage);
          console.log('tableState rowperpage- ', tableState.rowsPerPage);
          break;
        default:
          break;
      }
    }
  };

  const fetchDisburseData = async (page, rowsPerPage) => {
    try {
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;

      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-task-disbursement-details`, {
        taskInfoId: taskId,
        size: limit,
        page: offset,
      });
      const userDataValues = response.data.data.map(obj => [
        obj.userId,
        obj.fullName,
        obj.approvedSubmission,
        obj.boomcoins,
      ]);

      // Setting the new array of values
      setUserData(userDataValues);

      console.log("Data is ", userDataValues);

    } catch (error) {
      console.error('Error fetching student data:', error);
    }
  }

  useEffect(() => {
    if (taskId) {
      fetchDisburseData(currentPage, options.rowsPerPage);
    } else {
      console.error('Task ID is invalid:', taskId);
    }
  }, [taskId, reloadData, currentPage, options.rowsPerPage]);

  
  // const calculateTotalBoomCoins = (selectedRowsData) => {
  //   const totalCoins = selectedRowsData.reduce((total, user) => {
  //     return total + user['Boom Coins'];
  //   }, 0);
  //   setTotalBoomCoins(totalCoins);
  // };

  const handleDisburse = () => {
    setConfirmDialogOpen(true);
  };

  const triggerReload = () => {
    setReloadData(true);
  }

  const handleConfirmDisburse = () => {
    // Implement your disbursement logic here using the selected users
    console.log('Disbursing Boomcoins to:', selectedRows, taskId);

    axios.post(`${process.env.REACT_APP_BASE_URL}/disburse-task-coins`, {
      taskInfoId: taskId,
      userId: selectedRows
    })
      .then(response => {
        console.log('Disbursed coins successfully', response);
        triggerReload();
        setSelectedRows([]);
      })
      .catch(error => {
        console.error('Error disbursing coins:', error);
        return null;
      });


    // Reset selection and total boom coins after disbursement

    setTotalBoomCoins(0);
    setConfirmDialogOpen(false);
  };

  const handleCancelDisburse = () => {
    setConfirmDialogOpen(false);
  };

  const columns = [
    { name: 'UID', label: 'UID' },
    { name: 'Name', label: 'Name' },
    { name: 'Approved Submissions', label: 'Approved Submissions', options: { filter: false } },
    { name: 'Boom Coins', label: 'Boom Coins', options: { filter: false } },
  ];

  return (
    <div style={{ marginTop: '64px' }}>
      <MUIDataTable
        title="User Boomcoin Details"
        data={userData}
        columns={columns}
        options={options}
      />

      <div style={{ marginTop: '16px' }}>
        {/* <Typography variant="h6">Total Boom Coins: {totalBoomCoins}</Typography> */}
        <Button variant="contained" color="primary" onClick={handleDisburse} style={{ marginTop: '8px' }}>
          Disburse Boomcoin
        </Button>
      </div>

      <Dialog open={confirmDialogOpen} onClose={handleCancelDisburse}>
        <DialogTitle>Confirm Disbursement</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to disburse Boomcoins?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDisburse} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDisburse} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BoomcoinDisburse;
