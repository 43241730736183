import { Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from '../../axiosConfig';

function UserStatistics() {
  const [usersCount, setUsersCount] = useState(0);

  const fetchUsersCount = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-all-usersCount`);
      setUsersCount(response.data.totalCount);
    } catch (error) {
      console.error('Error fetching user count:', error);
    }
  }

  useEffect(() => { 
    fetchUsersCount();
  },[]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} mt={5} display={'flex'} justifyContent={'space-around'}>
        <Grid item xs={12} sm={6} md={4} lg={2} margin={2} padding={2} boxShadow={4} bgcolor="white"
          style={{ textAlign: 'center' }}>
          <Typography variant="h5">{usersCount}</Typography>
          <p><b>Total Users</b></p>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} margin={2} padding={2} boxShadow={4} bgcolor="white"
          style={{ textAlign: 'center' }}>
          <Typography variant="h5">75</Typography>
          <p> <b>Verified Users</b></p>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} margin={2} padding={2} boxShadow={4} bgcolor="white"
          style={{ textAlign: 'center' }}>
          <Typography variant="h5">50</Typography>
          <p> <b>Users Join today</b></p>
        </Grid>
      </Grid>
    </Container>
  );
}

export default UserStatistics;
