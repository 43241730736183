// SubmissionDetail.js
import React, { useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Box, List, TextField, ListItem, Typography, Dialog, DialogContent, Paper, Button, Checkbox, Modal } from '@mui/material';
import axios from '../../axiosConfig';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function StudentList({ selectedStudent, studentsData, onSelectStudent, triggerReload }) {
  // const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const handleCheckboxChange = (student) => {
    const taskSubmissionIds = student.userSubmission.map((subtask) => subtask.taskSubmissionId);

    setSelectedStudents((prevSelected) => {
      const isAlreadySelected = taskSubmissionIds.every((id) => prevSelected.includes(id));

      if (isAlreadySelected) {
        // If all taskSubmissionIds are already selected, remove them all
        return prevSelected.filter((id) => !taskSubmissionIds.includes(id));
      } else {
        // If any taskSubmissionId is not selected, add them all
        return [...prevSelected, ...taskSubmissionIds];
      }
    });
    console.log(selectedStudents);
  };

  const handleAccept = async () => {
    // Implement logic for accepting multiple students
    if (selectedStudents.length > 0) {
      const status = 1; // Status for "Accepted"
      console.log(selectedStudents);
      const taskSubmissionIds = selectedStudents;

      await axios.put(`${process.env.REACT_APP_BASE_URL}/update-submission-status`, {
        taskSubmissionId: taskSubmissionIds,
        approvalStatus: status,
      })
        .then(response => {
          console.log('Multiple students accepted successfully', response);
          toast.success("Success!");
          triggerReload();
          setSelectedStudents('');
        })
        .catch(error => {
          console.error('Error accepting multiple students:', error);
        });
    }
  };

  const handleReject = () => {
    console.log('Rejecting students:', selectedStudents, 'Reason:', rejectionReason);
    if (selectedStudents.length > 0) {
      // Open the rejection modal
      setModalOpen(true);
    }
  };

  // approvalStatus { 1 = approved | 2 = Rejected | 0 = pending }
  const handleModalReject = async () => {
    if (selectedStudents.length > 0) {
      const status = 2; // Status for "Rejected"
      const taskSubmissionIds = selectedStudents;

      // Example using axios:
      await axios.put(`${process.env.REACT_APP_BASE_URL}/update-submission-status`, {
        taskSubmissionId: taskSubmissionIds,
        approvalStatus: status,
        rejectReason: rejectionReason,
      })
        .then(response => {
          console.log('Multiple students rejected successfully', response);
          toast.success("Success!");
          triggerReload();
          setSelectedStudents('');
        })
        .catch(error => {
          console.error('Error rejecting multiple students:', error);
          toast.error(error);
        });

      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setRejectionReason('');
  };

  function getBackgroundColor(status) {
    switch (status) {
      case 'Accepted':
        return '#c8e6c9';
      case 'Rejected':
        return '#ffcdd2';
      default:
        return 'transparent';
    }
  }

  return (
    <Box width={300} height={'100wh'} boxShadow={3} overflow={'auto'} >
      <Typography variant="h5">Student List</Typography>
      <List>
        {studentsData.map((student) => (
          <ListItem
            key={student.personalDetailsSubId}
            button
            onClick={() => onSelectStudent(student)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // To push the status indicators to the end
              backgroundColor:
                getBackgroundColor(student.status),
              boxShadow:
                selectedStudent && student.personalDetailsSubId == selectedStudent.personalDetailsSubId
                  ? '0px 0px 10px 0px rgba(0,0,0,0.75)' // Add a box shadow for the selected student
                  : 'none',
              marginBottom: '1px',
            }}
          >
            <Box>
              <Checkbox
                checked={student.userSubmission.some(submission => selectedStudents.includes(submission.taskSubmissionId))}
                onChange={() => handleCheckboxChange(student)}
                sx={{ marginRight: '10px', padding: '2px' }}
              />
              {student.name}
            </Box>
            {/* <Box>
              {student.status === 'Accepted' && <DoneIcon style={{ color: 'green', fontSize: '1.5em', marginLeft: '5px', fontWeight: 'bold' }} />}
              {student.status === 'Rejected' && <CloseIcon style={{ color: 'red', fontSize: '1.5em', marginLeft: '5px', fontWeight: 'bold' }} />}
              {student.status === 'Pending' && <span style={{ color: 'gray', fontSize: '1.5em', marginLeft: '5px' }}></span>}
            </Box> */}
          </ListItem>
        ))}
      </List>
      {selectedStudents.length > 0 && (
        <Box mt="auto" display="flex" justifyContent="center">
          <Button variant="outlined" color="success" onClick={handleAccept} sx={{ marginRight: '10px' }}>
            Accept
          </Button>
          <Button variant="outlined" color="error" onClick={handleReject} sx={{ marginLeft: '10px' }}>
            Reject
          </Button>
        </Box>
      )}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="rejection-modal"
        aria-describedby="rejection-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '80%', md: 400 },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <TextField
            id="rejection-reason"
            label="Reason for Rejection"
            multiline
            rows={4}
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            sx={{ marginBottom: 2, width: '100%' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button variant="outlined" color="error" onClick={handleModalReject}>
              Reject
            </Button>
            <Box sx={{ width: 2 }} />
            <Button variant="outlined" onClick={handleModalClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

function SubmissionDetail() {
  const { state } = useLocation();
  const [tumId, setTumId] = useState(null);
  const [studentData, setStudentData] = useState();
  const [rejectionReason, setRejectionReason] = useState('');
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    if (
      state &&
      state.tumId
    ) {
      setTumId(state.tumId);
    }
  }, [state]);

  useEffect(() => {
    if (tumId) {
      axios.post(`${process.env.REACT_APP_BASE_URL}/get-user-submissions`, { tumId: tumId })
        .then(response => {
          setStudentData(response.data.data);
          console.log(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching student data:', error);
        });
    }
  }, [tumId, reloadData]);

  const [selectedStudent, setSelectedStudent] = useState(studentData ? studentData[0] : null);
  const [selectedSubtaskIndex, setSelectedSubtaskIndex] = useState(0);
  const [showButtons, setShowButtons] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);



  // approvalStatus { 1 = approved | 2 = Rejected | 0 = pending }
  const handleAccept = async () => {
    // Implement logic for accepting subtasks 
    if (selectedStudent && selectedSubtaskIndex !== null) {
      const selectedSubtask = selectedStudent.userSubmission[selectedSubtaskIndex];

      const status = 1;
      const taskSubmissionId = selectedSubtask.taskSubmissionId;
      console.log(`status= ${status}, taskSubmissionId = ${taskSubmissionId}`);

      try {
        await axios.put(`${process.env.REACT_APP_BASE_URL}/update-submission-status`, {
          taskSubmissionId: taskSubmissionId,
          approvalStatus: status
        })
          .then((data) => {
            console.log('accept api successful', data);
            toast.success("Success!");
            triggerReload();
          })
          .catch((err) => {
            console.log('Accept api error: ', err);
          });
        // window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const triggerReload = () => {
    setReloadData(prev => !prev);
  };

  const handleReject = () => {
    // Implement logic for rejecting subtasks
    if (selectedStudent && selectedSubtaskIndex !== null) {
      // Open the rejection modal
      setOpenModal(true);
    }
  };

  const handleModalReject = async () => {
    // Implement logic for handling rejection from the modal
    if (selectedStudent && selectedSubtaskIndex !== null) {
      const selectedSubtask = selectedStudent.userSubmission[selectedSubtaskIndex];

      const status = 2;
      const taskSubmissionId = selectedSubtask.taskSubmissionId;
      console.log(`status= ${status}, rejectionreason = ${rejectionReason}, taskSubmissionId = ${taskSubmissionId}`);
      // Example using axios:
      await axios.put(`${process.env.REACT_APP_BASE_URL}/update-submission-status`, {
        taskSubmissionId: taskSubmissionId,
        approvalStatus: status,
        rejectReason: rejectionReason,
      })
        .then(response => {
          console.log('Submission rejected successfully', response);
          toast.success("Success!");
          triggerReload();
        })
        .catch(error => {
          console.error('Error rejecting subtask:', error);
          toast.error(error.message);
          return null;
        });

      handleModalClose();
    }
  };

  useEffect(() => {
    if (studentData && studentData.length > 0) {
      setSelectedStudent(studentData[0]);
      setShowButtons(true);
    }
  }, [studentData]);

  const handleSelectStudent = (student) => {
    setSelectedStudent(student);
    setSelectedSubtaskIndex(0);

    // Check if there are subtasks for the selected student
    if (student.userSubmission && student.userSubmission.length > 0) {
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }

    setSelectedFile(null);
  };


  const handleSelectSubtask = (index) => {
    setSelectedSubtaskIndex(index);
    setShowButtons(true);
    setSelectedFile(null);
    // setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setRejectionReason('');
  };

  return (
    <Box display="flex" marginTop={4} >
      <StudentList
        selectedStudent={selectedStudent}
        studentsData={studentData ? studentData : []}
        onSelectStudent={handleSelectStudent}
        onAccept={handleAccept}
        onReject={handleReject}
        triggerReload={triggerReload}
      />

      {/* Right side with details of the selected student */}

      <Box flexGrow={1} p={0}>
        {selectedStudent ? (
          <div>

            {/* Display subtask titles as buttons */}
            <Box display="flex" flexDirection="row" gap={5} justifyContent={'center'} pb={3}>
              {selectedStudent.userSubmission.map((submission, index) => (
                <Button
                  key={index}
                  variant={index === selectedSubtaskIndex ? 'contained' : 'outlined'}
                  color={index === selectedSubtaskIndex ? 'success' : 'secondary'}
                  onClick={() => handleSelectSubtask(index)}
                  sx={{
                    marginBottom: 1,
                    borderRadius: 5, // Adjust the value as needed
                  }}
                >
                  {submission["subtask_info.subtaskTitle"]}
                </Button>
              ))}
            </Box>

            <Typography variant="h5" align="center" sx={{ marginBottom: 2 }}>
              {selectedStudent.name}
            </Typography>

            <div>
              {selectedStudent.userSubmission && selectedStudent.userSubmission[selectedSubtaskIndex] && selectedStudent.userSubmission[selectedSubtaskIndex].submissionAnswer && selectedStudent.userSubmission[selectedSubtaskIndex].submissionAnswer.length > 0 ? (
                selectedStudent.userSubmission[selectedSubtaskIndex].submissionAnswer.map((answer, answerIndex) => (
                  <div key={answerIndex} style={{
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                    margin: "10px",
                    padding: "10px",
                    textAlign: "left",
                  }}>
                    <Typography variant="subtitle1">
                      <b>Question</b>: {answer.questionText} (<b>{answer.type}</b>)
                    </Typography>
                    {answer.type !== 'file_upload' && (
                      <Typography variant="subtitle1">
                        <b>Answer {answerIndex + 1}</b>: {answer.answer}
                      </Typography>
                    )}
                  </div>
                ))
              ) : (
                <Typography variant="subtitle1">There is no submission answer.</Typography>
              )}
              {/* Display image files */}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
                {selectedStudent.userSubmission && selectedStudent.userSubmission[selectedSubtaskIndex] && selectedStudent.userSubmission[selectedSubtaskIndex].submissionAnswer && selectedStudent.userSubmission[selectedSubtaskIndex].submissionAnswer.length > 0 ? (
                  selectedStudent.userSubmission[selectedSubtaskIndex].submissionAnswer.map((answer, answerIndex) => {
                    if (answer.type === 'file_upload') {
                      return  (
                        <ListItem
                          key={answerIndex}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: 1,
                            flex: '0 0 calc(33.333% - 10px)',
                          }}
                        >
                          {answer.answer && answer.answer.endsWith('.pdf') ? (
                            <div>
                              <Typography variant="subtitle1">PDF File: {answer.name || answer}</Typography>
                              <Button variant="contained" color="primary" href={answer.answer} download>
                                Download PDF
                              </Button>
                            </div>
                          ) : answer.answer && (answer.answer.endsWith('.mp4') || answer.answer.endsWith('.mov')) ? (
                            <video width="100%" height="100%" controls>
                              <source src={answer.answer} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={answer.answer || answer}
                              alt={answer.name || answer}
                              style={{ width: '100%', height: '100%', maxWidth: '300px', borderRadius: '10px' }}
                            />
                          )}
                          {/* <Typography variant="subtitle1" sx={{ marginTop: '5px' }}>{file.name || file}</Typography> */}
                        </ListItem>
                      )
                    }
                  })
                ) : (
                  <Typography variant="subtitle1">There are no file uploads.</Typography>
                )}
              </div>
            </div>
            <div>
              {selectedStudent.status === "Rejected" && (
                <Typography variant="subtitle1">
                  <b>Rejection Reason:</b> {selectedStudent.userSubmission && selectedStudent.userSubmission[selectedSubtaskIndex].rejectReason}
                </Typography>
              )}
            </div>

            {/* Accept and Reject buttons */}
            {showButtons && (
              <Box mt={3} display="flex" justifyContent="center">
                <Button variant="outlined" color="success" onClick={handleAccept} sx={{ marginRight: '10px', marginBottom: ' 20px' }}>
                  Accept
                </Button>
                <Button variant="outlined" color="error" onClick={handleReject} sx={{ marginLeft: '10px', marginBottom: '20px' }}>
                  Reject
                </Button>
              </Box>
            )}

            {openModal && (
              <Modal
                open={openModal}
                onClose={handleModalClose}
                aria-labelledby="rejection-modal"
                aria-describedby="rejection-modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '80%', md: 400 },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <TextField
                    id="rejection-reason"
                    label="Reason for Rejection"
                    multiline
                    rows={4}
                    value={rejectionReason}
                    onChange={(e) => setRejectionReason(e.target.value)}
                    sx={{ marginBottom: 2, width: '100%' }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button variant="outlined" color="error" onClick={handleModalReject}>
                      Reject
                    </Button>
                    <Box sx={{ width: 2 }} />
                    <Button variant="outlined" onClick={handleModalClose}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Modal>
            )}


          </div>
        ) : (
          <Typography variant="body1">Please select a student from the list to view details.</Typography>
        )}
      </Box>
      <ToastContainer />
    </Box>
  );
}

export default SubmissionDetail;