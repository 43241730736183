import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import UserNavBar from './UserNavBar';
import UserModal from './UserModal';
import axios from '../../axiosConfig';
import { Button } from '@mui/material';



// const rows = [
//     { id: 1, name: 'Snow Jon', collegeName: 'Engineering', branch: 'CSE', year: 4, state: 'Haryana', location: 'Gurugram', status: 'Not submitted', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 2, name: 'Lannister Cersei', collegeName: 'Lannister University', branch: 'Political Science', year: 3, state: 'Maharashtra', location: 'Mumbai', status: 'Verified', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 3, name: 'Lannister Jaime', collegeName: 'Lannister University', branch: 'Swordsmanship', year: 4, state: 'Maharashtra', location: 'Pune', status: 'Requested', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 4, name: 'Stark Arya', collegeName: 'Winterfell College', branch: 'Assassination', year: 2, state: 'Haryana', location: 'Faridabad', status: 'Rejected', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 5, name: 'Targaryen Daenerys', collegeName: 'Dragon University', branch: 'Fire Studies', year: 1, state: 'West Bengal', location: 'Kolkata', status: 'Not submitted', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 6, name: 'Melisandre', collegeName: 'Red Priestess Academy', branch: 'Fire Magic', year: 5, state: 'Assam', location: 'Guwahati', status: 'Verified', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 7, name: 'Clifford Ferrara', collegeName: 'Science Institute', branch: 'Physics', year: 4, state: 'Uttar Pradesh', location: 'Lucknow', status: 'Requested', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 8, name: 'Frances Rossini', collegeName: 'Arts College', branch: 'Painting', year: 3, state: 'Rajasthan', location: 'Jaipur', status: 'Rejected', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 9, name: 'Roxie Harvey', collegeName: 'Retirement University', branch: 'Relaxation', year: 6, state: 'Retirement', location: 'Retirement', status: 'Not submitted', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 10, name: 'John Doe', collegeName: 'Mystery College', branch: 'Detective Studies', year: 3, state: 'New York', location: 'New York City', status: 'Not submitted', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 11, name: 'Jane Doe', collegeName: 'Art University', branch: 'Fine Arts', year: 2, state: 'California', location: 'Los Angeles', status: 'Verified', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 12, name: 'Alice Wonderland', collegeName: 'Wonderland Institute', branch: 'Fantasy Studies', year: 4, state: 'Wonderland', location: 'Wonderland City', status: 'Requested', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 13, name: 'Bob Builder', collegeName: 'Construction University', branch: 'Building Science', year: 3, state: 'Builderland', location: 'Builderland City', status: 'Verified', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 14, name: 'Charlie Chaplin', collegeName: 'Comedy School', branch: 'Laughter Studies', year: 5, state: 'Funnyland', location: 'Funnyland City', status: 'Not submitted', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 15, name: 'David Beckham', collegeName: 'Sports Academy', branch: 'Football', year: 4, state: 'England', location: 'London', status: 'Verified', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 16, name: 'Emma Watson', collegeName: 'Acting School', branch: 'Drama', year: 3, state: 'England', location: 'London', status: 'Requested', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 17, name: 'Frank Sinatra', collegeName: 'Music Institute', branch: 'Singing', year: 4, state: 'New Jersey', location: 'Hoboken', status: 'Not submitted', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 18, name: 'Grace Kelly', collegeName: 'Film Academy', branch: 'Acting', year: 3, state: 'Pennsylvania', location: 'Philadelphia', status: 'Verified', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 19, name: 'Harrison Ford', collegeName: 'Aviation School', branch: 'Piloting', year: 4, state: 'Illinois', location: 'Chicago', status: 'Requested', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 20, name: 'Ivanka Trump', collegeName: 'Business College', branch: 'Business Administration', year: 2, state: 'New York', location: 'New York City', status: 'Not submitted', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 21, name: 'Jackie Chan', collegeName: 'Martial Arts Academy', branch: 'Kung Fu', year: 5, state: 'Hong Kong', location: 'Hong Kong', status: 'Verified', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 22, name: 'Katy Perry', collegeName: 'Music School', branch: 'Pop Music', year: 3, state: 'California', location: 'Los Angeles', status: 'Requested', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 23, name: 'Leonardo da Vinci', collegeName: 'Artistic Genius School', branch: 'Art', year: 4, state: 'Italy', location: 'Florence', status: 'Verified', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 24, name: 'Marilyn Monroe', collegeName: 'Film Institute', branch: 'Acting', year: 3, state: 'California', location: 'Los Angeles', status: 'Not submitted', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 25, name: 'Nelson Mandela', collegeName: 'Leadership Academy', branch: 'Political Science', year: 4, state: 'South Africa', location: 'Johannesburg', status: 'Requested', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 26, name: 'Oprah Winfrey', collegeName: 'Media School', branch: 'Broadcasting', year: 2, state: 'Illinois', location: 'Chicago', status: 'Verified', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 27, name: 'Pablo Picasso', collegeName: 'Artistic Masterpiece Academy', branch: 'Art', year: 5, state: 'Spain', location: 'Barcelona', status: 'Not submitted', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 28, name: 'Queen Elizabeth II', collegeName: 'Royalty College', branch: 'Monarchy Studies', year: 4, state: 'United Kingdom', location: 'London', status: 'Verified', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 29, name: 'Rafael Nadal', collegeName: 'Tennis Academy', branch: 'Tennis', year: 3, state: 'Spain', location: 'Madrid', status: 'Requested', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
//     { id: 30, name: 'Serena Williams', collegeName: 'Tennis Institute', branch: 'Tennis', year: 2, state: 'United States', location: 'New York City', status: 'Not submitted', usertype: 'Boompanda', action: 'Block', delete: 'Delete' },
// ];

export default function UserTable() {
    const [allUsersFilter, setAllUsersFilter] = useState('');
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(500);
    const [selectedUser, setSelectedUser] = useState(null); // Track the selected user
    const [openModal, setOpenModal] = useState(false);
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const columns = [
        { field: 'id', headerName: 'UID', width: 50 },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: true,
        },
        {
            field: 'collegeName',
            headerName: 'College Name',
            width: 150,
            editable: true,
        },
        {
            field: 'branch',
            headerName: 'Branch',
            width: 150,
            editable: true,
        },
        {
            field: 'year',
            headerName: 'Year',
            type: 'number',
            width: 50,
            editable: true,
        },
        {
            field: 'state',
            headerName: 'State',
            width: 110,
            editable: true,
        },
        {
            field: 'location',
            headerName: 'Location',
            width: 110,
            editable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            editable: true,
        },
        // {
        //     field: 'usertype',
        //     headerName: 'User Type',
        //     width: 110,
        //     editable: true,
        // },
        {
            field: 'action',
            headerName: 'Action',
            width: 90,
            renderCell: (params) => (
                <Button onClick={() => handleBlockAction(params.row)}>Block</Button>
            ),
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 90,
            editable: true,
        },
    ];

    const [loading, setLoading] = useState(true);

    const handleBlockAction = (row) => {
        // Add logic to handle the "Block" action for the selected user (row)
        console.log(`Block action clicked for user: ${row.name}`);
    };


    const fetchData = async (page, rowsPerPage) => {
        try {
            setLoading(true);
            const limit = rowsPerPage;
            const offset = page * rowsPerPage;
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-all-users`, {
                size: limit,
                page: offset
            });

            // Set kycStatus to 0 if user_kyc_info is null
            const dataWithDefaultKycStatus = response.data.data.map(item => {
                if (!item.user_kyc_info) {
                    item.user_kyc_info = { kycStatus: 0 };
                }
                return item;
            });

            const flattenedData = flattenUserData(dataWithDefaultKycStatus);
            setRows(flattenedData);

            setCount(response.data.totalCount); // Set the total count of rows
            console.log('Rows - ', response.data.data);
            console.log('User data = ', flattenedData);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage]);


    const flattenUserData = (data) => {
        const flattenedData = [];

        data.forEach((user) => {
            const statusMapping = {
                0: 'Not Submitted',
                1: 'Requested',
                2: 'Verified',
                3: 'Rejected',
            };

            flattenedData.push({
                id: user.userId,
                name: user.fullName,
                collegeName: user.college_info.collegeName,
                branch: user.course_info.courseName,
                year: user.year,
                state: user.city_info.state_info.stateName,
                location: user.city_info.cityName,
                status: statusMapping[user.user_kyc_info.kycStatus] || 'Not Submitted',
                // usertype: "User Type",
                action: '',
                delete: '',
                email: user.emailId,
                mobile: user.phoneNumber,
            });
        });
        return flattenedData;
    };

    // Function to handle opening the modal
    const handleOpenModal = (user) => {
        setSelectedUser(user);
        setOpenModal(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setSelectedUser(null);
        setOpenModal(false);
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        console.log(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setCurrentPage(0);
    };

    const filteredRows = (rows || [])
        .filter((row) =>
            Object.values(row).some(
                (value) =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText.toLowerCase())
            )
        )
        .filter((row) => (allUsersFilter ? row.status === allUsersFilter : true));


    return (
        <>
            <UserNavBar onSearch={setSearchText} />
            <Box sx={{ height: 500, width: '100%', marginTop: '15px', marginBottom: '55px', overflow: 'auto' }}>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow
                                    key={rows.id}
                                    className="name-row" // Add this line>
                                >
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.field}
                                            style={{
                                                width: column.width,
                                                backgroundColor: '#f0f0f0',
                                                fontWeight: 'bold',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {column.field === 'delete' ? (
                                                column.headerName
                                            ) : (
                                                column.field === 'status' ? (
                                                    <Select
                                                        value={allUsersFilter}
                                                        onChange={(e) => setAllUsersFilter(e.target.value)}
                                                        style={{ minWidth: column.width, width: '100%', fontWeight: 'bold', }}
                                                        displayEmpty
                                                    >
                                                        {/* <MenuItem value="" style={{ fontWeight: 'bold' }}>Status</MenuItem> */}
                                                        <MenuItem value="" style={{ fontWeight: 'bold' }}>Status(all users)</MenuItem>
                                                        <MenuItem value="Not Submitted" style={{ fontWeight: 'bold' }}>Not Submitted</MenuItem>
                                                        <MenuItem value="Verified" style={{ fontWeight: 'bold' }}>Verified</MenuItem>
                                                        <MenuItem value="Requested" style={{ fontWeight: 'bold' }}>Requested</MenuItem>
                                                        <MenuItem value="Rejected" style={{ fontWeight: 'bold' }}>Rejected</MenuItem>
                                                    </Select>
                                                ) : (
                                                    column.headerName
                                                )
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow key={row.id}
                                            style={{ backgroundColor: 'transparent', transition: 'background-color 0.3s' }}
                                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                                        >
                                            {columns.map((column) => (
                                                <TableCell key={column.field} style={{
                                                    textAlign: 'center',
                                                    width: column.width,
                                                    cursor: column.field === 'name' ? 'pointer' : 'auto',
                                                }}
                                                    onClick={() => (column.field === 'name' ? handleOpenModal(row) : null)}
                                                >
                                                    {column.field === 'delete' ? (
                                                        <DeleteIcon />
                                                    ) : column.field === 'action' ? (
                                                        <Button variant='outlined' color="secondary" onClick={() => handleBlockAction(row)}>Block</Button>
                                                    ) : (
                                                        row[column.field]
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* Pagination */}
                    <TablePagination
                        rowsPerPageOptions={[100, 500, 1000, 5000]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box >
            <UserModal open={openModal} handleClose={handleCloseModal} user={selectedUser} />
        </>
    );
}
