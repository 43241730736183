import React from 'react'
import { TextField } from '@mui/material';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function UserSearch({ onSearch }) {

    const handleSearch = (e) => {
        const searchText = e.target.value;
        onSearch(searchText);
    };

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" style={{
            padding: '10px', marginBottom: '10px', marginTop: '10px', display: 'flex', justifyContent: 'space-around'
        }}>
            <Grid item xs={12} sm={6} md={3}>
                <TextField
                    variant='standard'
                    label="Search..."
                    onChange={handleSearch}
                    fullWidth
                    style={{
                        height: '40px',
                    }}
                    InputProps={{
                        endAdornment: <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default UserSearch