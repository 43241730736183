import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Boomcoin from '../../assets/boomcoin.png';
import { useLocation } from 'react-router-dom';
import axios from '../../../axiosConfig';

const AmountCell = ({ value }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src={Boomcoin} alt="Boomcoin" style={{ marginRight: '5px', width: '20px', height: '20px' }} />
    {value}
  </div>
);

const statusMapping = {
  0: 'Disbursed',
  1: 'Pending',
  2: 'Processed',
  3: 'Reversed',
  4: 'Queued',
  5: 'Initiated',
  6: 'Updated',
  7: 'Rejected',
  8: 'Failed',
};

const columns = [
  { field: 'userId', headerName: 'User No', width: 80, align: 'center', headerAlign: 'center' },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 130,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => <AmountCell value={params.value} />,
    sorting: true
  },
  { field: 'description', headerName: 'Description', width: 500, align: 'center', headerAlign: 'center' },
  {
    field: 'userTransactionId',
    headerName: ' User Transaction ID',
    width: 190,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 90,
    align: 'center',
    renderCell: (params) => statusMapping[params.value],
    headerAlign: 'center',
  },
  {
    field: 'updatedAt',
    headerName: 'Date And Time',
    sortable: false,
    width: 160,
    align: 'center',
    headerAlign: 'center',
  },
];

export default function TransactionTable({ searchText }) {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });
  const [rowCount, setRowCount] = useState(0);
  const [userId, setUserId] = useState();
  const state = useLocation();

  useEffect(() => {
    if (
      state &&
      state.state.userId
    ) {
      setUserId(state.state.userId);
    }
  }, [state]);

  const fetchData = async (page, rowsPerPage) => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-user-transactions?size=${rowsPerPage}&page=${page}`, {
        userId: userId,
      });
      const { data, totalCount } = response.data;
      setRows(data);
      setRowCount(totalCount);
      // console.log(data);
    } catch (error) {
      console.error('Error fetching transaction data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData(paginationModel.page, paginationModel.pageSize);
    }
  }, [paginationModel.page, paginationModel.pageSize, userId]);

  useEffect(() => {
    const filteredData = rows.filter((row) =>
      Object.values(row).some(
        (value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredRows(filteredData);
  }, [searchText, rows]);

  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);
  };

  return (
    <div style={{ height: 400, width: '100%', marginTop: '15px', marginBottom: '55px' }}>
      <DataGrid
        rows={filteredRows}
        getRowId={(row) => row.userTransactionId}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={rowCount}
        loading={loading}
        pageSizeOptions={[5, 10, 15]}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </div>
  );
}
