// SubmissionDetail.js
import React, { useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Box, List, TextField, ListItem, Typography, Select, MenuItem, FormControl, InputLabel, Button, Checkbox, Modal } from '@mui/material';
import { useEffect } from 'react';
import axios from '../../axiosConfig';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function StudentList({ selectedStudent, userName, onSelectStudent, students, triggerReload }) {

    const [selectedStudentIds, setSelectedStudentIds] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');

    const handleCheckboxChange = (studentId) => {
        console.log(studentId);
        if (selectedStudentIds.includes(studentId)) {
            setSelectedStudentIds(selectedStudentIds.filter((id) => id !== studentId));
        } else {
            setSelectedStudentIds([...selectedStudentIds, studentId]);
        }
    };

    const handleReject = () => {
        // Open the rejection modal
        setModalOpen(true);
    };

    const handleModalReject = () => {
        if (selectedStudentIds.length > 0) {
            const status = 2; // Status for "Rejected"

            console.log('taskSubmissionIds:', selectedStudentIds, 'status:', status, 'rejectionreason:', rejectionReason);

            axios.put(`${process.env.REACT_APP_BASE_URL}/update-submission-status`, {
                taskSubmissionId: selectedStudentIds,
                approvalStatus: status,
                rejectReason: rejectionReason,
            })
                .then(response => {
                    console.log('Multiple students rejected successfully', response);
                    triggerReload();
                    toast.success("Success!");
                    setSelectedStudentIds('');
                })
                .catch(error => {
                    console.error('Error rejecting multiple students:', error);
                    toast.error(error.message);
                });

            handleModalClose();
        }
    };

    const handleAccept = () => {
        if (selectedStudentIds.length > 0) {
            const status = 1; // Status for "Accepted"

            console.log('taskSubmissionIds:', selectedStudentIds, 'status:', status);

            axios.put(`${process.env.REACT_APP_BASE_URL}/update-submission-status`, {
                taskSubmissionId: selectedStudentIds,
                approvalStatus: status,
            })
                .then(response => {
                    console.log('Multiple students accepted successfully', response);
                    triggerReload();
                    toast.success("Success!");
                    setSelectedStudentIds('');
                })
                .catch(error => {
                    console.error('Error accepting multiple students:', error);
                    toast.error(error.message);
                });
        }
    };


    const handleModalClose = () => {
        setModalOpen(false);
        setRejectionReason('');
    };

    return (
        <Box width={300} height={'100vh'} boxShadow={3} overflow={'auto'}>
            <Typography variant="h5">Student List</Typography>
            {selectedStudentIds.length > 0 && (
                <Box mt="auto" display="flex" justifyContent="center">
                    <Button variant="outlined" color="success" onClick={handleAccept} sx={{ marginRight: '10px' }}>
                        Accept
                    </Button>
                    <Button variant="outlined" color="error" onClick={handleReject} sx={{ marginLeft: '10px' }}>
                        Reject
                    </Button>
                </Box>
            )}
            <List>
                {students.map((student) => (
                    <ListItem
                        key={student.personal_details_submission?.personalDetailsSubId}
                        onClick={() => onSelectStudent(student)}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor:
                                student.id === selectedStudentIds
                                    ? '#e0e8e0'
                                    : student.status === 'Accepted'
                                        ? '#c8e6c9'
                                        : student.status === 'Rejected'
                                            ? '#ffcdd2'
                                            : 'transparent',
                            boxShadow:
                                selectedStudent && student.taskSubmissionId === selectedStudent
                                    ? '0px 0px 10px 0px rgba(0,0,0,0.75)' // Add a box shadow for the selected student
                                    : 'none',
                            margin: '1px',
                        }}
                    >
                        <Box>
                            <Checkbox
                                checked={selectedStudentIds.includes(student.taskSubmissionId)}
                                onChange={() => handleCheckboxChange(student.taskSubmissionId)}
                                sx={{ marginRight: '10px', padding: '2px' }}
                            />
                            {userName}
                        </Box>
                        <Box>
                            {student.status === 'Accepted' && <DoneIcon style={{ color: 'green', fontSize: '1.5em', marginLeft: '5px', fontWeight: 'bold' }} />}
                            {student.status === 'Rejected' && <CloseIcon style={{ color: 'red', fontSize: '1.5em', marginLeft: '5px', fontWeight: 'bold' }} />}
                            {student.status === 'Pending' && <span style={{ color: 'gray', fontSize: '1.5em', marginLeft: '5px' }}></span>}
                        </Box>
                    </ListItem>
                ))}
            </List>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="rejection-modal"
                aria-describedby="rejection-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '80%', md: 400 },
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <TextField
                        id="rejection-reason"
                        label="Reason for Rejection"
                        multiline
                        rows={4}
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        sx={{ marginBottom: 2, width: '100%' }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button variant="outlined" color="error" onClick={handleModalReject}>
                            Reject
                        </Button>
                        <Box sx={{ width: 2 }} />
                        <Button variant="outlined" onClick={handleModalClose}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

function SubmissionDetail2() {

    const [selectedStudentId, setSelectedStudentId] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const [taskInfoId, settaskInfoId] = useState([]);
    const [subtaskData, setSubtaskData] = useState([]);
    const [tumId, setTumId] = useState([]);
    const [studentData, setStudentData] = useState([]);

    const [showButtons, setShowButtons] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [reloadData, setReloadData] = useState(false);

    const [selectedSubtaskId, setSelectedSubtaskId] = useState();
    const [userName, setUserName] = useState('');
    const [loadingSubtask, setLoadingSubtask] = useState(false);

    const { state } = useLocation();

    useEffect(() => {
        if (
            state &&
            state.taskInfoId &&
            state.tumId
        ) {
            settaskInfoId(state.taskInfoId);
            setTumId(state.tumId);
            setUserName(state.userName);
        }
    }, [state]);

    useEffect(() => {
        if (taskInfoId) {
            setLoadingSubtask(true);
            axios.post(`${process.env.REACT_APP_BASE_URL}/get-all-subtask`, { taskInfoId: taskInfoId })
                .then(response => {
                    setSubtaskData(response.data.data);
                    // Set the default subtask ID to the first subtask
                    const firstSubtaskId = response.data.data.length > 0 ? response.data.data[0].subtaskId : null;
                    // Automatically select the first subtask
                    setSelectedSubtaskId(firstSubtaskId);
                    // console.log("Data is ", response.data.data);
                })
                .catch(error => {
                    console.error('Error fetching subtask data:', error);
                })
                .finally(() => {
                    setLoadingSubtask(false);
                });
        }
    }, [taskInfoId, reloadData]);



    useEffect(() => {
        if (selectedSubtaskId && tumId) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/get-user-submssions-2-test`, { tumId: tumId, subtaskId: selectedSubtaskId })
                .then(response => {
                    setStudentData(response.data.data);
                    // console.log("Data of selected dropdown is ", response.data.data);
                })
                .catch(error => {
                    console.error('Error fetching student data:', error);
                });
        }
    }, [selectedSubtaskId, tumId, reloadData]);

    const triggerReload = () => {
        setReloadData(prev => !prev);
    };

    const handleSelectSubtask = (event) => {
        const subtaskId = event.target.value;
        setSelectedSubtaskId(subtaskId);
        setSelectedStudent(null);
    };

    const handleSelectStudent = (student) => {
        setSelectedStudent(student);
        setSelectedStudentId(student.taskSubmissionId);
        // console.log(student.taskSubmissionId);
        setShowButtons(true);
    };

    const handleReject = () => {
        // Open the rejection modal
        setOpenModal(true);
    };
    //  { 1 = approved | 2 = Rejected | 0 = pending }
    const handleModalReject = () => {
        const status = 2;
        const taskSubmissionId = selectedStudent.taskSubmissionId;
        console.log(`status= ${status}, rejectionreason = ${rejectionReason}, taskSubmissionId = ${taskSubmissionId}`);
        // Example using axios:
        axios.put(`${process.env.REACT_APP_BASE_URL}/update-submission-status`, {
            taskSubmissionId: taskSubmissionId,
            approvalStatus: status,
            rejectReason: rejectionReason,
        })
            .then(response => {
                console.log('Rejected successfully', response);
                toast.success("Success!");
                triggerReload();
            })
            .catch(error => {
                console.error('Error rejecting :', error);
                toast.error(error.message);
                return null;
            });

        handleModalClose();

    };

    const handleAccept = () => {
        const status = 1;
        const taskSubmissionId = selectedStudent.taskSubmissionId;
        console.log(`status= ${status}, taskSubmissionId = ${taskSubmissionId}`);
        try {
            axios.put(`${process.env.REACT_APP_BASE_URL}/update-submission-status`, {
                taskSubmissionId: taskSubmissionId,
                approvalStatus: status
            })
                .then((data) => {
                    console.log('accept api successful', data);
                    toast.success("Success!");
                    triggerReload();
                })
                .catch((err) => {
                    console.log('Accept api error: ', err);
                    toast.error(err.message);
                });
            // window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const handleModalClose = () => {
        setOpenModal(false);
        setRejectionReason('');
    };

    return (
        <Box display="flex" marginTop={4}>
            <Box>
                {/* Dropdown to select subtask */}
                <FormControl variant="standard" fullWidth sx={{ marginBottom: 2, marginTop: 2 }}>
                    <InputLabel id="subtask-select-label"> <b>Select Subtask</b></InputLabel>
                    <Select
                        labelId="subtask-select-label"
                        id="subtask-select"
                        value={selectedSubtaskId || ''}
                        onChange={handleSelectSubtask}
                    >
                        {Array.isArray(subtaskData) && subtaskData.map((subtask) => (
                            <MenuItem key={subtask.subtaskId} value={subtask.subtaskId}>
                                {subtask.subtaskTitle}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Display list of students for the selected subtask */}
                {studentData.length > -1 && (
                    <StudentList
                        selectedStudent={selectedStudentId}
                        onSelectStudent={handleSelectStudent}
                        students={studentData}
                        triggerReload={triggerReload}
                        userName={userName}
                    />
                )}
            </Box>

            {/* Right side with details of the selected student */}
            <Box flexGrow={1} p={3}>
                {selectedStudent ? (
                    <div>
                        <Typography variant="h5" align="center" sx={{ marginBottom: 2 }}>
                            {selectedStudent["personal_details_submission.name"]}
                        </Typography>

                        {selectedStudent.submissionAnswer.map((answer, answerIndex) => (
                            <div key={answerIndex} style={{
                                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                margin: "10px",
                                padding: "10px",
                                textAlign: "left",
                                width: '870px'
                            }}>
                                <Typography variant="subtitle1">
                                    <b>Question</b>: {answer.questionText} (<b>{answer.type}</b>)
                                </Typography>
                                {answer.type !== 'file_upload' && (
                                    <Typography variant="subtitle1">
                                        <b>Answer {answerIndex + 1}</b>: {answer.answer}
                                    </Typography>
                                )}
                            </div>
                        ))}
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
                            {selectedStudent && selectedStudent.submissionAnswer && // Check if submissionAnswer array exists
                                selectedStudent.submissionAnswer
                                    .filter(answer => answer.type === 'file_upload')
                                    .map((fileAnswer, fileIndex) => (
                                        <div key={fileIndex} style={{ flex: '0 0 calc(33.333% - 10px)', marginBottom: '10px' }}>
                                            {fileAnswer.answer && fileAnswer.answer.endsWith('.pdf') ? (
                                                <div>
                                                    <Typography variant="subtitle1">PDF File: {fileAnswer.questionText}</Typography>
                                                    <Button variant="contained" color="primary" href={fileAnswer.answer} download>
                                                        Download PDF
                                                    </Button>
                                                </div>
                                            ) : fileAnswer.answer && (fileAnswer.answer.endsWith('.mp4') || fileAnswer.answer.endsWith('.mov')) ? (
                                                <video width="100%" height="100%" controls>
                                                    <source src={fileAnswer.answer} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img
                                                    src={fileAnswer.answer}
                                                    alt={`Image ${fileIndex + 1}`}
                                                    style={{ width: '100%', height: 'auto', maxWidth: '300px', borderRadius: '10px' }}
                                                />
                                            )}
                                            {/* <Typography variant="subtitle1" sx={{ marginTop: '5px' }}>{fileAnswer.questionText}</Typography> */}
                                        </div>
                                    ))
                            }
                        </div>
                        <div>
                            {selectedStudent.status === "Rejected" && (
                                <Typography variant="subtitle1">
                                    <b>Rejection Reason</b>: {selectedStudent.rejectReason}
                                </Typography>
                            )}
                        </div>
                        {showButtons && (
                            <Box mt={3} display="flex" justifyContent="center">
                                <Button variant="outlined" color="success" onClick={handleAccept} sx={{ marginRight: '10px', marginBottom: ' 40px' }}>
                                    Accept
                                </Button>
                                <Button variant="outlined" color="error" onClick={handleReject} sx={{ marginLeft: '10px', marginBottom: '40px' }}>
                                    Reject
                                </Button>
                            </Box>
                        )}

                        {openModal && (
                            <Modal
                                open={openModal}
                                onClose={handleModalClose}
                                aria-labelledby="rejection-modal"
                                aria-describedby="rejection-modal-description"
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: { xs: '80%', md: 400 },
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 4,
                                    }}
                                >
                                    <TextField
                                        id="rejection-reason"
                                        label="Reason for Rejection"
                                        multiline
                                        rows={4}
                                        value={rejectionReason}
                                        onChange={(e) => setRejectionReason(e.target.value)}
                                        sx={{ marginBottom: 2, width: '100%' }}
                                    />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button variant="outlined" color="error" onClick={handleModalReject}>
                                            Reject
                                        </Button>
                                        <Box sx={{ width: 2 }} />
                                        <Button variant="outlined" onClick={handleModalClose}>
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>
                            </Modal>
                        )}
                    </div>
                ) : (
                    <Typography variant="body1">Please select a subtask from the list to view details.</Typography>
                )}
            </Box>
            <ToastContainer />
        </Box>

    );
}

export default SubmissionDetail2;