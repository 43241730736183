import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import TaskPhoto from "../assets/photo.png";
import axios from "../../axiosConfig";
import saveAs from "file-saver";

function TaskDetail() {
  // const { taskInfoId } = useParams();
  const [task, setTask] = useState(null);
  const navigate = useNavigate();

  const { state } = useLocation();
  const taskId = state?.taskInfoId || null;
  console.log(taskId);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/get-task-details`,
        { taskInfoId: taskId }
      );
      const taskDetails = response.data.data;
      console.log(taskDetails);
      setTask(taskDetails);
    } catch (error) {
      console.log("Error in fetch api - ", error);
      setTask(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [taskId]); // Re-run effect when taskId changes

  if (!task) {
    // Handle loading state or error state
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  console.log(task);

  // Assuming there can be multiple subtasks, use map to iterate over them
  const subtasks = task.subtask_infos || [];
  const totalBoomcoins = subtasks.reduce(
    (total, subtask) => total + (subtask.boomcoins || 0),
    0
  );

  const formatMonthYear = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const handleEditClick = async () => {
    try {
      console.log("Navigating to EditTask with taskDetails:", task);
      navigate(`/edit-task`, { state: { taskDetails: task } });
    } catch (error) {
      console.error("Error sending task details to edit:", error);
    }
  };

  const handlePauseClick = async () => {
    const newStatus = task.status === 1 ? 2 : 1;
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/update-task-status`, {
        taskInfoId: task.taskInfoId,
        status: newStatus,
      });
      console.log(`Status changed to ${newStatus}`);
      // Call fetchData to re-fetch updated data
      fetchData();
      // Update the task status in the local state or refetch the task details from the server if needed
    } catch (error) {
      console.log(error);
    }
  };
  const downloadExcelFile = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/get-export-data`,
        { taskInfoId: taskId },
        {
          responseType: "blob",
        }
      );
      console.log(response);
      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      let filename = `TaskSubmissions-${taskId}.xlsx`; // Default filename with taskId

    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (filenameMatch && filenameMatch.length === 2) {
        filename = filenameMatch[1];
      }
    }

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, filename);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" mb={3} mt={6} marginTop={7}>
        Task Detail
      </Typography>

      <Box
        boxShadow={4}
        borderRadius={8}
        bgcolor="white"
        p={3}
        style={{ margin: "10px" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <img
              src={task.brand_info.brandLogo}
              alt="TaskPhoto"
              style={{
                width: "80%",
                maxWidth: "300px",
                borderRadius: "7px",
                border: "2px solid lightblue",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box p={2} textAlign={{ xs: "center", sm: "left" }}>
              <Box>
                <Typography variant="h5">Task Information</Typography>
                <br />
                <Typography>
                  <b>{task.taskTitle}</b>
                </Typography>
                <Typography>
                  Category - {task.taskCategoryId || "No Promotion Type"}
                </Typography>
                <Typography>{totalBoomcoins} Boomcoins</Typography>
                <Typography>
                  {formatMonthYear(task.endDate)} - Expiry date
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box p={2} height="100%">
              <Box
                display="flex"
                justifyContent={{ xs: "center", sm: "flex-end" }}
                mt={2}
              >
                {task.status === 1 ? (
                  <Button
                    startIcon={<PauseCircleOutlineIcon />}
                    onClick={handlePauseClick}
                  >
                    Pause Task
                  </Button>
                ) : (
                  <Button
                    startIcon={<PlayCircleOutlineIcon />}
                    onClick={handlePauseClick}
                    style={{ color: "grey" }}
                  >
                    Resume Task
                  </Button>
                )}
              </Box>
              <Box
                display="flex"
                justifyContent={{ xs: "center", sm: "flex-end" }}
                mt={2}
              >
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditClick();
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ borderRadius: "50px", width: "11rem" }}
                  >
                    Edit task
                  </Button>
                </Link>
              </Box>
              <Box
                display="flex"
                justifyContent={{ xs: "center", sm: "flex-end" }}
                mt={2}
              >
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/task/taskanalysis`, {
                      state: { taskDetails: task },
                    });
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ borderRadius: "50px", width: "11rem" }}
                  >
                    More Details
                  </Button>
                </Link>
              </Box>
              <Box
                display="flex"
                justifyContent={{ xs: "center", sm: "flex-end" }}
                mt={2}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ borderRadius: "50px", width: "11rem" }}
                  onClick={downloadExcelFile}
                >
                  Export to Sheet
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={3}
        mt={5}
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={6}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          margin={2}
          padding={2}
          boxShadow={4}
          bgcolor="white"
          style={{ textAlign: "center" }}
        >
          <p>
            {" "}
            <b>Total Applications</b>
          </p>
          <Typography variant="h4">100</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          margin={2}
          padding={2}
          boxShadow={4}
          bgcolor="white"
          style={{ textAlign: "center" }}
        >
          <p>
            {" "}
            <b>Total Submissions</b>
          </p>
          <Typography variant="h4">75</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          margin={2}
          padding={2}
          boxShadow={4}
          bgcolor="white"
          style={{ textAlign: "center" }}
        >
          <p>
            {" "}
            <b>Approved</b>
          </p>
          <Typography variant="h4">50</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          margin={2}
          padding={2}
          boxShadow={4}
          bgcolor="white"
          style={{ textAlign: "center" }}
        >
          <p>
            {" "}
            <b>Rejected</b>
          </p>
          <Typography variant="h4">25</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          margin={2}
          padding={2}
          boxShadow={4}
          bgcolor="white"
          style={{ textAlign: "center" }}
        >
          <p>
            {" "}
            <b>Boomcoins Spent</b>
          </p>
          <Typography variant="h4">500</Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default TaskDetail;
