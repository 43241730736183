import React from 'react';
import './Dashboard.css';
import DashboardCard from './DashboardCard';
import TopEarners from './TopEarners';


function Dashboard() {


  return (
    <>
    <div className="dashboard-container">
      <DashboardCard />
      <TopEarners />
    </div>
    </>
  );
}

export default Dashboard;
