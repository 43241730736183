// axiosConfig.js
import axios from 'axios';



const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwtToken');
        const requesterId = localStorage.getItem('requesterId');

        config.headers.Authorization = `Bearer ${token}`;
        // Add the requesterId to the request body
        if (requesterId) {
            config.data = {
                ...config.data,
                requesterId,
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;