import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

const data = [
  { title: 'Total Users', value: 10 },
  { title: 'Verified Users', value: 10 },
  { title: 'Active tasks', value: 10 },
  { title: 'Total tasks', value: 10 },
  { title: 'Total boomcoins disbursed', value: 120 },
  { title: 'Users joined today', value: 10 }
];

function DashboardCard() {
  const renderCard = (item, index) => (
    <Grid item key={index} xs={12} sm={6} md={4} lg={4} style={{ width: '100%', minWidth: '150px' }}>
      <Card style={{ margin: '8px', padding: '8px', boxShadow: '4px 4px 8px #ccc', textAlign: 'center' }}>
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: '32px', fontWeight: 'bold' }}>
            {item.value}
          </Typography>
          <Typography>{item.title}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <div className="dashboard-card-container">
      <Grid container spacing={2} justifyContent="center">
        {data.map((item, index) => renderCard(item, index))}
      </Grid>
    </div>
  );
}

export default DashboardCard;
