import React from 'react';
import TransactionStatistics from './TransactionStatistics';
import TransactionSearch from './TransactionSearch';
// import TransactionTable from './TransactionTable';


function UserTransactions() {
    return (
        <>
            <TransactionStatistics />
            <TransactionSearch />
            {/* <TransactionTable /> */}
        </>
    )
}

export default UserTransactions;