import React from 'react'
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function SortableBanner({ id, banner, handleDeleteBanner }) {

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: id });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        position: 'relative',
        marginBottom: '20px'
    };

    // Ensure handleDeleteBanner is defined
    const handleClickDelete = () => {
        if (handleDeleteBanner) {
            handleDeleteBanner(id);
        }
    };


    return (
        <div ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            key={id} >
            <img
                src={banner.bannerImage}
                alt={`Sample Banner ${banner.id}`}
                style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '25px' }}
            />
            <IconButton
                style={{ position: 'absolute', bottom: '10px', right: '10px', color: 'red', background: '#FFFFFF7F' }}
                onClick={handleClickDelete}
            >
                <DeleteOutlineIcon />
            </IconButton>

        </div>
    )
}

export default SortableBanner